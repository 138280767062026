import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Heading from 'components/atoms/Heading';
import Box from 'components/atoms/Box';
import Column from 'components/atoms/Column';
import ColumnGroup from 'components/atoms/ColumnGroup';
import Container from 'components/atoms/Container';
import HeroContainer from 'components/atoms/HeroContainer';
import Body from 'components/atoms/Body';
import LoginForm from 'components/molecules/LoginForm';
import ForgotPasswordForm from 'components/molecules/ForgotPasswordForm';
import SetPasswordForm from 'components/molecules/SetPasswordForm';
import Modal from 'components/molecules/Modal';
import PageNotFound from 'pages/PageNotFound';
import { Constants } from 'utils';
import Loader from 'components/atoms/Loader';
import './style.css';

const Login: React.FC = (): JSX.Element => {
	const { action } = useParams();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [passwordShown, setPasswordShown] = useState(false);
	const [emailErrorMessage, setEmailErrorMessage] = useState('');
	const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
	const [errorFormat, setErrorFormat] = useState(false);
	const [confirmedPassword, setConfirmedPassword] = useState('');
	const [confirmedPasswordShown, setConfirmedPasswordShown] = useState(false);
	const [confirmedPasswordErrorMessage, setConfirmedPasswordErrorMessage] = useState('');
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalData, setModalData] = useState(Constants.MODAL_DATA.unknownError);
	const [isLoading, setIsLoading] = useState(false);

	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	};

	const toggleConfirmedPassword = () => {
		setConfirmedPasswordShown(!confirmedPasswordShown);
	};

	const updateEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
		setEmailErrorMessage('');
	};

	const updatePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value);
		setPasswordErrorMessage('');
		setConfirmedPasswordErrorMessage('');
		setErrorFormat(false);
	};

	const updateConfirmedPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
		setConfirmedPassword(e.target.value);
		setConfirmedPasswordErrorMessage('');
		setErrorFormat(false);
	};

	const loginProps = {
		email: email,
		emailMessage: emailErrorMessage,
		updateEmail: updateEmail,
		password: password,
		passwordMessage: passwordErrorMessage,
		updatePassword: updatePassword,
		togglePassword: togglePassword,
		passwordShown: passwordShown,
		setModalData: setModalData,
		setIsModalVisible: setIsModalVisible,
		setEmailErrorMessage: setEmailErrorMessage,
		setPasswordErrorMessage: setPasswordErrorMessage,
		setIsLoading: setIsLoading
	};

	const forgotPasswordProps = {
		email: email,
		emailErrorMessage: emailErrorMessage,
		updateEmail: updateEmail,
		setModalData: setModalData,
		setIsModalVisible: setIsModalVisible,
		setEmailErrorMessage: setEmailErrorMessage,
		setIsLoading: setIsLoading
	};

	const setPasswordProps = {
		password: password,
		updatePassword: updatePassword,
		togglePassword: togglePassword,
		passwordShown: passwordShown,
		errorFormat: errorFormat,
		setErrorFormat: setErrorFormat,
		confirmedPassword: confirmedPassword,
		updateConfirmedPassword: updateConfirmedPassword,
		toggleConfirmedPassword: toggleConfirmedPassword,
		confirmedPasswordShown: confirmedPasswordShown,
		passwordErrorMessage: passwordErrorMessage,
		confirmedPasswordErrorMessage: confirmedPasswordErrorMessage,
		setModalData: setModalData,
		setIsModalVisible: setIsModalVisible,
		setPassword: setPassword,
		setConfirmedPassword: setConfirmedPassword,
		setPasswordErrorMessage: setPasswordErrorMessage,
		setConfirmedPasswordErrorMessage: setConfirmedPasswordErrorMessage,
		setIsLoading: setIsLoading
	};

	return (
		<React.Fragment>
			{action !== 'login' && action !== 'forgotpassword' && action !== 'setpassword' ? (
				<PageNotFound />
			) : !isLoading ? (
				<HeroContainer className='is-fullheight has-background-grey-lighter'>
					<Body className='p-0'>
						<Container>
							<ColumnGroup className='is-mobile'>
								<Column
									className='is-full-mobile is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop is-6-widescreen
									is-offset-3-widescreen'
								>
									<Box className='columns has-background-white-bis p-6'>
										<div className='column is-half'>
											<Heading className='is-flex is-flex-direction-column is-justify-content-flex-start title is-2 mt-6 mb-6 has-text-grey-dark'>
												<span className='header-no-wrap'>Audience</span>
												<span className='header-no-wrap'>Builder</span> <br />
												<span>v0.1</span>
											</Heading>
										</div>
										<div className='column is-half custom-mt-6 custom-mt-0'>
											{action === 'login' ? (
												<LoginForm {...loginProps} />
											) : action === 'forgotpassword' ? (
												<ForgotPasswordForm {...forgotPasswordProps} />
											) : action === 'setpassword' ? (
												<SetPasswordForm {...setPasswordProps} />
											) : null}
											<Modal
												data={modalData}
												isModalVisible={isModalVisible}
												setIsModalVisible={setIsModalVisible}
											/>
										</div>
									</Box>
								</Column>
							</ColumnGroup>
						</Container>
					</Body>
				</HeroContainer>
			) : (
				<HeroContainer className='is-fullheight is-flex is-justify-content-center'>
					<Body>
						<Container className='is-flex is-justify-content-center'>
							<Loader className='icon is-large' />
						</Container>
					</Body>
				</HeroContainer>
			)}
		</React.Fragment>
	);
};

export default Login;
