import React, { useEffect, useRef, useState } from 'react';
import NavBar from 'components/molecules/NavBar';
import PageHeader from 'components/molecules/PageHeader';
import { Link } from 'react-router-dom';
import Button from 'components/atoms/Button';
import Table from 'components/molecules/Table';
import Pagination from 'components/molecules/Pagination';
import HeroContainer from 'components/atoms/HeroContainer';
import Body from 'components/atoms/Body';
import Container from 'components/atoms/Container';
import Loader from 'components/atoms/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.css';
import Input from 'components/atoms/Input';
import { Segment } from 'services';
import { Helpers, Constants, AuthUtils } from 'utils';
import FormControl from 'components/atoms/FormControl';
import SegmentFilter from 'components/molecules/SegmentFilter';
import Modal from 'components/molecules/Modal';
import { Auth } from 'services';
interface KeyString {
	[key: string]: string;
}

const Home: React.FC = (): JSX.Element => {
	const {
		fetchSegmentsFailed,
		segmentsDeleteFailed,
		segmentsDeleteSuccessful,
		deleteSegmentsAlert,
		unAuthorized,
		failedToFetchFilterList
	} = Constants.MODAL_DATA;
	const { getAllSegments, deleteSegments, getSegmentFilters } = Segment;
	const { getLocalTime } = Helpers;
	const [segmentList, setSegmentList] = useState<KeyString[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isTableLoading, setIsTableLoading] = useState(true);
	const [deleteButtonLoader, setDeleteButtonLoader] = useState(false);
	const [disabledCheckbox, setDisabledCheckbox] = useState<boolean[]>([]);
	const [page, setPage] = useState(1);
	const [dataSize, setDatasize] = useState(0); //eslint-disable-line
	const [selectedSegments, setSelectedSegments] = useState<KeyString[]>([]);
	const [disableDelete, setDisableDelete] = useState(true);
	const [modalData, setModalData] = useState(fetchSegmentsFailed);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalAction, setModalAction] = useState<(() => void) | undefined>(undefined);
	const [activeMenuRow, setActiveMenuRow] = useState<KeyString>({}); //eslint-disable-line
	const [sortingOrder, setSortingOrder] = useState<string | undefined>('DESC');
	const [sortingColumn, setSortingColumn] = useState<string | undefined>('createdAt');
	const selectedAccessFilter = useRef<Array<string>>([]);
	const selectedPartnerFilter = useRef<Array<string>>([]);
	const selectedOwnerFilter = useRef<Array<string>>([]);
	const selectedStatusFilter = useRef<Array<string>>([]);
	const [refreshFlag, setRefreshFlag] = useState(false);
	const searchText = useRef('');

	const [accessFilters, setAccessFilters] = useState<KeyString[]>([]);
	const [partnerFilters, setPartnerFilters] = useState<KeyString[]>([]);
	const [statusFilters, setStatusFilters] = useState<KeyString[]>([]);
	const [ownerFilters, setOwnerFilters] = useState<KeyString[]>([]);
	const displayColumnOrder = ['name', 'description', 'createdAt', 'owner', 'status'];
	const displayColumnName = ['Name', 'Description', 'Creation Date', 'Owner', 'Status'];

	useEffect(() => {
		initializeFilters();
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
		getSegmentList();
	}, [refreshFlag, page, sortingColumn, sortingOrder]);

	const refreshPage = () => {
		setRefreshFlag(!refreshFlag);
	};

	useEffect(() => {
		if (selectedSegments.length > 0) {
			setDisableDelete(false);
		} else {
			setDisableDelete(true);
		}
	}, [selectedSegments]);

	const clearJwt = () => {
		localStorage.removeItem('metadata :');
		Auth.logOut();
	};

	const getSegmentList = () => {
		setIsTableLoading(true);
		const offset = (page - 1) * 50;
		const access =
			selectedAccessFilter.current.length > 0 ? selectedAccessFilter.current.join() : undefined;
		const partner =
			selectedPartnerFilter.current.length > 0 ? selectedPartnerFilter.current.join() : undefined;
		const status =
			selectedStatusFilter.current.length > 0 ? selectedStatusFilter.current.join() : undefined;
		const owner =
			selectedOwnerFilter.current.length > 0 ? selectedOwnerFilter.current.join() : undefined;
		const searchTextValue = searchText.current === '' ? undefined : searchText.current;
		let order;
		let sortBy;
		if (sortingColumn !== '') {
			sortBy = sortingColumn;
			order = sortingOrder;
		}

		//Payload for fetching the segment lists into the table
		const params = {
			offset: offset,
			access: access,
			sharedToPartner: partner,
			ownerId: owner,
			status: status,
			searchText: searchTextValue,
			sortBy: sortBy,
			order: order
		};

		getAllSegments(params).then((res) => {
			if (res.status === 200) {
				// eslint-disable-next-line
				const segments: KeyString[] = res.data.data.map((item: any) => {
					const segment = {
						id: item.id,
						name: item.name,
						description: item.description,
						createdAt: getLocalTime(item.createdAt.value),
						owner: item.owner,
						status: item.status,
						size: item.audienceSize ? item.audienceSize : '0',
						lastDownloadedAt: item.lastDownloadedAt
							? Helpers.getLocalTime(item.lastDownloadedAt.value) //To get region-wise timestamp
							: '',
						lastSharedToCleverTapAt: item.lastSharedToCleverTapAt
							? Helpers.getLocalTime(item.lastSharedToCleverTapAt.value) //To get region-wise timestamp
							: '',
						lastSharedToFirebaseAt: item.lastSharedToFirebaseAt
							? Helpers.getLocalTime(item.lastSharedToFirebaseAt.value) //To get region-wise timestamp
							: '',
						lastSharedToGAMAt: item.lastSharedToGAMAt
							? Helpers.getLocalTime(item.lastSharedToGAMAt.value) //To get region-wise timestamp
							: '',
						lastSharedToCleverTapSuccessful: item.lastSharedToCleverTapSuccessful,
						lastSharedToFirebaseSuccessful: item.lastSharedToFirebaseSuccessful,
						lastSharedToGAMSuccessful: item.lastSharedToGAMSuccessful,
						canExportToCleverTap: item.canExportToCleverTap,
						canExportToFirebase: item.canExportToFirebase,
						canExportToGAM: item.canExportToGAM,
						ownerId: item.ownerId,
						isCustomQuery: item.isCustomQuery
					};
					return segment;
				});
				//If user is Admin, user will always have checkbox enabled
				if (AuthUtils.isAdmin()) {
					const disabled: boolean[] = res.data.data.map(() => {
						return false;
					});
					setDisabledCheckbox(disabled);
				}
				//If user is Creator, user will have the checkbox enabled if the user is the owner of the segment
				else {
					const userId = AuthUtils.getUserId();
					const disabled: boolean[] = res.data.data.map((segment: KeyString) => {
						if (userId === segment.ownerId) {
							return false;
						} else return true;
					});
					setDisabledCheckbox(disabled);
				}
				setDatasize(res.data.totalCount);
				setSegmentList(segments);
			} else if (res.status === 401) {
				setModalData(unAuthorized);
				setIsModalVisible(true);
				setModalAction(() => clearJwt());
			} else {
				setModalData(fetchSegmentsFailed);
				setIsModalVisible(true);
			}
			setIsTableLoading(false);
			setIsLoading(false);
			setSelectedSegments([]);
		});
	};

	//Get the segment filters which can be applied on segments
	const initializeFilters = () => {
		getSegmentFilters().then((response) => {
			if (response.status === 200) {
				const copyAccessFilters = filtersMapping(response.data.access);
				const copyPartnerFilters = filtersMapping(response.data.sharedWithPartners);
				const copyStatusFilters = filtersMapping(response.data.status);
				const copyOwnerFilters = response.data.owners.map((owner: KeyString) => {
					return {
						label: owner.name,
						value: owner.id
					};
				});
				setAccessFilters(copyAccessFilters);
				setPartnerFilters(copyPartnerFilters);
				setStatusFilters(copyStatusFilters);
				setOwnerFilters(copyOwnerFilters);
			} else {
				setModalData(failedToFetchFilterList);
				setIsModalVisible(true);
			}
		});
	};

	const deleteModalTrigger = () => {
		setModalData(deleteSegmentsAlert);
		setIsModalVisible(true);
		setModalAction(() => deleteAllSegments);
	};

	//Selected segments will get deleted and the segment list will get updated
	const deleteAllSegments = () => {
		const segmentIds: string[] = selectedSegments.map((segment) => {
			return segment.id;
		});
		setDeleteButtonLoader(true);
		setDisableDelete(true);
		deleteSegments(segmentIds).then((response) => {
			if (response.status === 200) {
				getSegmentList();
				setModalData(segmentsDeleteSuccessful);
				setIsModalVisible(true);
			} else {
				setModalData(segmentsDeleteFailed);
				setIsModalVisible(true);
			}
			setDeleteButtonLoader(false);
		});
	};

	const filtersMapping = (rawFilters: string[]) => {
		return rawFilters.map((filter: string) => {
			let label = filter;
			if (filter === 'Clevertap') {
				label = 'CleverTap';
			} else if (filter === 'GoogleAdManager') {
				label = 'Google Ad Manager';
			}
			return {
				label: label,
				value: filter
			};
		});
	};

	const applyFilter = () => {
		getSegmentList();
	};

	const searchSegment = () => {
		if (searchText.current !== '') getSegmentList();
	};

	const tableProps = {
		tableType: 'segment',
		className: 'is-fullwidth is-hoverable',
		data: segmentList,
		showCheckBox: true,
		showAction: true,
		selectedRows: selectedSegments,
		setSelectedRows: setSelectedSegments,
		displayColumnOrder: displayColumnOrder,
		displayColumnName: displayColumnName,
		setModalData: setModalData,
		setIsModalVisible: setIsModalVisible,
		setActiveMenuRow: setActiveMenuRow,
		setModalAction: setModalAction,
		sortingOrder: sortingOrder,
		sortingColumn: sortingColumn,
		setSortingOrder: setSortingOrder,
		setSortingColumn: setSortingColumn,
		disabledCheckbox: disabledCheckbox
	};

	const segmentFilterProps = {
		applyFilter: applyFilter,
		accessFilters: accessFilters,
		partnerFilters: partnerFilters,
		ownerFilters: ownerFilters,
		statusFilters: statusFilters,
		selectedAccessFilter: selectedAccessFilter,
		selectedPartnerFilter: selectedPartnerFilter,
		selectedOwnerFilter: selectedOwnerFilter,
		selectedStatusFilter: selectedStatusFilter
	};

	return (
		<React.Fragment>
			<div className='home-container'>
				<NavBar className='is-right' />

				<Modal
					data={modalData}
					isModalVisible={isModalVisible}
					setIsModalVisible={setIsModalVisible}
					action={modalAction}
					setAction={setModalAction}
				/>

				{!isLoading ? (
					<React.Fragment>
						<PageHeader pageTitle='Segments'></PageHeader>
						<div className='px-6 pt-2 pb-3 is-flex is-justify-content-right options-container'>
							<div className='search-container'>
								<FormControl className='has-icons-right'>
									<Input
										type='text'
										placeholder='Search'
										onChange={(e) => {
											searchText.current = e.target.value;
										}}
										onKeyDown={(e) => e.key === 'Enter' && searchSegment()}
									/>
									<span
										className='icon is-small is-clickable is-right has-text-black pr-3 hover:has-background-primary'
										onClick={() => searchSegment()}
									>
										<FontAwesomeIcon icon={['fas', 'search']} />
									</span>
								</FormControl>
							</div>
							<div className='is-flex is-justify-content-right'>
								<Button
									className={'is-dark mr-5 ' + (deleteButtonLoader ? 'is-loading' : '')}
									disabled={disableDelete}
									onClick={deleteModalTrigger}
								>
									Delete
								</Button>
								<Link to='/segment/create'>
									<Button className='is-dark'>Create</Button>
								</Link>

								<Button
									className='is-dark ml-5'
									onClick={() => {
										refreshPage();
									}}
								>
									<FontAwesomeIcon icon={['fas', 'refresh']} />
								</Button>
							</div>
						</div>
						<div className='is-flex home-content'>
							<SegmentFilter {...segmentFilterProps}></SegmentFilter>
							<div className='is-flex-grow-1 home-table-parent'>
								{!isTableLoading && (
									<div className='home-table-container'>
										<Table {...tableProps} />
									</div>
								)}
								{isTableLoading && (
									<div className='table-loader-container is-flex is-justify-content-space-around is-align-items-center'>
										<Loader className='icon is-large' />
									</div>
								)}
							</div>
						</div>
						<div className='is-flex is-justify-content-right home-pagination-container'>
							<Pagination dataSize={dataSize} page={page} setPage={setPage}></Pagination>
						</div>
					</React.Fragment>
				) : (
					<HeroContainer className='is-fullheight is-flex is-justify-content-center'>
						<Body>
							<Container className='is-flex is-justify-content-center'>
								<Loader className='icon is-large' />
							</Container>
						</Body>
					</HeroContainer>
				)}
			</div>
		</React.Fragment>
	);
};

export default Home;
