import React, { useEffect, useState } from 'react';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import FormField from 'components/atoms/FormField';
import FormControl from 'components/atoms/FormControl';
import Column from 'components/atoms/Column';
import ColumnGroup from 'components/atoms/ColumnGroup';
import NavBar from 'components/molecules/NavBar';
import PageHeader from 'components/molecules/PageHeader';
import HeroContainer from 'components/atoms/HeroContainer';
import Body from 'components/atoms/Body';
import Header from 'components/atoms/Header';
import Label from 'components/atoms/Label';
import { User } from 'services';
import { useSearchParams } from 'react-router-dom';
import { useParams, Link } from 'react-router-dom';
import { Helpers, Constants } from 'utils';
import Modal from 'components/molecules/Modal';
import Container from 'components/atoms/Container';
import Loader from 'components/atoms/Loader';
import Radio from 'components/atoms/Radio';
import Footer from 'components/atoms/Footer';
import PageNotFound from 'pages/PageNotFound';

interface CreateUserPayload {
	name: string;
	email: string;
	role: string;
	currentEmail?: string;
}

interface UpdateUserPayload {
	name?: string;
	email?: string;
	role?: string;
	currentEmail?: string;
}

const UserMutate: React.FC = (): JSX.Element => {
	const { validateEmail, validateName, validateRole } = Helpers;
	const {
		unknownError,
		successfulUserCreation,
		userExists,
		successfulUserUpdation,
		noUpdates,
		userNotExist
	} = Constants.MODAL_DATA;
	const { createUser, getUserById, updateUser } = User;
	const [emailErrorMessage, setEmailErrorMessage] = useState('');
	const [nameErrorMessage, setNameErrorMessage] = useState('');
	const [roleErrorMessage, setRoleErrorMessage] = useState('');
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [modalData, setModalData] = useState(unknownError);
	const [initialUserState, setInitialUserState] = useState({
		name: '',
		email: '',
		role: '',
		currentEmail: ''
	});
	const [formUserState, setFormUserState] = useState({
		name: '',
		email: '',
		role: 'CREATOR',
		currentEmail: ''
	});
	const { action } = useParams();

	const [searchParams] = useSearchParams();
	const userId = searchParams.get('id') || '';

	useEffect(() => {
		if (action === 'update') {
			setIsLoading(true);
			getUserById(userId).then((response) => {
				if (response.status === 200 && response.data.length > 0) {
					const { name, role, email } = response.data[0];
					setIsLoading(false);
					//Initial User State which contains user details
					setInitialUserState({
						name: name,
						email: email,
						role: role,
						currentEmail: email
					});
					//Updated User State which contains changed user details
					setFormUserState({
						name: name,
						email: email,
						role: role,
						currentEmail: email
					});
				} else {
					//If user is trying to update the user which doesnot exist in the table
					setModalData(userNotExist);
					setIsLoading(false);
					setIsModalVisible(true);
				}
			});
		}
	}, []);

	const onChange = (name: string, value: string) => {
		setFormUserState({ ...formUserState, [name]: value });
	};

	//Payload for creating a User
	const buildCreatePayload = () => {
		const payload: CreateUserPayload = { ...formUserState, name: formUserState.name.trim() };
		delete payload.currentEmail;
		return payload;
	};

	const onCreateSubmit = async () => {
		const payload = buildCreatePayload();
		const { name, email, role } = formUserState;
		//Validating if name, email and role assigned while creating are as per the required format
		if (validateEmail(email) && validateName(name) && validateRole(role)) {
			setIsLoading(true);
			createUser(payload).then((response) => {
				if (response.status === 200) {
					setModalData(successfulUserCreation);
				}
				//Trying to create the user with same email, which already exists in the table
				else if (response.status === 401) {
					setModalData(userExists);
				} else {
					setModalData(unknownError);
				}
				setIsLoading(false);
				setIsModalVisible(true);
			});
		} else {
			//if Email is not valid as per the requirement, error messages are populated
			if (!validateEmail(email)) {
				if (email.trim() === '') {
					setFormUserState({ ...formUserState, email: '' });
					setEmailErrorMessage('Email cannot be blank');
				} else {
					setEmailErrorMessage('Invalid email format');
				}
			}
			//if Name is not valid as per the requirement, error messages are populated
			if (!validateName(name)) {
				setFormUserState({ ...formUserState, name: '' });
				setNameErrorMessage('Name cannot be blank');
			}
			//if Role is not valid as per the requirement, error messages are populated
			if (!validateRole(role)) {
				setNameErrorMessage('Invalid role');
			}
		}
	};

	//Payload for updating a User
	const buildUpdatePayload = () => {
		const payload: UpdateUserPayload = { ...formUserState, name: formUserState.name.trim() };
		const { name, email, role, currentEmail } = payload;
		//If email was not updated
		if (email === currentEmail) {
			delete payload.email;
		}
		//If name was not updated
		if (name === initialUserState.name) {
			delete payload.name;
		}
		//If role was not updated
		if (role === initialUserState.role) {
			delete payload.role;
		}
		return payload;
	};

	const onUpdateSubmit = () => {
		const payload = buildUpdatePayload();
		const payloadLength = Object.keys(payload).length;
		const { name, email, role } = formUserState;
		//Validating if name, email and role assigned while creating are as per the required format and atleast one of the parameters are changed
		if (validateEmail(email) && validateName(name) && validateRole(role) && payloadLength > 1) {
			setIsLoading(true);
			updateUser(payload, userId).then((response) => {
				if (response.status === 200) {
					setModalData(successfulUserUpdation);
				} else if (response.status === 400) {
					setModalData(userExists);
				} else {
					setModalData(unknownError);
				}
				setIsLoading(false);
				setIsModalVisible(true);
			});
		} else {
			//if Email is not valid as per the requirement, error messages are populated
			if (!validateEmail(email)) {
				if (email.trim() === '') {
					setFormUserState({ ...formUserState, email: '' });
					setEmailErrorMessage('Email cannot be blank');
				} else {
					setEmailErrorMessage('Invalid email format');
				}
			}
			//if Name is not valid as per the requirement, error messages are populated
			if (!validateName(name)) {
				setFormUserState({ ...formUserState, name: '' });
				setNameErrorMessage('Name cannot be blank');
			}
			//if Role is not valid as per the requirement, error messages are populated
			if (!validateRole(role)) {
				setNameErrorMessage('Invalid role');
			} else if (payloadLength <= 1) {
				setModalData(noUpdates);
				setIsModalVisible(true);
			}
		}
	};

	return (
		<React.Fragment>
			{action !== 'create' && action !== 'update' ? (
				<PageNotFound />
			) : (
				<HeroContainer className='is-fullheight'>
					<Header className='pl-3'>
						<NavBar className='is-light' />
					</Header>
					{!isLoading ? (
						<React.Fragment>
							<PageHeader pageTitle={action === 'create' ? 'Create User' : 'Update User'}></PageHeader>
							<hr className='mx-6 is-primary' />
							<Body className='is-block pt-3'>
								<div className='pl-3'>
									<ColumnGroup>
										<Column className='is-one-third'>
											<FormField className=''>
												<Label>Name</Label>
												<FormControl>
													<Input
														className={nameErrorMessage === '' ? '' : 'is-danger'}
														type='text'
														placeholder='John Doe'
														name='name'
														value={formUserState.name}
														onChange={(e) => {
															onChange(e.target.name, e.target.value);
															setNameErrorMessage('');
														}}
													></Input>
												</FormControl>
												<p className={'help has-text-left is-danger'}>{nameErrorMessage}</p>
											</FormField>
											<FormField>
												<Label>Email</Label>
												<FormControl>
													<Input
														className={emailErrorMessage === '' ? '' : 'is-danger'}
														type='text'
														placeholder='user@setindia.com'
														name='email'
														value={formUserState.email}
														onChange={(e) => {
															onChange(e.target.name, e.target.value);
															setEmailErrorMessage('');
														}}
													></Input>
												</FormControl>
												<p className={'help has-text-left is-danger'}>{emailErrorMessage}</p>
											</FormField>
										</Column>
										<Column>
											<FormField>
												<Label>Role</Label>
												<FormControl>
													<Radio
														className={roleErrorMessage === '' ? '' : 'input-button-group is-danger'}
														name='role'
														label='Admin'
														value='ADMIN'
														checked={formUserState.role === 'ADMIN'}
														onChange={(e) => {
															onChange(e.target.name, e.target.value);
															setRoleErrorMessage('');
														}}
													/>
													<Radio
														className={roleErrorMessage === '' ? '' : 'input-button-group is-danger'}
														name='role'
														label='Creator'
														value='CREATOR'
														checked={formUserState.role === 'CREATOR'}
														onChange={(e) => {
															onChange(e.target.name, e.target.value);
															setRoleErrorMessage('');
														}}
													/>
												</FormControl>
												<p className={'help has-text-left is-danger'}>{roleErrorMessage}</p>
											</FormField>
										</Column>
									</ColumnGroup>
								</div>
							</Body>

							<Footer className='pb-6 pr-6 mb-6 mr-6'>
								<div className='is-flex is-justify-content-right'>
									<Link to='/users'>
										<Button className='is-text'>Cancel</Button>
									</Link>
									<Button
										className='mr-6 is-dark'
										onClick={() => {
											action === 'create' ? onCreateSubmit() : onUpdateSubmit();
										}}
									>
										{action === 'create' ? 'Create' : 'Update'}
									</Button>
								</div>
							</Footer>
						</React.Fragment>
					) : (
						<HeroContainer className='is-fullheight is-flex is-justify-content-center'>
							<Body>
								<Container className='is-flex is-justify-content-center'>
									<Loader className='icon is-large' />
								</Container>
							</Body>
						</HeroContainer>
					)}
					<Modal
						data={modalData}
						isModalVisible={isModalVisible}
						setIsModalVisible={setIsModalVisible}
					/>
				</HeroContainer>
			)}
		</React.Fragment>
	);
};

export default UserMutate;
