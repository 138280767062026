import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/atoms/Button';
import FormControl from 'components/atoms/FormControl';
import FormField from 'components/atoms/FormField';
import Input from 'components/atoms/Input';
import Label from 'components/atoms/Label';
import { Auth } from 'services';
import { Constants, Helpers } from 'utils';

interface ModalData {
	title: string;
	content: string;
	path: string;
}
interface SetPasswordFormProps {
	className?: string;
	password: string;
	updatePassword?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	togglePassword: (e: React.MouseEvent<HTMLButtonElement>) => void;
	passwordShown: boolean;
	errorFormat: boolean;
	setErrorFormat: React.Dispatch<React.SetStateAction<boolean>>;
	confirmedPassword: string;
	updateConfirmedPassword?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	toggleConfirmedPassword: (e: React.MouseEvent<HTMLButtonElement>) => void;
	confirmedPasswordShown: boolean;
	passwordErrorMessage: string;
	confirmedPasswordErrorMessage: string;
	setModalData: React.Dispatch<React.SetStateAction<ModalData>>;
	setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setPassword: React.Dispatch<React.SetStateAction<string>>;
	setConfirmedPassword: React.Dispatch<React.SetStateAction<string>>;
	setPasswordErrorMessage: React.Dispatch<React.SetStateAction<string>>;
	setConfirmedPasswordErrorMessage: React.Dispatch<React.SetStateAction<string>>;
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const SetPasswordForm: React.FC<SetPasswordFormProps> = ({
	password,
	updatePassword,
	togglePassword,
	passwordShown,
	errorFormat,
	setErrorFormat,
	confirmedPassword,
	updateConfirmedPassword,
	toggleConfirmedPassword,
	confirmedPasswordShown,
	passwordErrorMessage,
	confirmedPasswordErrorMessage,
	setModalData,
	setIsModalVisible,
	setPassword,
	setConfirmedPassword,
	setPasswordErrorMessage,
	setConfirmedPasswordErrorMessage,
	setIsLoading
}): JSX.Element => {
	const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line

	//Token is required here, because while resetting the password, the reset password link contains the unique token and not the metadata
	const token = searchParams.get('token') || '';

	const onSubmit = async () => {
		//If password and the confirmed password are same and password is according to the required format
		if (password === confirmedPassword && Helpers.validatePassword(password)) {
			setIsLoading(true);
			const response = await Auth.resetPassword(password, token);

			if (response.status === 200) {
				response.data;
				setModalData(Constants.MODAL_DATA.successfulPasswordSet);
				setIsModalVisible(true);
			} else if (response.status === 400) {
				//If new password is one of the previously used 5 passwords, then user cannot set that password
				if (response.data.error.message.includes('Current')) {
					setModalData(Constants.MODAL_DATA.previousPassword);
				}
				//If by mistake, token is deleted then, the set password link becomes invalid link
				else if (response.data.error.message.includes('empty')) {
					setModalData(Constants.MODAL_DATA.invalidResetLink);
				}
				//User is not allowed to reset the password within 24 hours of setting the new password
				else if (response.data.error.message.includes('hours')) {
					setModalData(Constants.MODAL_DATA.duplicateResetPassword);
				} else {
					setModalData(Constants.MODAL_DATA.invalidPassword);
				}
			} else if (response.status === 401) {
				//The reset password link expires within 1 hour of receiving the link (email)
				if (response.data.error.message.includes('expired')) {
					setModalData(Constants.MODAL_DATA.expiredResetLink);
				} else {
					setModalData(Constants.MODAL_DATA.invalidCredentials);
				}
			} else {
				setModalData(Constants.MODAL_DATA.unknownError);
			}
			setIsLoading(false);
			setIsModalVisible(true);
			setPassword('');
			setConfirmedPassword('');
		}
		//If password is according to the required format but password and the confirmed password are not same
		else if (Helpers.validatePassword(password) && password !== confirmedPassword) {
			setConfirmedPasswordErrorMessage('Password mismatch.');
			setPasswordErrorMessage('');
			setErrorFormat(true);
		}
		//If password is not according to the required format but password and the confirmed password are same
		else if (!Helpers.validatePassword(password) && password === confirmedPassword) {
			setErrorFormat(true);
			setPasswordErrorMessage(
				'Password must be a combination of capital and small letters, numbers and special characters, and should be atleast 8 characters long.'
			);
			setConfirmedPasswordErrorMessage('');
		} else {
			setPasswordErrorMessage(
				'Password must be a combination of capital and small letters, numbers and special characters, and should be atleast 8 characters long.'
			);
			setConfirmedPasswordErrorMessage('Password mismatch.');
			setErrorFormat(true);
		}
	};

	return (
		<React.Fragment>
			<FormField className='mb-6'>
				<Label className='has-text-grey-dark ml-1 is-small'>Password</Label>
				<FormControl className='has-icons-right'>
					<Input
						className={errorFormat ? 'is-danger' : ''}
						type={passwordShown ? 'text' : 'password'}
						placeholder='Password'
						value={password}
						onChange={updatePassword}
						onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
					/>
					<span
						className='icon is-small is-clickable is-right mr-1 has-text-black pr-3 hover:has-background-primary'
						onClick={togglePassword}
					>
						{passwordShown ? (
							<FontAwesomeIcon icon={['fas', 'eye-slash']} style={{ color: '#d4d4d4' }} />
						) : (
							<FontAwesomeIcon icon={['fas', 'eye']} style={{ color: '#d4d4d4' }} />
						)}
					</span>
				</FormControl>
				<p className={'help has-text-left is-danger'}>{passwordErrorMessage}</p>
				{passwordErrorMessage === '' && confirmedPasswordErrorMessage === '' && (
					<p className='help'>
						Password must be a combination of capital and small letters, numbers and special characters,
						and should be atleast 8 characters long.
					</p>
				)}
			</FormField>

			<FormField className='mb-6'>
				<Label className='has-text-grey-dark ml-1 is-small'>Re-enter Password</Label>
				<FormControl className='has-icons-right'>
					<Input
						className={errorFormat ? 'is-danger' : ''}
						type={confirmedPasswordShown ? 'text' : 'password'}
						placeholder='Re-enter Password'
						value={confirmedPassword}
						onChange={updateConfirmedPassword}
						onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
					/>
					<span
						className='icon is-small is-clickable is-right mr-1 has-text-black pr-3 hover:has-background-primary'
						onClick={toggleConfirmedPassword}
					>
						{confirmedPasswordShown ? (
							<FontAwesomeIcon icon={['fas', 'eye-slash']} style={{ color: '#d4d4d4' }} />
						) : (
							<FontAwesomeIcon icon={['fas', 'eye']} style={{ color: '#d4d4d4' }} />
						)}
					</span>
				</FormControl>
				<p className={'help has-text-left is-danger'}>{confirmedPasswordErrorMessage}</p>
			</FormField>

			<div className='level mt-4 mb-6'>
				<FormField>
					<FormControl>
						<Button className='is-link has-background-grey-dark mb-3' onClick={onSubmit}>
							Set Password
						</Button>
					</FormControl>
				</FormField>
			</div>
		</React.Fragment>
	);
};

export default SetPasswordForm;
