import React from 'react';
import ColumnGroup from 'components/atoms/ColumnGroup';
import Column from 'components/atoms/Column';
import FormField from 'components/atoms/FormField';
import Label from 'components/atoms/Label';
import FormControl from 'components/atoms/FormControl';
import Input from 'components/atoms/Input';
import Textarea from 'components/atoms/TextArea';
import Radio from 'components/atoms/Radio';
import DatePicker from 'components/molecules/DatePicker';
import RefreshFrequency from 'components/molecules/RefreshFrequency';
import TypeAheadUserEmailChips from 'components/molecules/TypeAheadUserEmailChips';
import { PropsInterface } from 'interfaces';
import { AuthUtils } from 'utils';

interface DescriptionTabProps {
	className?: string;
	typeAheadUserEmailChipsProps: PropsInterface.TypeAheadUserEmailChipsProps;
	datePickerProps: PropsInterface.DatePickerProps;

	setDurationUnit: React.Dispatch<React.SetStateAction<string>>;
	setDurationValue: React.Dispatch<React.SetStateAction<number>>;
	setDateOffsetValue: React.Dispatch<React.SetStateAction<number>>;
	setFromValue: React.Dispatch<React.SetStateAction<string>>;
	setToValue: React.Dispatch<React.SetStateAction<string>>;
	setBeforeValue: React.Dispatch<React.SetStateAction<string>>;
	setAfterValue: React.Dispatch<React.SetStateAction<string>>;
	setOnValue: React.Dispatch<React.SetStateAction<string>>;
	setDatePickerDropdownValue: React.Dispatch<React.SetStateAction<string>>;

	refreshFrequencyProps: PropsInterface.RefreshFrequencyProps;

	segmentName: string;
	segmentDescription: string;
	setSegmentName: React.Dispatch<React.SetStateAction<string>>;
	setSegmentDescription: React.Dispatch<React.SetStateAction<string>>;
	isSegmentDynamic: boolean;
	setIsSegmentDynamic: React.Dispatch<React.SetStateAction<boolean>>;
	segmentNameErrorMessage: string;
	setSegmentNameErrorMessage: React.Dispatch<React.SetStateAction<string>>;

	selectedAccess: string;
	setSelectedAccess: React.Dispatch<React.SetStateAction<string>>;

	selectedQueryType: string;
	setSelectedQueryType: React.Dispatch<React.SetStateAction<string>>;

	view: boolean;
}

const DescriptionTab: React.FC<DescriptionTabProps> = ({
	typeAheadUserEmailChipsProps,
	datePickerProps,
	setDurationUnit,
	setDurationValue,
	setDateOffsetValue,
	setFromValue,
	setToValue,
	setBeforeValue,
	setAfterValue,
	setOnValue,
	setDatePickerDropdownValue,
	refreshFrequencyProps,
	segmentName,
	segmentDescription,
	setSegmentName,
	setSegmentDescription,
	isSegmentDynamic,
	setIsSegmentDynamic,
	selectedAccess,
	setSelectedAccess,
	selectedQueryType,
	setSelectedQueryType,
	view,
	segmentNameErrorMessage,
	setSegmentNameErrorMessage
}): JSX.Element => {
	const refreshDatePickerDropdownValue = () => {
		setDurationUnit('DAY');
		setDurationValue(0);
		setDateOffsetValue(0);
		setFromValue('');
		setToValue('');
		setBeforeValue('');
		setAfterValue('');
		setOnValue('');
		setDatePickerDropdownValue('Select the date or duration');
		setIsSegmentDynamic(false);
	};

	return (
		<React.Fragment>
			<ColumnGroup>
				<Column className='is-one-third'>
					<FormField>
						<Label>Name*</Label>
						<FormControl>
							<Input
								className={segmentNameErrorMessage === '' ? '' : 'is-danger'}
								type='text'
								placeholder='Segment Name'
								value={segmentName}
								onChange={(e) => {
									setSegmentName(e.target.value);
									setSegmentNameErrorMessage('');
								}}
								disabled={view}
							/>
						</FormControl>
						<p className={'help has-text-left is-danger'}>{segmentNameErrorMessage}</p>
					</FormField>
					<FormField>
						<Label>Description*</Label>
						<FormControl>
							<Textarea
								placeholder='Segment Description'
								value={segmentDescription}
								onChange={(e) => setSegmentDescription(e.target.value)}
								disabled={view}
							/>
						</FormControl>
					</FormField>
				</Column>
				<Column className='is-one-quarter ml-5'>
					{AuthUtils.isAdmin() && (
						<FormField>
							<Label>Query Type</Label>
							<FormControl className='mb-5'>
								<Radio
									className='ml-0 mr-4'
									label='Rule-based'
									name='queryType'
									value='Simple'
									checked={selectedQueryType === 'Simple'}
									onChange={(e) => {
										refreshDatePickerDropdownValue();
										setSelectedQueryType(e.target.value);
									}}
									disabled={view}
								/>
								<Radio
									className='ml-0 mr-4'
									label='Custom'
									name='queryType'
									value='Custom'
									checked={selectedQueryType === 'Custom'}
									onChange={(e) => {
										refreshDatePickerDropdownValue();
										setSelectedQueryType(e.target.value);
									}}
									disabled={view}
								/>
							</FormControl>
						</FormField>
					)}
					<FormField>
						<Label>Access</Label>
						<FormControl className='mb-5'>
							<Radio
								className='ml-0 mr-4'
								label='Public'
								name='access'
								value='Public'
								checked={selectedAccess === 'Public'}
								onChange={(e) => setSelectedAccess(e.target.value)}
								disabled={view}
							/>
							<Radio
								className='ml-0 mr-4'
								label='Private'
								name='access'
								value='Private'
								checked={selectedAccess === 'Private'}
								onChange={(e) => setSelectedAccess(e.target.value)}
								disabled={view}
							/>
							<Radio
								className='ml-0 mr-4'
								label='Shared'
								name='access'
								value='Shared'
								checked={selectedAccess === 'Shared'}
								onChange={(e) => setSelectedAccess(e.target.value)}
								disabled={view}
							/>
						</FormControl>
					</FormField>
					{selectedAccess === 'Shared' && (
						<FormField>
							<Label>Users to share with</Label>
							<TypeAheadUserEmailChips {...typeAheadUserEmailChipsProps} />
						</FormField>
					)}
				</Column>
				<Column>
					<FormField>
						{selectedQueryType === 'Simple' ? <Label>Date/Duration*</Label> : <Label>Date</Label>}
						<DatePicker
							{...datePickerProps}
							refreshDatePickerDropdownValue={refreshDatePickerDropdownValue}
							isSegmentDynamic={isSegmentDynamic}
							setIsSegmentDynamic={setIsSegmentDynamic}
							view={view}
						/>
					</FormField>
					{isSegmentDynamic && (
						<FormField>
							<Label>Refresh Frequency</Label>
							<RefreshFrequency {...refreshFrequencyProps} />
						</FormField>
					)}
				</Column>
			</ColumnGroup>
		</React.Fragment>
	);
};

export default DescriptionTab;
