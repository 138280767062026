import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faEye,
	faEyeSlash,
	faAngleLeft,
	faAngleRight,
	faEllipsisVertical,
	faEdit,
	faTrashCan,
	faFilter,
	faAnglesRight,
	faAnglesLeft,
	faAngleUp,
	faAngleDown,
	faCirclePlus,
	faSearch,
	faSortUp,
	faSortDown,
	faSort,
	faPlus,
	faPencil,
	faCheck,
	faRefresh,
	faQuestionCircle
} from '@fortawesome/free-solid-svg-icons';

library.add(
	faEye,
	faEyeSlash,
	faAngleLeft,
	faAngleRight,
	faEllipsisVertical,
	faEdit,
	faTrashCan,
	faFilter,
	faAnglesRight,
	faAnglesLeft,
	faAngleUp,
	faAngleDown,
	faCirclePlus,
	faSearch,
	faSortUp,
	faSortDown,
	faSort,
	faPlus,
	faPencil,
	faCheck,
	faRefresh,
	faQuestionCircle
);
