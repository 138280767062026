import React from 'react';
import FormControl from 'components/atoms/FormControl';
import FormField from 'components/atoms/FormField';
import Label from 'components/atoms/Label';
import Textarea from 'components/atoms/TextArea';
import { Helpers } from 'utils';

interface QueryTabProps {
	className?: string;
	query: string;
	setQuery: React.Dispatch<React.SetStateAction<string>>;
	view: boolean;
	isQueryValid: boolean;
	setIsQueryValid: React.Dispatch<React.SetStateAction<boolean>>;
	shouldHaveQueryValid: boolean;
	setShouldHaveQueryValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const QueryTab: React.FC<QueryTabProps> = ({
	query,
	setQuery,
	view,
	isQueryValid,
	setIsQueryValid,
	shouldHaveQueryValid,
	setShouldHaveQueryValid
}): JSX.Element => {
	const { validateQuery, shouldHaveQuery } = Helpers;

	const handleShouldHaveQueryChange = (value: string) => {
		if (shouldHaveQuery(value)) {
			setShouldHaveQueryValid(true);
		} else {
			setShouldHaveQueryValid(false);
		}
	};

	const handleQueryChange = (value: string) => {
		if (validateQuery(value)) {
			setIsQueryValid(true);
		} else {
			setIsQueryValid(false);
		}
		setQuery(value);
	};

	return (
		<React.Fragment>
			<FormField>
				<Label>Query*</Label>
				<FormControl>
					<Textarea
						placeholder='Enter Query'
						value={query}
						onChange={(e) => {
							handleQueryChange(e.target.value), handleShouldHaveQueryChange(e.target.value);
						}}
						disabled={view}
					/>
					{!isQueryValid && (
						<p className='help is-danger'>
							Keyword INSERT, DELETE, UPDATE, ALTER AND DROP are not allowed in custom query. Please remove
							them.
						</p>
					)}
					{!shouldHaveQueryValid && (
						<p className='help is-link'>
							Keyword SELECT (*) FROM are necessary in custom query. Please add them.
						</p>
					)}
				</FormControl>
			</FormField>
		</React.Fragment>
	);
};

export default QueryTab;
