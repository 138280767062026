import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom';
import Login from 'pages/Login';
import Home from 'pages/Home';
import AdminDashboard from 'pages/AdminDashboard';
import UserMutate from 'pages/UserMutate';
import 'bulma/css/bulma.min.css';
import 'fontawesome';
import ProtectedRoute from 'components/atoms/ProtectedRoute';
import SegmentMutate from 'pages/SegmentMutate';

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<Routes>
				<Route path='/' element={<ProtectedRoute adminPage={false} commonOutlet={<Home />} />} />
				<Route path='/auth/:action' element={<Login />} />
				<Route
					path='/users'
					element={<ProtectedRoute adminPage={true} creatorPath='/' adminOutlet={<AdminDashboard />} />}
				/>
				<Route
					path='/user/:action'
					element={<ProtectedRoute adminPage={true} creatorPath='/' adminOutlet={<UserMutate />} />}
				/>
				<Route
					path='/segment/:action'
					element={<ProtectedRoute adminPage={false} commonOutlet={<SegmentMutate />} />}
				/>
			</Routes>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);
