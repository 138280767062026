import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.css';
import DropdownItem from 'components/atoms/DropdownItem';
import Button from 'components/atoms/Button';

interface DatePickerCustomMenuProps {
	activeTab: string;
	durationValue: number;
	setDurationValue: React.Dispatch<React.SetStateAction<number>>;
	durationUnit: string;
	setDurationUnit: React.Dispatch<React.SetStateAction<string>>;
	dateOffsetValue: number;
	setDateOffsetValue: React.Dispatch<React.SetStateAction<number>>;
}

const DatePickerCustomMenu: React.FC<DatePickerCustomMenuProps> = ({
	activeTab,
	durationValue,
	setDurationValue,
	durationUnit,
	setDurationUnit,
	dateOffsetValue,
	setDateOffsetValue
}) => {
	const [isDurationValueDropdownVisible, setIsDurationValueDropdownVisible] = useState(false);
	const [isDurationUnitDropdownVisible, setIsDurationUnitDropdownVisible] = useState(false);
	const [dateOffsetDropdownVisible, setDateOffsetDropdownVisible] = useState(false);
	const numberArray: Array<number> = [];
	for (let i = 1; i <= 365; i++) {
		numberArray.push(i);
	}

	const itemInTheLastUnitSelectHandler = (text: string) => {
		setDurationUnit(text);
		setIsDurationUnitDropdownVisible(!isDurationUnitDropdownVisible);
	};

	return (
		<React.Fragment>
			<div className='is-flex is-justify-content-center mt-3'>
				{activeTab === 'Duration' && (
					<div className='dropdown is-active'>
						<div className='dropdown-trigger'>
							<Button
								aria-haspopup='true'
								aria-controls='dropdown-menu'
								onClick={() => {
									setIsDurationValueDropdownVisible(!isDurationValueDropdownVisible);
								}}
							>
								<span>{durationValue}</span>
								<span className='icon is-small'>
									<FontAwesomeIcon icon={['fas', 'angle-down']} />
								</span>
							</Button>
							<Button
								aria-haspopup='true'
								aria-controls='dropdown-menu'
								onClick={() => setIsDurationUnitDropdownVisible(!isDurationUnitDropdownVisible)}
							>
								<span>{durationUnit}</span>
								<span className='icon is-small'>
									<FontAwesomeIcon icon={['fas', 'angle-down']} aria-hidden='true' />
								</span>
							</Button>
						</div>
						{isDurationValueDropdownVisible && (
							<div className='dropdown-menu' id='dropdown-menu' role='menu'>
								<div className='dropdown-content scrollable-dropdown'>
									{numberArray.map((value, index) => (
										<DropdownItem
											key={index}
											onClick={() => {
												setDurationValue(value);
												setIsDurationValueDropdownVisible(false);
											}}
										>
											{value}
										</DropdownItem>
									))}
								</div>
							</div>
						)}
						{isDurationUnitDropdownVisible && (
							<div className='dropdown-menu' id='dropdown-menu' role='menu'>
								<div className='dropdown-content'>
									<DropdownItem onClick={() => itemInTheLastUnitSelectHandler('DAY')}>Day(s)</DropdownItem>
									<DropdownItem onClick={() => itemInTheLastUnitSelectHandler('WEEK')}>Week(s)</DropdownItem>
									<DropdownItem onClick={() => itemInTheLastUnitSelectHandler('MONTH')}>
										Month(s)
									</DropdownItem>
									<DropdownItem onClick={() => itemInTheLastUnitSelectHandler('QUARTER')}>
										Quarter(s)
									</DropdownItem>
									<DropdownItem onClick={() => itemInTheLastUnitSelectHandler('YEAR')}>Year(s)</DropdownItem>
								</div>
							</div>
						)}
					</div>
				)}
				{activeTab === 'Offset' && (
					<div className='dropdown is-active'>
						<div className='dropdown-trigger'>
							<Button
								aria-haspopup='true'
								aria-controls='dropdown-menu'
								onClick={() => setDateOffsetDropdownVisible(!dateOffsetDropdownVisible)}
							>
								<span>{dateOffsetValue}</span>
								<span className='icon is-small'>
									<FontAwesomeIcon icon={['fas', 'angle-down']} />
								</span>
							</Button>
						</div>
						{dateOffsetDropdownVisible && (
							<div className='dropdown-menu' id='dropdown-menu' role='menu'>
								<div className='dropdown-content scrollable-dropdown'>
									{numberArray.map((value, index) => (
										<DropdownItem
											key={index}
											onClick={() => {
												setDateOffsetValue(value);
												setDateOffsetDropdownVisible(false);
											}}
										>
											{value}
										</DropdownItem>
									))}
								</div>
							</div>
						)}
						<span className='m-2 ml-4'>Day(s) ago</span>
					</div>
				)}
			</div>
		</React.Fragment>
	);
};

export default DatePickerCustomMenu;
