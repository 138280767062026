import Button from 'components/atoms/Button';
import React, { useEffect } from 'react';
import NavBarMenu from 'components/atoms/NavBarMenu';
import NavBarItem from 'components/atoms/NavBarItem';
import { AuthUtils } from 'utils';
import { Auth } from 'services';

interface NavBarProps {
	className?: string;
}

const NavBar: React.FC<NavBarProps> = ({ className }): JSX.Element => {
	const logOut = () => {
		localStorage.removeItem('metadata :');
		Auth.logOut();
	};

	useEffect(() => {
		const burgerIcon = document.querySelector('#burger');
		const navbarMenu = document.querySelector('#nav-links');

		burgerIcon &&
			burgerIcon.addEventListener('click', () => {
				navbarMenu && navbarMenu.classList.toggle('is-active');
			});
	}, []);

	return (
		<nav className={`navbar px-3 ${className || ''}`}>
			<div className='navbar-brand'>
				<NavBarItem link='/'>
					<img src={require('../../../assets/images/logo.png')} />
				</NavBarItem>
				{/* Renders hamburger icon */}
				<a className='navbar-burger' id='burger'>
					<span></span>
					<span></span>
					<span></span>
				</a>
			</div>

			<NavBarMenu id='nav-links'>
				<div className='navbar-start'>
					<NavBarItem link='/'>Home</NavBarItem>

					{AuthUtils.isAdmin() && <NavBarItem link='/users'>Users</NavBarItem>}
				</div>

				<div className='navbar-end'>
					<NavBarItem link='/auth/login'>
						<Button
							className='is-dark'
							onClick={() => {
								localStorage.getItem('metadata :') ? logOut() : {};
							}}
						>
							<strong>{localStorage.getItem('metadata :') ? 'Logout' : 'Login'}</strong>
						</Button>
					</NavBarItem>
				</div>
			</NavBarMenu>
		</nav>
	);
};

export default NavBar;
