import React, { useEffect, useRef, useState } from 'react';
import { Segment } from 'services';
import TypeAhead from 'components/molecules/TypeAhead';
import { ParameterType } from 'interfaces/interface';
import { Constants } from 'utils';
import Modal from 'components/molecules/Modal';

interface TypeAheadParamaterValueProps {
	selectedValue: string;
	setSelectedValue: React.Dispatch<React.SetStateAction<string>>;
	parameterContent?: ParameterType | undefined;
	selectedParameter?: string;
	isContainType?: boolean;
	view?: boolean;
}

const TypeAHeadParameterValue: React.FC<TypeAheadParamaterValueProps> = ({
	selectedValue,
	setSelectedValue,
	parameterContent,
	selectedParameter,
	isContainType,
	view
}): JSX.Element => {
	const [input, setInput] = useState<string>(isContainType ? '' : selectedValue);
	const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
	const [loader, setLoader] = useState<boolean>(false);
	const [data, setData] = useState<Array<{ id?: string; value: string }>>([]);
	const fetchPermission = useRef(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const { unknownError, failedToFetchParameterValues } = Constants.MODAL_DATA;
	const [modalData, setModalData] = useState(unknownError);

	useEffect(() => {
		//Changes in input trigger by rulelist state value change should not trigger api call to fetch drop down data.
		//For this reason fetchPermission is used and set to false
		fetchPermission.current = false;
		if (!isContainType) setInput(selectedValue);
		else setInput('');
	}, [selectedValue]);

	useEffect(() => {
		//Api call to fetch drop down data.
		//Only happens when selected parameter value is not empty and fetch permission is true
		if (selectedParameter !== '' && fetchPermission.current) {
			getDropdownValues();
		}
		//fetchPermission is set to true because on subsequent input change, api call should happen.
		fetchPermission.current = true;
	}, [input]);

	const itemSelectHandler = (value: string) => {
		//fectchPermission is set to false since we don't want to trigger api call after selecting a value from dropdown value.
		fetchPermission.current = false;
		//If operator is 'contain/does not contain', input value is set to empty on selecting a value.
		if (!isContainType) setInput(value);
		else setInput('');
		setSelectedValue(value);
	};

	const onFocusHandler = () => {
		getDropdownValues();
		fetchPermission.current = true;
	};

	const onBlurHandler = () => {
		setSelectedValue(input);
		if (isContainType) setInput('');
		fetchPermission.current = false;
	};

	const getDropdownValues = () => {
		setLoader(true);
		const apiParameter = parameterContent ? parameterContent.apiColumnName : '';
		Segment.getParameterValues(apiParameter, input).then((response) => {
			if (response.status === 200) {
				if (response.data.data.length > 0) {
					const typeAheadData = response.data.data.map((item: string) => {
						return { value: item.toString() };
					});
					setData(typeAheadData);
					setDropdownOpen(true);
				} else {
					setData([]);
					setDropdownOpen(false);
				}
			} else {
				setModalData(failedToFetchParameterValues);
				setIsModalVisible(true);
			}
			setLoader(false);
		});
	};

	const typeAheadProps = {
		data: data,
		inputType: 'text',
		handleClick: itemSelectHandler,
		input: input,
		setInput: setInput,
		dropdownOpen: dropdownOpen,
		setDropdownOpen: setDropdownOpen,
		loader: loader,
		focusHandler: onFocusHandler,
		blurHandler: onBlurHandler,
		view: view
	};
	return (
		<React.Fragment>
			<TypeAhead {...typeAheadProps}></TypeAhead>
			<Modal data={modalData} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
		</React.Fragment>
	);
};
export default TypeAHeadParameterValue;
