import React, { useEffect, useState } from 'react';
import NavBar from 'components/molecules/NavBar';
import PageHeader from 'components/molecules/PageHeader';
import Header from 'components/atoms/Header';
import Section from 'components/atoms/Section';
import DescriptionTab from 'components/molecules/DescriptionTab';
import FiltersTab from 'components/molecules/FiltersTab';
import FieldsTab from 'components/molecules/FieldsTab';
import PartnersTab from 'components/molecules/PartnersTab';
import Tabs from 'components/atoms/Tabs';
import Button from 'components/atoms/Button';
import { Interface } from 'interfaces';
import Footer from 'components/atoms/Footer';
import QueryTab from 'components/molecules/QueryTab';
import { Constants, Helpers } from 'utils';
import { Auth, Segment } from 'services';
import Modal from 'components/molecules/Modal';
import HeroContainer from 'components/atoms/HeroContainer';
import Body from 'components/atoms/Body';
import Container from 'components/atoms/Container';
import Loader from 'components/atoms/Loader';
import { RuleType } from 'interfaces/interface';
import { useLocation, Link, useParams, useSearchParams } from 'react-router-dom';
import { getUserId, isAdmin } from 'utils/auth.utils';
import PageNotFound from 'pages/PageNotFound';

const { validateName } = Helpers;

interface Rule {
	rules: Array<RuleType>;
	condition: string | null;
}

const SegmentMutate: React.FC = (): JSX.Element => {
	const [modalAction, setModalAction] = useState<(() => void) | undefined>(undefined);
	const {
		createRuleGroupPayload,
		getFormattedDate,
		durationDropdownValueFormatter,
		offsetDropdownValueFormatter,
		createRuleGroupState,
		createWorkAreaRules
	} = Helpers;
	const { createSegment, getSegmentById, updateSegment } = Segment;

	const [activeTab, setActiveTab] = useState('DESCRIPTION');
	const [isRuleListValid, setIsRuleListValid] = useState(true);
	const [isPreviewValid, setIsPreviewValid] = useState(true);

	const { state } = useLocation();
	const { action } = useParams();

	//For setting and removing view (read-only) mode
	const [view, setView] = useState(!(state === 'UPDATE') && action === 'update');

	//Description Tab
	const [segmentName, setSegmentName] = useState(''); //Segment Name
	const [segmentNameErrorMessage, setSegmentNameErrorMessage] = useState('');
	const [segmentDescription, setSegmentDescription] = useState(''); //Segment Description

	const [selectedAccess, setSelectedAccess] = useState('Private'); //Different access

	const [selectedQueryType, setSelectedQueryType] = useState('Simple'); //Different Query creation types

	const [userData, setUserData] = useState<Array<{ id?: string; value: string }>>([]); //Shared with users
	const [userEmailChips, setUserEmailChips] = useState<string[]>([]); //Shared with users
	const [userId, setUserId] = useState<string[]>([]);

	//Current date
	const date = new Date();
	const presentDate = getFormattedDate(date);
	const [startDate, setStartDate] = useState(presentDate); //Date-Duration Picker
	const [endDate, setEndDate] = useState(presentDate); //Date-Duration Picker
	const [datePickerDropdownValue, setDatePickerDropdownValue] = useState(''); //Date Picker
	const [durationValue, setDurationValue] = useState(0); //Duration Tab
	const [durationUnit, setDurationUnit] = useState('DAY'); //Duration Tab
	const [dateOffsetValue, setDateOffsetValue] = useState(0); //Offset Tab
	const [fromValue, setFromValue] = useState(''); //Date Tab
	const [toValue, setToValue] = useState(''); //Date Tab
	const [beforeValue, setBeforeValue] = useState(''); //Date Tab
	const [afterValue, setAfterValue] = useState(''); //Date Tab
	const [onValue, setOnValue] = useState(''); //Date Tab
	const [isRangeValid, setIsRangeValid] = useState(true); //Validity for between dates

	const [isSegmentDynamic, setIsSegmentDynamic] = useState(false); //Segment Dynamic or Static
	const [refreshFrequencyUnit, setRefreshFrequencyUnit] = useState('Hours'); //Refresh Frequency Component
	const [refreshFrequencyInput, setRefreshFrequencyInput] = useState(''); //Refresh Frequency Component
	const [refreshFrequencyErrorMessage, setRefreshFrequencyErrorMessage] = useState(''); //Refresh Frequency Component

	//Filters Tab
	const [ruleList, setRuleList] = useState<RuleType[]>([]);
	const [ruleGroups, setRuleGroups] = useState<Array<Rule>>([
		{
			rules: [],
			condition: null
		}
	]);
	const [havingRuleGroups, setHavingRuleGroups] = useState<Array<Rule>>([
		{
			rules: [],
			condition: null
		}
	]);
	const [preview, setPreview] = useState(''); //WhereArea query preview
	const [havingPreview, setHavingPreview] = useState(''); //HavingArea query preview
	const [subscriptionStatus, setSubscriptionStatus] = useState(''); //Current subscription status

	//Fields Tab
	const [selectedFields, setSelectedFields] = useState<string[]>([]);

	//Query Tab
	const [query, setQuery] = useState('');
	const [isQueryValid, setIsQueryValid] = useState(true);
	const [shouldHaveQueryValid, setShouldHaveQueryValid] = useState(false);

	//Partners Tab
	const [isDownloadSelected, setIsDownloadSelected] = useState(false);
	const [selectedPartners, setSelectedPartners] = useState<string[]>([]);
	const [gamData, setGamData] = useState<Array<{ id?: string; value: string }>>([]);
	const [gamInput, setGamInput] = useState<string>('');
	const [currentValidGamInput, setCurrentValidGamInput] = useState(gamInput);
	const [gamId, setGamId] = useState('');

	const [tabs, setTabs] = useState<Array<{ name: string; displayName: string }>>([]);
	const {
		unknownError,
		segmentNameAlreadyExists,
		unAuthorized,
		segmentNotExists,
		accessNotAllowed
	} = Constants.MODAL_DATA;

	//Modal display
	const [isModalVisible, setIsModalVisible] = useState(false); //eslint-disable-line
	const [isLoading, setIsLoading] = useState(false); //eslint-disable-line
	const [modalData, setModalData] = useState(unknownError); //eslint-disable-line

	const [ownerId, setOwnerId] = useState('');

	const simpleQueryTabs = [
		{ name: 'DESCRIPTION', displayName: 'Description' },
		{ name: 'FILTERS', displayName: 'Filters' },
		{ name: 'FIELDS', displayName: 'Fields' },
		{ name: 'PARTNERS', displayName: 'Partners' }
	];

	const customQueryTabs = [
		{ name: 'DESCRIPTION', displayName: 'Description' },
		{ name: 'QUERY', displayName: 'Query' },
		{ name: 'PARTNERS', displayName: 'Partners' }
	];

	useEffect(() => {
		selectedQueryType === 'Custom' ? setTabs(customQueryTabs) : setTabs(simpleQueryTabs);
	}, [selectedQueryType]);

	const onNext = () => {
		if (activeTab === 'DESCRIPTION') {
			if (selectedQueryType === 'Simple') {
				setActiveTab('FILTERS');
			} else {
				setActiveTab('QUERY');
			}
		} else if (activeTab === 'FILTERS') {
			setActiveTab('FIELDS');
		} else if (activeTab === 'FIELDS' || activeTab === 'QUERY') {
			setActiveTab('PARTNERS');
		}
	};

	const onBack = () => {
		if (activeTab === 'PARTNERS') {
			if (selectedQueryType === 'Simple') {
				setActiveTab('FIELDS');
			} else {
				setActiveTab('QUERY');
			}
		} else if (activeTab === 'FIELDS') {
			setActiveTab('FILTERS');
		} else if (activeTab === 'FILTERS' || activeTab === 'QUERY') {
			setActiveTab('DESCRIPTION');
		}
	};

	const [searchParams] = useSearchParams();
	const segmentId = searchParams.get('id') || '';

	//Validity of both, simple and compound rules
	//Neither rules should have any empty field
	const checkRuleListValidity = (): void => {
		let valid = true;
		ruleList.forEach((rule) => {
			if (rule.type === 'simple') {
				if (!checkSubRuleValidity(rule.subRules[0], false)) {
					valid = false;
				}
			} else {
				rule.subRules.forEach((subRule, index) => {
					if (index !== 0) {
						if (!checkSubRuleValidity(subRule, false)) {
							valid = false;
						}
					} else if (rule.isHaving) {
						if (!checkSubRuleValidity(subRule, true)) {
							valid = false;
						}
					}
				});
				//empty compound rule check
				if (!rule.isHaving) {
					if (rule.subRules.length < 2) valid = false;
				}
			}
		});
		setIsRuleListValid(valid);
	};

	//Validating preview, preview should neither be empty nor it should have <, > symbols
	const checkPreviewValidity = () => {
		let valid = true;
		if (preview.trim() === '' && havingPreview.trim() === '') {
			valid = false;
		} else if (
			preview.includes('<') ||
			preview.includes('>') ||
			preview.includes('(  )') ||
			havingPreview.includes('<') ||
			havingPreview.includes('>') ||
			havingPreview.includes('(  )')
		) {
			valid = false;
		} else {
			valid = true;
		}
		setIsPreviewValid(valid);
	};

	//Validating sub rule, it should never have empty fields
	const checkSubRuleValidity = (rule: Interface.subRuleType, having: boolean): boolean => {
		return (
			rule.parameter !== '' &&
			rule.operator !== '' &&
			(rule.operator === 'between'
				? rule.max !== '' && rule.min !== ''
				: rule.operator === 'exists' || rule.operator === 'does not exist'
				? true
				: rule.value[0] !== '') &&
			(!having ? true : rule.having !== undefined && rule.having !== '' ? true : false)
		);
	};

	const datePickerProps = {
		presentDate: presentDate,
		setStartDate: setStartDate,
		setEndDate: setEndDate,
		durationValue: durationValue,
		setDurationValue: setDurationValue,
		durationUnit: durationUnit,
		setDurationUnit: setDurationUnit,
		dateOffsetValue: dateOffsetValue,
		setDateOffsetValue: setDateOffsetValue,
		fromValue: fromValue,
		setFromValue: setFromValue,
		toValue: toValue,
		setToValue: setToValue,
		isRangeValid: isRangeValid,
		setIsRangeValid: setIsRangeValid,
		beforeValue: beforeValue,
		setBeforeValue: setBeforeValue,
		afterValue: afterValue,
		setAfterValue: setAfterValue,
		onValue: onValue,
		setOnValue: setOnValue,
		datePickerDropdownValue: datePickerDropdownValue,
		setDatePickerDropdownValue: setDatePickerDropdownValue,
		view: view,
		selectedQueryType: selectedQueryType,
		setRefreshFrequencyInput: setRefreshFrequencyInput
	};

	const refreshDatePickerProps = {
		setDurationUnit: setDurationUnit,
		setDurationValue: setDurationValue,
		setDateOffsetValue: setDateOffsetValue,
		setFromValue: setFromValue,
		setToValue: setToValue,
		setBeforeValue: setBeforeValue,
		setAfterValue: setAfterValue,
		setOnValue: setOnValue,
		setDatePickerDropdownValue: setDatePickerDropdownValue
	};

	const shareWithProps = {
		userData: userData,
		setUserData: setUserData,
		userId: userId,
		setUserId: setUserId,
		userEmailChips: userEmailChips,
		setUserEmailChips: setUserEmailChips,
		view: view
	};

	const queryTypeProps = {
		selectedQueryType: selectedQueryType,
		setSelectedQueryType: setSelectedQueryType
	};

	const accessTypeProps = {
		selectedAccess: selectedAccess,
		setSelectedAccess: setSelectedAccess
	};

	const RefreshFrequencyProps = {
		refreshFrequencyUnit: refreshFrequencyUnit,
		setRefreshFrequencyUnit: setRefreshFrequencyUnit,
		refreshFrequencyInput: refreshFrequencyInput,
		setRefreshFrequencyInput: setRefreshFrequencyInput,
		refreshFrequencyErrorMessage: refreshFrequencyErrorMessage,
		setRefreshFrequencyErrorMessage: setRefreshFrequencyErrorMessage,
		view: view
	};

	const descriptionProps = {
		segmentName: segmentName,
		segmentDescription: segmentDescription,
		setSegmentName: setSegmentName,
		setSegmentDescription: setSegmentDescription,
		isSegmentDynamic: isSegmentDynamic,
		setIsSegmentDynamic: setIsSegmentDynamic,
		view: view,
		segmentNameErrorMessage: segmentNameErrorMessage,
		setSegmentNameErrorMessage: setSegmentNameErrorMessage
	};

	const filterProps = {
		ruleList: ruleList,
		setRuleList: setRuleList,
		ruleGroups,
		setRuleGroups,
		havingRuleGroups,
		setHavingRuleGroups,
		preview,
		setPreview,
		havingPreview,
		setHavingPreview,
		view,
		subscriptionStatus,
		setSubscriptionStatus
	};

	const fieldProps = {
		selectedFields: selectedFields,
		setSelectedFields: setSelectedFields,
		selectedPartners: selectedPartners,
		view: view
	};

	const queryProps = {
		query: query,
		setQuery: setQuery,
		view: view,
		isQueryValid: isQueryValid,
		setIsQueryValid: setIsQueryValid,
		shouldHaveQueryValid: shouldHaveQueryValid,
		setShouldHaveQueryValid: setShouldHaveQueryValid
	};

	const partnerProps = {
		isDownloadSelected: isDownloadSelected,
		setIsDownloadSelected: setIsDownloadSelected,
		selectedPartners: selectedPartners,
		setSelectedPartners: setSelectedPartners,
		selectedFields: selectedFields,
		view: view,
		selectedQueryType: selectedQueryType,
		data: gamData,
		setData: setGamData,
		gamInput: gamInput,
		setGamInput: setGamInput,
		currentValidGamInput: currentValidGamInput,
		setCurrentValidGamInput: setCurrentValidGamInput,
		setGamId: setGamId
	};

	useEffect(() => {
		checkRuleListValidity();
	}, [ruleList]);

	useEffect(() => {
		checkPreviewValidity();
	}, [preview, havingPreview]);

	const clearJwt = () => {
		localStorage.removeItem('metadata :');
		Auth.logOut();
	};

	useEffect(() => {
		if (segmentId.length) {
			setIsLoading(true);
			getSegmentById(segmentId).then((response) => {
				if (response.status === 200 && response.data.length > 0) {
					const {
						ownerId,
						name,
						description,
						startDate,
						endDate,
						updateFrequency,
						updateFrequencyTimeUnit,
						access,
						sharedWithUsers,
						dateOffset,
						timeFrameFrequency,
						timeFrameUnit,
						isCustomQuery,
						ruleGroup,
						customQuery,
						sharedWithPartners,
						fields,
						toDownload,
						whereAreaQuery,
						havingAreaQuery,
						subscriptionStatus,
						gamListId,
						listName
					} = response.data[0];

					setRuleList(createRuleGroupState(ruleGroup));

					setOwnerId(ownerId);
					action === 'update' ? setSegmentName(name) : null;
					setSegmentDescription(description);

					const newStartDate = getFormattedDate(new Date(startDate));
					const newEndDate = getFormattedDate(new Date(endDate));
					setStartDate(newStartDate);
					setEndDate(newEndDate);
					setRefreshFrequencyInput(updateFrequency);
					setRefreshFrequencyUnit(updateFrequencyTimeUnit);
					if (
						updateFrequency !== 0 &&
						updateFrequency !== '' &&
						updateFrequency !== null &&
						updateFrequency !== undefined
					) {
						setIsSegmentDynamic(true);
					}
					setSelectedAccess(access);
					setUserData(sharedWithUsers);

					const userEmailIds = sharedWithUsers.map((suggestion: { userid: string; email: string }) => {
						return suggestion.email;
					});
					setUserEmailChips(userEmailIds);

					const userIds = sharedWithUsers.map((suggestion: { userid: string; email: string }) => {
						return suggestion.userid;
					});
					setUserId(userIds);

					setRuleGroups(createWorkAreaRules(createRuleGroupState(ruleGroup), whereAreaQuery));
					setHavingRuleGroups(createWorkAreaRules(createRuleGroupState(ruleGroup), havingAreaQuery));

					setDateOffsetValue(dateOffset ? dateOffset : 0);
					setDurationValue(timeFrameFrequency ? timeFrameFrequency : 0);
					setDurationUnit(timeFrameUnit ? timeFrameUnit : '');
					if (newStartDate === '2000-01-01' && newEndDate === '2099-12-31') {
						setDatePickerDropdownValue(
							`${durationDropdownValueFormatter(
								timeFrameFrequency,
								timeFrameUnit
							)}${offsetDropdownValueFormatter(timeFrameFrequency, dateOffset)}`
						);
					} else if (newStartDate === newEndDate) {
						setDatePickerDropdownValue(
							`${durationDropdownValueFormatter(
								timeFrameFrequency,
								timeFrameUnit
							)}${offsetDropdownValueFormatter(timeFrameFrequency, dateOffset)}on ${newStartDate}`
						);
						setOnValue(newStartDate);
					} else if (newStartDate === '2000-01-01') {
						setDatePickerDropdownValue(
							`${durationDropdownValueFormatter(
								timeFrameFrequency,
								timeFrameUnit
							)}${offsetDropdownValueFormatter(timeFrameFrequency, dateOffset)}before ${newEndDate}`
						);
						setBeforeValue(newEndDate);
					} else if (newEndDate === '2099-12-31') {
						setDatePickerDropdownValue(
							`${durationDropdownValueFormatter(
								timeFrameFrequency,
								timeFrameUnit
							)}${offsetDropdownValueFormatter(timeFrameFrequency, dateOffset)}after ${newStartDate}`
						);
						setAfterValue(newStartDate);
					} else {
						setDatePickerDropdownValue(
							`${durationDropdownValueFormatter(
								timeFrameFrequency,
								timeFrameUnit
							)}${offsetDropdownValueFormatter(
								timeFrameFrequency,
								dateOffset
							)}from ${newStartDate} to ${newEndDate}`
						);
						setFromValue(newStartDate);
						setToValue(newEndDate);
					}
					setSelectedQueryType(isCustomQuery ? 'Custom' : 'Simple');
					setQuery(customQuery);
					setSelectedPartners(sharedWithPartners);
					setSelectedFields(fields);
					setIsDownloadSelected(toDownload);
					setPreview(whereAreaQuery);
					setHavingPreview(havingAreaQuery);
					setSubscriptionStatus(subscriptionStatus);
					setGamId(gamListId);
					setGamInput(listName);
				}
				//If you are not an owner of the segment or not the Admin
				else if (response.status === 401) {
					setModalData(unAuthorized);
					setIsModalVisible(true);
					setModalAction(() => clearJwt());
				} else if (response.status === 404) {
					setModalData(segmentNotExists);
					setIsModalVisible(true);
				} else {
					setModalData(unknownError);
					setIsModalVisible(true);
				}
				setIsLoading(false);
			});
		}
	}, []);

	const onCreateSubmit = async () => {
		if (validateName(segmentName)) {
			setIsLoading(true);
			createSegment(
				segmentName.trim(),
				segmentDescription,
				startDate,
				endDate,
				refreshFrequencyInput === '0' || refreshFrequencyInput === '' ? null : refreshFrequencyInput,
				refreshFrequencyInput === '0' || refreshFrequencyInput === '' ? null : refreshFrequencyUnit,
				selectedAccess,
				userId,
				dateOffsetValue,
				durationValue,
				durationUnit,
				selectedQueryType === 'Custom',
				query,
				selectedPartners,
				selectedFields,
				isDownloadSelected,
				preview,
				havingPreview,
				createRuleGroupPayload(ruleList),
				subscriptionStatus,
				gamId
			).then((response) => {
				if (response.status === 200) {
					setModalData({
						title: 'Success',
						content: `'${segmentName.trim()}' has been successfully created!`,
						path: '/'
					});
				}
				//User cannot have two segments with same name
				else if (response.status === 400) {
					setModalData(segmentNameAlreadyExists);
				} else if (response.status === 401) {
					//If user is a Creator and segment is created through custom queries, user is not allowed to create such segment
					if (response.data.error.message.includes('custom queries')) {
						setModalData(accessNotAllowed);
					} else {
						setModalAction(() => clearJwt());
						setModalData(unAuthorized);
					}
				} else {
					setModalData(unknownError);
				}
				setIsLoading(false);
				setIsModalVisible(true);
			});
		} else {
			setSegmentNameErrorMessage('Segment name cannot be blank');
		}
	};

	const onUpdateSubmit = () => {
		if (validateName(segmentName)) {
			setIsLoading(true);
			updateSegment(
				segmentId,
				segmentName.trim(),
				segmentDescription,
				startDate,
				endDate,
				refreshFrequencyInput === '0' ||
					refreshFrequencyInput === '' ||
					refreshFrequencyInput === undefined
					? null
					: refreshFrequencyInput,
				refreshFrequencyInput === '0' ||
					refreshFrequencyInput === '' ||
					refreshFrequencyInput === undefined
					? null
					: refreshFrequencyUnit,
				selectedAccess,
				userId,
				dateOffsetValue,
				durationValue,
				durationUnit,
				selectedQueryType === 'Custom',
				query,
				selectedPartners,
				selectedFields,
				isDownloadSelected,
				preview,
				havingPreview,
				createRuleGroupPayload(ruleList),
				subscriptionStatus,
				gamId
			).then((response) => {
				if (response.status === 200) {
					setModalData({
						title: 'Success',
						content: `'${segmentName}' has been successfully updated!`,
						path: '/'
					});
				}
				//While updating, user cannot have the name of the segment similar to the one existing in the table
				else if (response.status === 400) {
					setModalData({
						title: 'An error has occured',
						content: `The segment with this name '${segmentName}' already exists`,
						path: ''
					});
				} else if (response.status === 401) {
					setModalAction(() => clearJwt());
					setModalData(unAuthorized);
				} else {
					setModalData(unknownError);
				}
				setIsLoading(false);
				setIsModalVisible(true);
			});
		} else {
			setSegmentNameErrorMessage('Segment name cannot be blank');
		}
	};

	return (
		<React.Fragment>
			{action !== 'create' && action !== 'update' ? (
				<PageNotFound />
			) : (
				<React.Fragment>
					<Header className='pl-3'>
						<NavBar />
					</Header>
					{!isLoading ? (
						<React.Fragment>
							<PageHeader
								pageTitle={
									action === 'create' ? 'Create Segment' : view === true ? 'View Segment' : 'Update Segment'
								}
								setView={setView}
								disabled={!isAdmin() && ownerId !== getUserId()}
							/>
							<Section>
								<Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
								{activeTab === 'DESCRIPTION' && (
									<DescriptionTab
										{...descriptionProps}
										{...accessTypeProps}
										{...queryTypeProps}
										{...refreshDatePickerProps}
										typeAheadUserEmailChipsProps={shareWithProps}
										datePickerProps={datePickerProps}
										refreshFrequencyProps={RefreshFrequencyProps}
									/>
								)}
								{selectedQueryType === 'Simple' && (
									<>
										{activeTab === 'FILTERS' && <FiltersTab {...filterProps} />}
										{activeTab === 'FIELDS' && <FieldsTab {...fieldProps} />}
									</>
								)}
								{selectedQueryType === 'Custom' && activeTab === 'QUERY' && <QueryTab {...queryProps} />}
								{activeTab === 'PARTNERS' && <PartnersTab {...partnerProps} />}
							</Section>
							<Footer>
								<div className='is-flex is-justify-content-right mb-5'>
									{activeTab !== 'DESCRIPTION' ? (
										<Button className='is-outline mr-2' onClick={onBack}>
											Back
										</Button>
									) : (
										<Link to='/'>
											<Button className='is-text'>Cancel</Button>
										</Link>
									)}

									{activeTab === 'PARTNERS' && selectedQueryType === 'Simple' ? (
										<Button
											className='mr-6 is-dark'
											onClick={() => {
												action === 'create' ? onCreateSubmit() : onUpdateSubmit();
											}}
											disabled={
												!isRuleListValid ||
												selectedFields.length === 0 ||
												segmentName.trim() === '' ||
												segmentDescription === '' ||
												!isPreviewValid ||
												view ||
												(selectedPartners.includes('GoogleAdManager') && gamId === '') ||
												(durationValue === 0 &&
													fromValue === '' &&
													toValue === '' &&
													beforeValue === '' &&
													afterValue === '' &&
													onValue === '')
											}
										>
											{action === 'create' ? 'Create' : 'Update'}
										</Button>
									) : activeTab === 'PARTNERS' && selectedQueryType === 'Custom' ? (
										<Button
											className='mr-6 is-dark'
											onClick={() => {
												action === 'create' ? onCreateSubmit() : onUpdateSubmit();
											}}
											disabled={
												segmentName.trim() === '' ||
												segmentDescription === '' ||
												view ||
												!isQueryValid ||
												(selectedPartners.includes('GoogleAdManager') && gamId === '') ||
												!shouldHaveQueryValid
											}
										>
											{action === 'create' ? 'Create' : 'Update'}
										</Button>
									) : activeTab === 'DESCRIPTION' && selectedQueryType === 'Simple' ? (
										<Button
											className='mr-6 is-dark'
											onClick={onNext}
											disabled={
												segmentName.trim() === '' ||
												segmentDescription === '' ||
												(durationValue === 0 &&
													fromValue === '' &&
													toValue === '' &&
													beforeValue === '' &&
													afterValue === '' &&
													onValue === '') ||
												view
											}
										>
											Next
										</Button>
									) : activeTab === 'DESCRIPTION' && selectedQueryType === 'Custom' ? (
										<Button
											className='mr-6 is-dark'
											onClick={onNext}
											disabled={segmentName.trim() === '' || segmentDescription === '' || view}
										>
											Next
										</Button>
									) : activeTab === 'QUERY' ? (
										<Button
											className='mr-6 is-dark'
											onClick={onNext}
											disabled={!isQueryValid || query === '' || !shouldHaveQueryValid || view}
										>
											Next
										</Button>
									) : activeTab === 'FILTERS' ? (
										<Button
											className='mr-6 is-dark'
											onClick={onNext}
											disabled={!isRuleListValid || !isPreviewValid || view}
										>
											Next
										</Button>
									) : activeTab === 'FIELDS' ? (
										<Button className='mr-6 is-dark' onClick={onNext} disabled={selectedFields.length === 0}>
											Next
										</Button>
									) : (
										<Button className='mr-6 is-dark' onClick={onNext}>
											Next
										</Button>
									)}
								</div>
							</Footer>
						</React.Fragment>
					) : (
						<HeroContainer className='is-fullheight is-flex is-justify-content-center'>
							<Body>
								<Container className='is-flex is-justify-content-center'>
									<Loader className='icon is-large' />
								</Container>
							</Body>
						</HeroContainer>
					)}
					<Modal
						data={modalData}
						isModalVisible={isModalVisible}
						setIsModalVisible={setIsModalVisible}
						action={modalAction}
						setAction={setModalAction}
					/>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

export default SegmentMutate;
