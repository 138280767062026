import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import FilterMenuList from '../FilterMenuList';
import Button from 'components/atoms/Button';
import './style.css';
interface KeyString {
	[key: string]: string;
}

interface SegmentFilterProps {
	className?: string;
	applyFilter: () => void;
	accessFilters: Array<KeyString>;
	partnerFilters: Array<KeyString>;
	ownerFilters: Array<KeyString>;
	statusFilters: Array<KeyString>;
	selectedAccessFilter: React.MutableRefObject<string[]>;
	selectedPartnerFilter: React.MutableRefObject<string[]>;
	selectedOwnerFilter: React.MutableRefObject<string[]>;
	selectedStatusFilter: React.MutableRefObject<string[]>;
}

const SegmentFilter: React.FC<SegmentFilterProps> = ({
	applyFilter,
	accessFilters,
	partnerFilters,
	ownerFilters,
	statusFilters,
	selectedAccessFilter,
	selectedPartnerFilter,
	selectedOwnerFilter,
	selectedStatusFilter
}): JSX.Element => {
	const [filterShown, setFilterShown] = useState(false);
	const accessProps = {
		filterTitle: 'Segment Type',
		filters: accessFilters,
		selectedFilter: selectedAccessFilter
	};

	const partnerProps = {
		filterTitle: 'Shared with Partner',
		filters: partnerFilters,
		selectedFilter: selectedPartnerFilter
	};

	const ownerProps = {
		filterTitle: 'Owner',
		filters: ownerFilters,
		selectedFilter: selectedOwnerFilter
	};

	const statusProps = {
		filterTitle: 'Status',
		filters: statusFilters,
		selectedFilter: selectedStatusFilter
	};

	const showFilterToggle = () => {
		setFilterShown(!filterShown);
	};

	return (
		<React.Fragment>
			<div className='filter-sidenav has-background-light'>
				<div className='is-flex is-justify-content-space-between mt-2'>
					<div className='is-flex is-justify-content-start is-align-items-center'>
						<Button className='is-small ml-2 is-light filter-icon' onClick={showFilterToggle}>
							<FontAwesomeIcon icon={['fas', 'filter']} />
						</Button>
						{filterShown && <span className='has-text-weight-bold ml-3'>Filters</span>}
					</div>
					{filterShown && (
						<Button className='is-small is-dark mx-2' onClick={applyFilter}>
							Apply
						</Button>
					)}
				</div>
				{filterShown && (
					<div className='p-4'>
						<FilterMenuList {...accessProps}></FilterMenuList>
						<FilterMenuList {...partnerProps}></FilterMenuList>
						<FilterMenuList {...ownerProps}></FilterMenuList>
						<FilterMenuList {...statusProps}></FilterMenuList>
					</div>
				)}
				<div className='filter-toggle-button-container pb-2'>
					<Button className='is-small mx-2 is-light is-block' onClick={showFilterToggle}>
						{!filterShown && <FontAwesomeIcon icon={['fas', 'angles-right']} />}
						{filterShown && <FontAwesomeIcon icon={['fas', 'angles-left']} />}
					</Button>
				</div>
			</div>
		</React.Fragment>
	);
};

export default SegmentFilter;
