import { ParameterType, KeyStringArray } from 'interfaces/interface';

// Messages appearing in Modal for different success and failure scenarios
const MODAL_DATA = {
	successfulPasswordSet: {
		title: 'Success',
		content: 'Your password has been set successfully. Please login using your credentials.',
		path: '/auth/login'
	},

	invalidCredentials: {
		title: 'An error has occured',
		content: 'Invalid user credentials',
		path: ''
	},

	unknownError: {
		title: 'An error has occured',
		content: 'An unkown error has occured',
		path: ''
	},

	invalidPassword: {
		title: 'An error has occured',
		content: 'Please enter a valid password',
		path: ''
	},

	forgotPasswordSuccess: {
		title: 'Reset Password',
		content:
			'If you are a registered user, you will receive a password reset mail. If you do not receive an email, please contact your administrator. Check your registered email inbox!',
		path: ''
	},

	successfulUserCreation: {
		title: 'Success',
		content: 'User has been successfully created!',
		path: '/users'
	},

	successfulUserUpdation: {
		title: 'Success',
		content: 'User profile has been successfully updated!',
		path: '/users'
	},

	userExists: {
		title: 'An error has occured',
		content: 'This user already exists',
		path: ''
	},

	userNotExist: {
		title: 'An error has occured',
		content: 'User does not exist',
		path: '/users'
	},

	selectAllFields: {
		title: 'An error has occured',
		content: 'Please select all the fields',
		path: ''
	},

	sessionExpired: {
		title: 'An error has occured',
		content: 'Your session has expired. Please login again',
		path: '/auth/login'
	},

	userLocked: {
		title: 'An error has occured',
		content: 'User does not exist or has been locked, please contact your administrator',
		path: ''
	},

	previousPassword: {
		title: 'An error has occured',
		content: 'You cannot reuse any of the last 5 passwords',
		path: ''
	},

	invalidResetLink: {
		title: 'An error has occured',
		content: 'Invalid Reset Link',
		path: '/auth/forgotpassword'
	},

	expiredResetLink: {
		title: 'An error has occured',
		content: 'The reset link has expired',
		path: ''
	},

	noUpdates: {
		title: 'Alert',
		content: 'No updates were performed',
		path: ''
	},

	duplicateResetPassword: {
		title: 'An error has occured',
		content: 'Password cannot be changed more than once in 24 hours',
		path: ''
	},

	deactivateUsersAlert: {
		title: 'Alert',
		content: 'The selected User(s) will be deactivated',
		path: ''
	},

	deactivateUsersFailed: {
		title: 'An error has occurred',
		content: 'Fail to deactivate selected User(s). Please retry!',
		path: ''
	},

	fetchUsersFailed: {
		title: 'An error has occurred',
		content: 'Failed to fetch Users.',
		path: ''
	},

	confirmResetPassword: {
		title: 'Alert',
		content: 'This action will send a password setting link to the user. Do you wish to continue?',
		path: ''
	},

	resetPasswordSuccess: {
		title: 'Success',
		content: 'Password reset link sent to user successfully',
		path: ''
	},

	userUnlockSuccess: {
		title: 'Success',
		content: 'User activated successfully',
		path: ''
	},

	confirmUserUnlock: {
		title: 'Alert',
		content: 'This action will enable the user to login. Do you wish to continue?',
		path: ''
	},

	fetchSegmentsFailed: {
		title: 'An error has occurred',
		content: 'Failed to fetch Segments.',
		path: ''
	},

	segmentsDeleteSuccessful: {
		title: 'Segment(s) Deleted',
		content: 'Selected segment(s) deleted successfully.',
		path: ''
	},

	segmentsDeleteFailed: {
		title: 'An error has occured',
		content: 'Failed to delete selected Segment(s).',
		path: ''
	},

	deleteSegmentsAlert: {
		title: 'Alert',
		content: 'The selected Segment(s) will be deleted.',
		path: ''
	},

	unAuthorized: {
		title: 'An error has occured',
		content: 'Either you have been logged out or not allowed. Please login again',
		path: '/auth/login'
	},

	segmentNameAlreadyExists: {
		title: 'An error has occured',
		content: 'The segment with this name already exists',
		path: ''
	},

	segmentNotExists: {
		title: 'An error has occured',
		content: 'Segment does not exist',
		path: '/'
	},

	exportSuccessful: {
		title: 'Success',
		content: 'Segment has been successfully exported to partner.',
		path: ''
	},

	exportFailed: {
		title: 'Export failed!',
		content: 'Unable to export segment to partner.',
		path: ''
	},

	segmentDownloadFailed: {
		title: 'Download failed!',
		content: 'Unable to download segment.',
		path: ''
	},

	fetchSegmentDetailsFailed: {
		title: 'An error has occurred',
		content: 'Failed to fetch segment details',
		path: ''
	},

	failedToLoadAudience: {
		title: 'An error has occured',
		content: 'Failed to load the GAM audience list',
		path: ''
	},

	downloadListFailed: {
		title: 'An error has occured',
		content: 'Failed to load the list of files to download',
		path: ''
	},

	accessNotAllowed: {
		title: 'An error has occured',
		content: 'User does not have permission to create or view segments with custom queries',
		path: '/'
	},

	failedToFetchParameterValues: {
		title: 'An error has occured',
		content: 'Failed to fetch parameter values',
		path: ''
	},

	failedToFetchUsersList: {
		title: 'An error has occured',
		content: 'Failed to fetch Users list',
		path: ''
	},

	failedToFetchFilterList: {
		title: 'An error has occured',
		content: 'Failed to fetch filter list',
		path: ''
	}
};

// Mapping of parameters from backend (column name) to frontend (display name) used for creating rules
const ParameterMapping: ParameterType[] = [
	{
		displayName: 'CAC Value',
		category: 'Other',
		isHaving: false,
		columnType: 'string',
		apiColumnName: '__cacValue__'
	},
	{
		displayName: 'Duration',
		category: 'Other',
		isHaving: false,
		columnType: 'number',
		apiColumnName: '__duration__'
	},
	{
		displayName: 'Is CAC',
		category: 'Other',
		isHaving: false,
		columnType: 'number',
		apiColumnName: '__isCAC__'
	},
	{
		displayName: 'Season',
		category: 'Other',
		isHaving: false,
		columnType: 'number',
		apiColumnName: '__season__'
	},
	{
		displayName: 'Video Sub Type',
		category: 'Other',
		isHaving: false,
		columnType: 'string',
		apiColumnName: '__videoSubType__'
	},
	{
		displayName: 'Video Id',
		category: 'Other',
		isHaving: false,
		columnType: 'string',
		apiColumnName: '__VideoId__'
	},
	{
		displayName: 'Bucket',
		category: 'Other',
		isHaving: false,
		columnType: 'string',
		apiColumnName: '__bucket__'
	},
	{
		displayName: 'Category',
		category: 'Other',
		isHaving: false,
		columnType: 'string',
		apiColumnName: '__category__'
	},
	{
		displayName: 'Channel',
		category: 'Other',
		isHaving: false,
		columnType: 'string',
		apiColumnName: '__channel__'
	},
	{
		displayName: 'Content Cluster',
		category: 'Other',
		isHaving: false,
		columnType: 'string',
		apiColumnName: '__contentCluster__'
	},
	{
		displayName: 'Content Type',
		category: 'Other',
		isHaving: false,
		columnType: 'string',
		apiColumnName: '__contentType__'
	},

	{
		displayName: 'Date',
		category: 'Event',
		isHaving: false,
		columnType: 'date',
		apiColumnName: '__date__'
	},
	{
		displayName: 'Evergent Type',
		category: 'Other',
		isHaving: false,
		columnType: 'string',
		apiColumnName: '__evergentType__'
	},
	{
		displayName: 'Expiry Date',
		category: 'Other',
		isHaving: false,
		columnType: 'date',
		apiColumnName: '__expiryDate__'
	},
	{
		displayName: 'First Content Watch After',
		category: 'Other',
		isHaving: false,
		columnType: 'string',
		apiColumnName: '__FCWA__'
	},
	{
		displayName: 'Genre',
		category: 'Other',
		isHaving: false,
		columnType: 'string',
		apiColumnName: '__genre__'
	},
	{
		displayName: 'Is Free',
		category: 'Other',
		isHaving: false,
		columnType: 'boolean',
		apiColumnName: '__isFree__'
	},
	{
		displayName: 'Is Sub Active',
		category: 'Other',
		isHaving: false,
		columnType: 'boolean',
		apiColumnName: '__isSubActive__'
	},
	{
		displayName: 'Last Subscription Date',
		category: 'Other',
		isHaving: false,
		columnType: 'date',
		apiColumnName: '__lastSubscriptionDate__'
	},
	{
		displayName: 'Platform',
		category: 'Technographics',
		isHaving: false,
		columnType: 'string',
		apiColumnName: '__platform__'
	},
	{
		displayName: 'Preview Video View',
		category: 'Event',
		isHaving: false,
		columnType: 'number',
		apiColumnName: '__previewVideoView__'
	},
	{
		displayName: 'Preview Watch Time (in min)',
		category: 'Event',
		isHaving: false,
		columnType: 'number',
		apiColumnName: '__previewWatchtime__'
	},
	{
		displayName: 'Primary Genre',
		category: 'Other',
		isHaving: false,
		columnType: 'string',
		apiColumnName: '__primaryGenre__'
	},
	{
		displayName: 'Show Name',
		category: 'Other',
		isHaving: false,
		columnType: 'string',
		apiColumnName: '__showName__'
	},
	{
		displayName: 'Total Video Views',
		category: 'Event',
		isHaving: true,
		columnType: 'number',
		apiColumnName: '__totalVideoViews__'
	},
	{
		displayName: 'Total Watch Time (in min)',
		category: 'Event',
		isHaving: true,
		columnType: 'number',
		apiColumnName: '__totalWatchTime__'
	},
	{
		displayName: 'Video Language ',
		category: 'Other',
		isHaving: false,
		columnType: 'string',
		apiColumnName: '__videoLanguage__'
	},
	{
		displayName: 'Video Title',
		category: 'Other',
		isHaving: false,
		columnType: 'string',
		apiColumnName: '__videoTitle__'
	},
	{
		displayName: 'Video View',
		category: 'Event',
		isHaving: false,
		columnType: 'number',
		apiColumnName: '__videoView__'
	},
	{
		displayName: 'Watch Time (in min)',
		category: 'Event',
		isHaving: false,
		columnType: 'number',
		apiColumnName: '__watchtime__'
	}
];

// Conditional Mapping of conditions(operators) according to the datatype of parameters
const ConditionMapping: KeyStringArray = {
	number: [
		'equals',
		'not equals',
		'between',
		'greater than',
		'less than',
		'exists',
		'does not exist'
	],
	string: ['contains', 'does not contain', 'equals', 'not equals', 'exists', 'does not exist'],
	date: ['equals', 'not equals', 'between', 'greater than', 'less than', 'exists', 'does not exist'],
	boolean: ['equals', 'not equals', 'exists', 'does not exist']
};

// Conditional mapping of aggregator options on the basis of datatype of parameters
const HavingOptions = {
	number: ['sum', 'avg', 'count', 'min', 'max'],
	stringAndDate: ['count', 'min', 'max']
};

// List of categories of parameters
const categoryList = ['Technographics', 'Event', 'Other'];

export { MODAL_DATA, ParameterMapping, ConditionMapping, HavingOptions, categoryList };
