import React from 'react';

interface ButtonProps {
	className?: string;
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	disabled?: boolean;
	onMouseDown?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button: React.FC<ButtonProps> = ({
	className,
	children,
	disabled,
	onClick,
	onMouseDown
}): JSX.Element => {
	return (
		<React.Fragment>
			<button
				disabled={disabled}
				className={`button ${className || ''}`}
				onClick={onClick}
				onMouseDown={onMouseDown}
			>
				{children}
			</button>
		</React.Fragment>
	);
};

export default Button;
