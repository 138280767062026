import React from 'react';
import './style.css';

interface NavBarMenuProps {
	className?: string;
	id?: string;
}

const NavBarMenu: React.FC<NavBarMenuProps> = ({ className, id, children }): JSX.Element => {
	return (
		<div className={`navbar-menu responsive-nav-menu ${className || ''}`} id={id}>
			{children}
		</div>
	);
};

export default NavBarMenu;
