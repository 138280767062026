import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/atoms/Button';
import FormControl from 'components/atoms/FormControl';
import FormField from 'components/atoms/FormField';
import Hyperlink from 'components/atoms/Hyperlink';
import Input from 'components/atoms/Input';
import Label from 'components/atoms/Label';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'services';
import { Constants, Helpers } from 'utils';
import axios from 'axios';

axios.defaults.withCredentials = true;

interface ModalData {
	title: string;
	content: string;
	path: string;
}

interface LoginFormProps {
	className?: string;
	email: string;
	emailMessage: string;
	updateEmail?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	password: string;
	passwordMessage: string;
	updatePassword?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	togglePassword: (e: React.MouseEvent<HTMLButtonElement>) => void;
	setModalData: React.Dispatch<React.SetStateAction<ModalData>>;
	setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setEmailErrorMessage: React.Dispatch<React.SetStateAction<string>>;
	setPasswordErrorMessage: React.Dispatch<React.SetStateAction<string>>;
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
	passwordShown: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({
	email,
	emailMessage,
	updateEmail,
	password,
	updatePassword,
	passwordMessage,
	togglePassword,
	passwordShown,
	setModalData,
	setIsModalVisible,
	setEmailErrorMessage,
	setPasswordErrorMessage,
	setIsLoading
}): JSX.Element => {
	const navigate = useNavigate();
	const { validateEmail, validateLoginPassword } = Helpers;
	const { userLocked, invalidCredentials, unknownError } = Constants.MODAL_DATA;

	const onSubmit = async () => {
		//If password and email for logging in are according to the required format
		if (validateEmail(email) && validateLoginPassword(password)) {
			setIsLoading(true);
			const response = await Auth.loginAuth(email, password);

			if (response.status === 200) {
				const metadata = response.data.metadata;
				localStorage.setItem('metadata :', metadata);
				navigate('/');
			} else if (response.status === 401) {
				//If the user enter incorrect password for 5 times, the user gets locked and cannot login into the system
				if (response.data.error.message.includes('Locked')) {
					setModalData(userLocked);
				}
				//If either email or password or both are invalid
				else {
					setModalData(invalidCredentials);
				}
				setIsLoading(false);
				setIsModalVisible(true);
			} else {
				setModalData(unknownError);
				setIsLoading(false);
				setIsModalVisible(true);
			}
		}

		//If email is not according to the required format
		if (!Helpers.validateEmail(email)) {
			if (email === '') {
				setEmailErrorMessage('Email cannot be blank');
			} else {
				setEmailErrorMessage('Invalid email format');
			}
		}

		//If password is not according to the requirement
		if (password === '') {
			setPasswordErrorMessage('Password cannot be blank');
		}
	};

	return (
		<React.Fragment>
			<FormField className='mb-6'>
				<Label className='has-text-grey-dark ml-1 is-small'>Email</Label>
				<FormControl className='input-container'>
					<Input
						className={emailMessage === '' ? '' : 'is-danger'}
						type='text'
						placeholder='Email'
						value={email}
						onChange={updateEmail}
						onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
					/>
				</FormControl>
				<p className={'help has-text-left is-danger'}>{emailMessage}</p>
			</FormField>
			<FormField className='mb-6'>
				<Label className='has-text-grey-dark ml-1 is-small'>Password</Label>
				<FormControl className='has-icons-right'>
					<Input
						className={passwordMessage === '' ? '' : 'is-danger'}
						type={passwordShown ? 'text' : 'password'}
						placeholder='Password'
						value={password}
						onChange={updatePassword}
						onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
					/>
					<p className={'help has-text-left is-danger'}>{passwordMessage}</p>
					<span
						className='icon is-small is-clickable is-right mr-1 has-text-black pr-3 hover:has-background-primary'
						onClick={togglePassword}
					>
						{passwordShown ? (
							<FontAwesomeIcon icon={['fas', 'eye-slash']} style={{ color: '#d4d4d4' }} />
						) : (
							<FontAwesomeIcon icon={['fas', 'eye']} style={{ color: '#d4d4d4' }} />
						)}
					</span>
				</FormControl>
			</FormField>
			<div className='mt-4 mb-6 is-flex is-justify-content-space-between is-align-items-center'>
				<FormField className='is-grouped is-flex-direction-row is-justify-content-center'>
					<FormControl>
						<Hyperlink className='has-text-grey-dark is-underlined' to='/auth/forgotpassword'>
							Forgot Password?
						</Hyperlink>
					</FormControl>
				</FormField>

				<FormField>
					<FormControl>
						<Button className='is-link has-background-grey-dark mb-3' onClick={onSubmit}>
							Login
						</Button>
					</FormControl>
				</FormField>
			</div>
		</React.Fragment>
	);
};

export default LoginForm;
