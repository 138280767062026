import React from 'react';
import { RuleType } from 'interfaces/interface';
import './styles.css';

interface RuleTagProps {
	isDeletable: boolean;
	rule: RuleType;
	handleDragStart: React.DragEventHandler<HTMLDivElement>;
	handleDragEnter: React.DragEventHandler<HTMLDivElement>;
	className?: string;
	deleteTag?: () => void;
	view: boolean;
}

const RuleTag: React.FC<RuleTagProps> = ({
	isDeletable,
	rule,
	handleDragStart,
	className,
	handleDragEnter,
	deleteTag,
	view
}) => {
	document.addEventListener('dragover', function (e) {
		e.preventDefault();
	});
	return (
		<div
			draggable={!view}
			className={`tag m-2 p-4 is-clickable is-size-6 ${className ? className : ''} ${
				!rule.name && 'empty-tag'
			}`}
			onDragStart={handleDragStart}
			onDragEnter={handleDragEnter}
		>
			{rule.name ? rule.name : 'Drag...'}
			{isDeletable && rule.name && <button className='delete is-small' onClick={deleteTag} />}
		</div>
	);
};
export default RuleTag;
