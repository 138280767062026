import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './style.css';

interface ToolTipProps {
	className?: string;
	direction?: string;
}

const ToolTip: React.FC<ToolTipProps> = ({ className, children, direction }): JSX.Element => {
	return (
		<React.Fragment>
			<span className={className}>
				<FontAwesomeIcon className='tooltip-icon ml-2' icon={['fas', 'question-circle']} />
				<span className={`tooltip-content help ml-6 ${direction || 'top'}`}>{children}</span>
			</span>
		</React.Fragment>
	);
};

export default ToolTip;
