import { RuleType, ApiRuleGroups, subRuleType } from 'interfaces/interface';
import { Constants } from 'utils';

const validateEmail = (email: string): boolean => {
	const regex = /^(?!.*?[._]{2})([\w\.-]*[\w]+)@([\w-]+)\.([\w]{2,8})(\.[\w]{2,8})*?$/; // eslint-disable-line
	return regex.test(email);
};

const validatePassword = (password: string): boolean => {
	const regex = /^(?=.*?[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,30}$/;
	return regex.test(password);
};

const validateLoginPassword = (password: string): boolean => {
	if (password.length > 0) return true;
	else return false;
};

const timeFormatHandler = (time: string): string => {
	if (time === undefined) return 'NA';
	const indexOfDot = time.indexOf('.');
	time = time.slice(0, indexOfDot);
	time = time.replace('T', ' ');
	time = time.replaceAll('-', '/');
	return time;
};

const validateName = (name: string): boolean => {
	return name.trim().length > 0;
};

const validateRole = (role: string): boolean => {
	return role === 'ADMIN' || role === 'CREATOR';
};

const createRuleGroupPayload = (ruleList: RuleType[]) => {
	const ruleListPayload = [];
	for (let i = 0; i < ruleList.length; i++) {
		let rulePayload: {
			ruleName: string;
			condition: string | null;
			aggregator: string | null | undefined;
			aggregatorField: string | null;
			aggregatorCondition: string | null;
		} = {
			ruleName: ruleList[i].name,
			condition: '',
			aggregator: null,
			aggregatorField: null,
			aggregatorCondition: null
		};

		let conditionString: string | null = '';

		// transforming rule list into api format for sql query creation
		if (ruleList[i].type === 'simple') {
			// for simple rules
			const rule = ruleList[i].subRules[0];
			if (rule.operator === 'contains') {
				// ui parameter name, contains, ['ABC', 'XYZ'] ----> __api_parameter_name__ IN ('ABC', 'XYZ')
				conditionString = `${rule.apiColumnName} IN (`;
				for (let j = 0; j < rule.value.length; j++) {
					conditionString += `'${rule.value[j]}'${j < rule.value.length - 1 ? ',' : ''}`;
				}
				conditionString += ')';
			} else if (rule.operator === 'does not contain') {
				// ui parameter name, does not contain, ['ABC', 'XYZ'] ----> __api_parameter_name__ NOT IN ('ABC', 'XYZ')
				conditionString = `${rule.apiColumnName} NOT IN (`;
				for (let j = 0; j < rule.value.length; j++) {
					conditionString += `'${rule.value[j]}'${j < rule.value.length - 1 ? ',' : ''}`;
				}
				conditionString += ')';
			} else if (rule.operator === 'between') {
				// ui parameter name, between, min, max ----> __api_parameter_name__ BETWEEN min AND max
				if (rule.columnType === 'string' || rule.columnType === 'date') {
					conditionString = `${rule.apiColumnName} BETWEEN '${rule.min}' AND '${rule.max}'`;
				} else {
					conditionString = `${rule.apiColumnName} BETWEEN ${rule.min} AND ${rule.max}`;
				}
			} else if (rule.operator === 'greater than') {
				// ui parameter name, greater than, value -----> __api_parameter_name__ > value
				if (rule.columnType === 'string' || rule.columnType === 'date') {
					conditionString = `${rule.apiColumnName} > '${rule.value[0]}'`;
				} else {
					conditionString = `${rule.apiColumnName} > ${rule.value[0]}`;
				}
			} else if (rule.operator === 'less than') {
				// ui parameter name, less than, value -----> __api_parameter_name__ < value
				if (rule.columnType === 'string' || rule.columnType === 'date') {
					conditionString = `${rule.apiColumnName} < '${rule.value[0]}'`;
				} else {
					conditionString = `${rule.apiColumnName} < ${rule.value[0]}`;
				}
			} else if (rule.operator === 'equals') {
				// ui parameter name, equals, value -----> __api_parameter_name__ = value
				if (rule.columnType === 'string' || rule.columnType === 'date') {
					conditionString = `${rule.apiColumnName} = '${rule.value[0]}'`;
				} else {
					conditionString = `${rule.apiColumnName} = ${rule.value[0]}`;
				}
			} else if (rule.operator === 'not equals') {
				// ui parameter name, not equals, value -----> __api_parameter_name__ != value
				if (rule.columnType === 'string' || rule.columnType === 'date') {
					conditionString = `${rule.apiColumnName} != '${rule.value[0]}'`;
				} else {
					conditionString = `${rule.apiColumnName} != ${rule.value[0]}`;
				}
			} else if (rule.operator === 'exists') {
				// ui parameter name, exists -----> __api_parameter_name__ IS NOT null
				conditionString = `${rule.apiColumnName} IS NOT null`;
			} else if (rule.operator === 'does not exist') {
				// ui parameter name, does not exist -----> __api_parameter_name__ IS null
				conditionString = `${rule.apiColumnName} IS null`;
			}
		} else if (ruleList[i].type === 'compound') {
			// for compound rules
			for (let k = 0; k < ruleList[i].subRules.length; k++) {
				const rule = ruleList[i].subRules[k];
				if (rule.having === '') {
					// if not a having rule
					if (rule.operator === 'contains') {
						// ui parameter name, contains, ['ABC', 'XYZ'] ----> __api_parameter_name__ IN ('ABC', 'XYZ')
						conditionString += `${rule.apiColumnName} IN (`;
						for (let j = 0; j < rule.value.length; j++) {
							conditionString += `'${rule.value[j]}'${j < rule.value.length - 1 ? ',' : ''}`;
						}
						conditionString += `) ${rule.condition !== null ? rule.condition : ''} `;
						// add condition at the end if it exists
					} else if (rule.operator === 'does not contain') {
						// ui parameter name, does not contain, ['ABC', 'XYZ'] ----> __api_parameter_name__ NOT IN ('ABC', 'XYZ')
						conditionString += `${rule.apiColumnName} NOT IN (`;
						for (let j = 0; j < rule.value.length; j++) {
							conditionString += `'${rule.value[j]}'${j < rule.value.length - 1 ? ',' : ''}`;
						}
						conditionString += `) ${rule.condition !== null ? rule.condition : ''} `;
						// add condition at the end if it exists
					} else if (rule.operator === 'between') {
						// ui parameter name, between, min, max ----> __api_parameter_name__ BETWEEN min AND max
						if (rule.columnType === 'string' || rule.columnType === 'date') {
							conditionString += `${rule.apiColumnName} BETWEEN '${rule.min}' AND '${rule.max}' ${
								rule.condition !== null ? rule.condition : ''
								// add condition at the end if it exists
							} `;
						} else {
							conditionString += `${rule.apiColumnName} BETWEEN ${rule.min} AND ${rule.max} ${
								rule.condition !== null ? rule.condition : ''
								// add condition at the end if it exists
							} `;
						}
					} else if (rule.operator === 'greater than') {
						// ui parameter name, greater than, value -----> __api_parameter_name__ > value
						if (rule.columnType === 'string' || rule.columnType === 'date') {
							conditionString += `${rule.apiColumnName} > '${rule.value[0]}' ${
								rule.condition !== null ? rule.condition : ''
								// add condition at the end if it exists
							} `;
						} else {
							conditionString += `${rule.apiColumnName} > ${rule.value[0]} ${
								rule.condition !== null ? rule.condition : ''
								// add condition at the end if it exists
							} `;
						}
					} else if (rule.operator === 'less than') {
						// ui parameter name, less than, value -----> __api_parameter_name__ < value
						if (rule.columnType === 'string' || rule.columnType === 'date') {
							conditionString += `${rule.apiColumnName} < '${rule.value[0]}' ${
								rule.condition !== null ? rule.condition : ''
								// add condition at the end if it exists
							} `;
						} else {
							conditionString += `${rule.apiColumnName} < ${rule.value[0]} ${
								rule.condition !== null ? rule.condition : ''
								// add condition at the end if it exists
							} `;
						}
					} else if (rule.operator === 'equals') {
						// ui parameter name, equals, value -----> __api_parameter_name__ = value
						if (rule.columnType === 'string' || rule.columnType === 'date') {
							conditionString += `${rule.apiColumnName} = '${rule.value[0]}' ${
								rule.condition !== null ? rule.condition : ''
								// add condition at the end if it exists
							} `;
						} else {
							conditionString += `${rule.apiColumnName} = ${rule.value[0]} ${
								rule.condition !== null ? rule.condition : ''
								// add condition at the end if it exists
							} `;
						}
					} else if (rule.operator === 'not equals') {
						// ui parameter name, not equals, value -----> __api_parameter_name__ != value
						if (rule.columnType === 'string' || rule.columnType === 'date') {
							conditionString += `${rule.apiColumnName} != '${rule.value[0]}' ${
								rule.condition !== null ? rule.condition : ''
								// add condition at the end if it exists
							} `;
						} else {
							conditionString += `${rule.apiColumnName} != ${rule.value[0]} ${
								rule.condition !== null ? rule.condition : ''
								// add condition at the end if it exists
							} `;
						}
					} else if (rule.operator === 'exists') {
						// ui parameter name, exists -----> __api_parameter_name__ IS NOT null
						conditionString += `${rule.apiColumnName} IS NOT null ${
							rule.condition !== null ? rule.condition : ''
							// add condition at the end if it exists
						} `;
					} else if (rule.operator === 'does not exist') {
						// ui parameter name, does not exist -----> __api_parameter_name__ IS null
						conditionString += `${rule.apiColumnName} IS null ${
							rule.condition !== null ? rule.condition : ''
							// add condition at the end if it exists
						} `;
					}
				} else {
					// if its a having rule do the same as above but not populate the aggregatorCondition without the api column name
					let aggregatorConditionString = '';
					if (rule.operator === 'contains') {
						aggregatorConditionString = 'IN (';
						for (let j = 0; j < rule.value.length; j++) {
							aggregatorConditionString += `'${rule.value[j]}'${j < rule.value.length - 1 ? ',' : ''}`;
						}
						aggregatorConditionString += ')';
					} else if (rule.operator === 'does not contain') {
						aggregatorConditionString = 'NOT IN (';
						for (let j = 0; j < rule.value.length; j++) {
							aggregatorConditionString += `'${rule.value[j]}'${j < rule.value.length - 1 ? ',' : ''}`;
						}
						aggregatorConditionString += ')';
					} else if (rule.operator === 'between') {
						if (rule.columnType === 'string' || rule.columnType === 'date') {
							aggregatorConditionString = `BETWEEN '${rule.min}' AND '${rule.max}'`;
						} else {
							aggregatorConditionString = `BETWEEN ${rule.min} AND ${rule.max}`;
						}
					} else if (rule.operator === 'greater than') {
						if (rule.columnType === 'string' || rule.columnType === 'date') {
							aggregatorConditionString = `> '${rule.value[0]}'`;
						} else {
							aggregatorConditionString = `> ${rule.value[0]}`;
						}
					} else if (rule.operator === 'less than') {
						if (rule.columnType === 'string' || rule.columnType === 'date') {
							aggregatorConditionString = `< '${rule.value[0]}'`;
						} else {
							aggregatorConditionString = `< ${rule.value[0]}`;
						}
					} else if (rule.operator === 'equals') {
						if (rule.columnType === 'string' || rule.columnType === 'date') {
							aggregatorConditionString = `= '${rule.value[0]}'`;
						} else {
							aggregatorConditionString = `= ${rule.value[0]}`;
						}
					} else if (rule.operator === 'not equals') {
						if (rule.columnType === 'string' || rule.columnType === 'date') {
							aggregatorConditionString = `!= '${rule.value[0]}'`;
						} else {
							aggregatorConditionString = `!= ${rule.value[0]}`;
						}
					} else if (rule.operator === 'exists') {
						aggregatorConditionString = 'IS NOT null';
					} else if (rule.operator === 'does not exist') {
						aggregatorConditionString = 'IS null';
					}
					rulePayload = {
						...rulePayload,
						aggregator: rule.having?.toUpperCase(), // set having aggregator
						aggregatorField: rule.apiColumnName, // add the api column name
						aggregatorCondition: aggregatorConditionString
					};
				}
			}
		}
		rulePayload = { ...rulePayload, condition: conditionString !== '' ? conditionString : null };
		ruleListPayload.push(rulePayload);
	}
	return ruleListPayload;
};

const createRuleGroupState = (apiRuleGroups: ApiRuleGroups[]) => {
	// transforming api format to rule groups
	const arr: RuleType[] = [];
	const blankSubRule = {
		apiColumnName: '',
		columnType: '',
		condition: '',
		max: '',
		min: '',
		operator: '',
		parameter: '',
		value: ['']
	};
	for (let i = 0; i < apiRuleGroups.length; i++) {
		let rule = apiRuleGroups[i];
		let ruleListItem: RuleType = {
			name: rule.ruleName ? rule.ruleName : '',
			type: '',
			subRules: [],
			isHaving: rule.aggregator !== null,
			condition: null
		};

		let currentWord = '';
		let betweenFlag = false;
		let conditionString = rule.condition;
		if (conditionString !== null) {
			for (let i = 0; i < conditionString.length; i++) {
				// iterate through the conditionString and stop at a space and read the last word
				if (conditionString[i] === ' ') {
					if (currentWord === 'BETWEEN') {
						// if the last word is BETWEEN set the flag to true
						betweenFlag = true;
					}
					if (currentWord === 'AND' && betweenFlag) {
						// read for 'AND' if the betweenFlag is true
						conditionString =
							conditionString.slice(0, i) + '//UNIQUE//STRING//BREAKER//' + conditionString.slice(i);
						betweenFlag = false;
						// modify the the string with a unique string at every AND
					}
					currentWord = '';
				} else {
					currentWord += conditionString[i];
				}
			}
			// replace all occurences of AND inside the between conditions with 'and'
			rule = {
				...rule,
				condition: conditionString.replaceAll('AND//UNIQUE//STRING//BREAKER//', 'and')
			};
		}
		if (
			!rule.condition?.includes(' OR ') &&
			!rule.condition?.includes(' AND ') &&
			rule.condition !== null
		) {
			const subRule = createSubRule(rule.condition);
			ruleListItem.subRules.push(subRule);
			if (rule.aggregator === null) {
				ruleListItem = { ...ruleListItem, type: 'simple' };
			} else {
				ruleListItem = { ...ruleListItem, type: 'compound' };
			}
			arr.push(ruleListItem);
		}
		if (rule.aggregator !== null) {
			const subRule = createHavingRule(rule);
			ruleListItem.subRules.push(subRule);
			if (!rule.condition?.includes(' OR ') && !rule.condition?.includes(' AND ')) {
				ruleListItem.subRules.reverse();
			}
			ruleListItem = { ...ruleListItem, type: 'compound' };
			if (rule.condition === null) {
				arr.push(ruleListItem);
			}
		}
		if (rule.condition?.includes(' OR ') || rule.condition?.includes(' AND ')) {
			// if its a compound condition replace 'AND' with a unique string and split the condition at the string
			let conditionString = rule.condition.trim();
			let currentWord = '';
			for (let i = 0; i < conditionString.length; i++) {
				if (conditionString[i] === ' ') {
					if (currentWord === 'OR' || currentWord === 'AND') {
						conditionString =
							conditionString.slice(0, i) + '//UNIQUE//STRING//BREAKER//' + conditionString.slice(i + 1);
					}
					currentWord = '';
				} else {
					currentWord += conditionString[i];
				}
			}
			const conditionsArray = conditionString.split('//UNIQUE//STRING//BREAKER//'); // splitting the condition

			if (rule.aggregator === null) {
				ruleListItem.subRules.push(blankSubRule);
			}
			for (let i = 0; i < conditionsArray.length; i++) {
				// iteration through the split and creating subRules
				if (!conditionsArray[i].includes('OR') && !conditionsArray[i].includes('AND')) {
					const subRule = createSubRule(conditionsArray[i]);
					ruleListItem.subRules.push(subRule);
				} else {
					if (conditionsArray[i].includes('OR')) {
						const ruleString = conditionsArray[i].substring(0, conditionsArray[i].lastIndexOf(' '));
						const subRule = createSubRule(ruleString, 'OR');
						ruleListItem.subRules.push(subRule);
					} else if (conditionsArray[i].includes('AND')) {
						const ruleString = conditionsArray[i].substring(0, conditionsArray[i].lastIndexOf(' '));
						const subRule = createSubRule(ruleString, 'AND');
						ruleListItem.subRules.push(subRule);
					}
				}
			}
			ruleListItem = { ...ruleListItem, type: 'compound' };

			arr.push(ruleListItem);
		}
	}
	return arr;
};

const createHavingRule = (rule: ApiRuleGroups) => {
	const splitArr = rule.aggregatorCondition ? rule.aggregatorCondition.split(' ') : [];

	const aggregatorConditionStringArr = splitArr.splice(0, 1);

	aggregatorConditionStringArr.push(
		splitArr.join(' ').replaceAll(/'/g, '').replaceAll('(', '').replaceAll(')', '')
	);
	const index = Constants.ParameterMapping.findIndex(
		(mapping) => mapping.apiColumnName === rule.aggregatorField
	);

	// subRule skeleton
	let subRule: subRuleType = {
		apiColumnName: rule.aggregatorField ? rule.aggregatorField : '',
		parameter: Constants.ParameterMapping[index].displayName,
		columnType: Constants.ParameterMapping[index].columnType,
		operator: '',
		condition: null,
		having: rule.aggregator?.toLowerCase(),
		value: [],
		min: '',
		max: ''
	};

	// set operator and values
	if (aggregatorConditionStringArr[0] === '=') {
		subRule = { ...subRule, operator: 'equals' };
		subRule.value.push(aggregatorConditionStringArr[1]);
	} else if (aggregatorConditionStringArr[0] === '!=') {
		subRule = { ...subRule, operator: 'not equals' };
		subRule.value.push(aggregatorConditionStringArr[1]);
	} else if (aggregatorConditionStringArr[0] === '>') {
		subRule = { ...subRule, operator: 'greater than' };
		subRule.value.push(aggregatorConditionStringArr[1]);
	} else if (aggregatorConditionStringArr[0] === '<') {
		subRule = { ...subRule, operator: 'less than' };
		subRule.value.push(aggregatorConditionStringArr[1]);
	} else if (aggregatorConditionStringArr[0] === 'IN') {
		subRule = { ...subRule, operator: 'contains' };
		const values = aggregatorConditionStringArr[1].split(',');
		values.forEach((value: string) => {
			subRule.value.push(value);
		});
	} else if (aggregatorConditionStringArr[0] === 'NOT') {
		subRule = { ...subRule, operator: 'does not contain' };
		const values = aggregatorConditionStringArr[1].split(',');
		values.forEach((value: string) => {
			subRule.value.push(value);
		});
	} else if (aggregatorConditionStringArr[0] === 'IS') {
		if (aggregatorConditionStringArr[1] === 'null') {
			subRule = { ...subRule, operator: 'does not exist' };
		} else {
			subRule = { ...subRule, operator: 'exists' };
		}
	} else if (aggregatorConditionStringArr[0] === 'BETWEEN') {
		const values = aggregatorConditionStringArr[1].split(' ');

		subRule = { ...subRule, operator: 'between', min: values[0], max: values[2] };
	}
	return subRule;
};

const createSubRule = (ruleString: string, condition?: string | null) => {
	// split and format condition string
	const splitArr = ruleString.trim().split(' ');

	const ruleStringArr =
		splitArr.includes('NOT') && splitArr.includes('IN')
			? splitArr.splice(0, 3)
			: splitArr.splice(0, 2);
	ruleStringArr.push(
		splitArr.join(' ').replaceAll(/'/g, '').replaceAll('(', '').replaceAll(')', '')
	);
	const index = Constants.ParameterMapping.findIndex(
		(mapping) => mapping.apiColumnName === ruleStringArr[0]
	);

	// subRule skeleton
	let subRule: subRuleType = {
		apiColumnName: ruleStringArr[0],
		parameter: Constants.ParameterMapping[index].displayName,
		columnType: Constants.ParameterMapping[index].columnType,
		operator: '',
		condition: condition || null,
		having: '',
		value: [],
		min: '',
		max: ''
	};

	// set operator
	if (ruleStringArr[1] === '=') {
		subRule = { ...subRule, operator: 'equals' };

		subRule.value.push(ruleStringArr[2]);
	} else if (ruleStringArr[1] === '!=') {
		subRule = { ...subRule, operator: 'not equals' };

		subRule.value.push(ruleStringArr[2]);
	} else if (ruleStringArr[1] === '>') {
		subRule = { ...subRule, operator: 'greater than' };

		subRule.value.push(ruleStringArr[2]);
	} else if (ruleStringArr[1] === '<') {
		subRule = { ...subRule, operator: 'less than' };
		subRule.value.push(ruleStringArr[2]);
	} else if (ruleStringArr[1] === 'IN') {
		subRule = { ...subRule, operator: 'contains' };
		const values = ruleStringArr[2].split(',');

		values.forEach((value) => {
			subRule.value.push(value);
		});
	} else if (ruleStringArr[1] === 'NOT' && ruleStringArr[2] === 'IN') {
		subRule = { ...subRule, operator: 'does not contain' };
		const values = ruleStringArr[3].split(',');

		values.forEach((value) => {
			subRule.value.push(value);
		});
	} else if (ruleStringArr[1] === 'BETWEEN') {
		const values = ruleStringArr[2].split(' ');

		subRule = { ...subRule, operator: 'between', min: values[0], max: values[2] };
	} else if (ruleStringArr[1] === 'IS') {
		if (ruleStringArr[2] === 'null') {
			subRule = { ...subRule, operator: 'does not exist' };
		} else {
			subRule = { ...subRule, operator: 'exists' };
		}
	}

	return subRule;
};

const createWorkAreaRules = (apiRuleList: RuleType[], query: string) => {
	if (query.trim() !== '') {
		const queryStringGroups = createGroups(query.trim()); // get group array from query
		const queryRuleGroups = [];
		for (let i = 0; i < queryStringGroups.length; i++) {
			const rules = [];
			for (let j = 0; j < queryStringGroups[i].rules.length; j++) {
				// iterate through query groups and populate the ruleGroups in the same format by matching the ruleName
				if (queryStringGroups[i].rules[j] !== 'OR' && queryStringGroups[i].rules[j] !== 'AND') {
					const rule = apiRuleList.filter(
						(rule: RuleType) => rule.name === queryStringGroups[i].rules[j]
					);

					if (j < queryStringGroups[i].rules.length - 1) {
						const copyRule = { ...rule[0], condition: queryStringGroups[i].rules[j + 1] };
						rules.push(copyRule);
					} else {
						const copyRule = { ...rule[0], condition: null };
						rules.push(copyRule);
					}
				}
			}
			queryRuleGroups.push({ rules: rules, condition: queryStringGroups[i].condition });
		}
		return queryRuleGroups;
	} else {
		return [
			{
				rules: [],
				condition: null
			}
		];
	}
};

const createGroups = (query: string) => {
	// convert query into ruleGroup array with 2 dimentions
	query = query.substring(1, query.length - 1).trim();

	const groups: Array<{ rules: Array<string>; condition: string | null }> = [];
	let readGroup = false;

	let groupString = '';
	let conditionString = '';
	for (let i = 0; i < query.length; i++) {
		if (query[i] === '(') {
			// read a group whenever a bracket starts
			readGroup = true;
			i++;
			if (i > 1) {
				groups[groups.length - 1].condition = conditionString.trim();
				conditionString = '';
			}
		}
		if (query[i] === ')') {
			// stop reading a group when bracket ends
			groups.push({ rules: groupString.trim().split(' '), condition: null });
			readGroup = false;
			groupString = '';
			i++;
		}
		if (readGroup === true) {
			groupString += query[i];
		}
		if (readGroup === false) {
			conditionString += query[i];
		}
	}
	return groups;
};
const getLocalTime = (time: string): string => {
	if (time === undefined || time === null) return 'NA';
	const datetemp = new Date(time);
	return getFormattedDate(datetemp) + ' ' + getFormattedTime(datetemp);
};

const getFormattedTime = (date: Date): string => {
	const h = date.getHours() < 9 ? '0' + date.getHours() : date.getHours();
	const m = date.getMinutes();
	const s = date.getSeconds();
	const time = h + ':' + m + ':' + s;
	return time;
};
const getFormattedDate = (date: Date) => {
	const month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
	const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
	const year = date.getFullYear();
	return year + '-' + month + '-' + day;
};

const validateQuery = (query: string) => {
	const regex =
		/^((?!(\s+|^)(insert\s+into(\s+|$)|delete\s+from(\s+|$)|update(\s+|$)|drop\s+table(\s+|$)|alter\stable(\s+|$)))[\s\S])*$/i;
	return regex.test(query);
};

const shouldHaveQuery = (query: string) => {
	const regex = /^((?!(\s+|^)(select\s+([\S]+|(\S+\s+\S+))+\s+from(\s+|$)))[\s\S])*$/i;
	return !regex.test(query);
};

const durationDropdownValueFormatter = (
	timeFrameFrequency: number,
	timeFrameUnit: string
): string => {
	let durationValue = '';
	if (timeFrameFrequency !== null && timeFrameFrequency !== 0 && timeFrameFrequency !== undefined) {
		durationValue += `In the last ${timeFrameFrequency} ${timeFrameUnit}, `;
	}
	return durationValue;
};

const offsetDropdownValueFormatter = (timeFrameFrequency: number, offset: number): string => {
	let offsetValue = '';
	if (offset !== null && offset !== 0 && timeFrameFrequency !== undefined) {
		offsetValue += `for ${offset} day(s) ago, `;
	}
	return offsetValue;
};

export {
	validateEmail,
	validatePassword,
	validateLoginPassword,
	validateName,
	validateRole,
	timeFormatHandler,
	createRuleGroupPayload,
	getFormattedDate,
	createRuleGroupState,
	validateQuery,
	shouldHaveQuery,
	durationDropdownValueFormatter,
	offsetDropdownValueFormatter,
	createWorkAreaRules,
	getLocalTime,
	getFormattedTime
};
