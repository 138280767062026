import React from 'react';
import { Link } from 'react-router-dom';

interface HyperlinkProps {
	className?: string;
	to?: string;
}

const Hyperlink: React.FC<HyperlinkProps> = ({ className, children, to }): JSX.Element => {
	return (
		<React.Fragment>
			<Link className={className || ''} to={to || ''}>
				{children}
			</Link>
		</React.Fragment>
	);
};

export default Hyperlink;
