import React from 'react';
import Tabs from 'components/atoms/Tabs';
import Input from 'components/atoms/Input';
import './style.css';

interface DatePickerCalendarMenuProps {
	activeTab: string;
	setActiveTab: React.Dispatch<React.SetStateAction<string>>;
	fromValue: string;
	setFromValue: React.Dispatch<React.SetStateAction<string>>;
	toValue: string;
	setToValue: React.Dispatch<React.SetStateAction<string>>;
	isRangeValid: boolean;
	beforeValue: string;
	setBeforeValue: React.Dispatch<React.SetStateAction<string>>;
	afterValue: string;
	setAfterValue: React.Dispatch<React.SetStateAction<string>>;
	onValue: string;
	setOnValue: React.Dispatch<React.SetStateAction<string>>;
}

const DatePickerCalendarMenu: React.FC<DatePickerCalendarMenuProps> = ({
	activeTab,
	setActiveTab,
	fromValue,
	setFromValue,
	toValue,
	setToValue,
	isRangeValid,
	beforeValue,
	setBeforeValue,
	afterValue,
	setAfterValue,
	onValue,
	setOnValue
}) => {
	const calendarMenuTabs = [
		{ name: 'BETWEEN', displayName: 'Between' },
		{ name: 'BEFORE', displayName: 'Before' },
		{ name: 'AFTER', displayName: 'After' },
		{ name: 'ON', displayName: 'On' }
	];

	return (
		<React.Fragment>
			<Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={calendarMenuTabs} />
			<div className='pb-2 px-5'>
				{activeTab === 'BETWEEN' && (
					<div className='dropdown is-active is-flex is-justify-content-center'>
						<div>
							<div className='is-flex is-align-items-center'>
								<div className='mr-2'>From</div>
								<Input
									type='date'
									className=''
									onChange={(e) => {
										setFromValue(e.target.value);
									}}
									value={fromValue}
								/>
							</div>
							<div className='is-flex is-align-items-center mt-3'>
								<div className='mr-5'>To</div>
								<Input
									type='date'
									onChange={(e) => {
										setToValue(e.target.value);
									}}
									value={toValue}
								/>
							</div>
							{!isRangeValid && (
								<div className='is-flex is-align-items-center is-justify-content-center'>
									<p className='help is-danger mt-4'>&#39;To&#39; cannot be less than &#39;From&#39;</p>
								</div>
							)}
						</div>
					</div>
				)}
				{activeTab === 'BEFORE' && (
					<div className='dropdown is-active is-flex is-justify-content-center'>
						<div className='date-picker'>
							<Input
								type='date'
								onChange={(e) => {
									setBeforeValue(e.target.value);
								}}
								value={beforeValue}
							/>
						</div>
					</div>
				)}
				{activeTab === 'AFTER' && (
					<div className='dropdown is-active is-flex is-justify-content-center'>
						<div className='date-picker'>
							<Input
								type='date'
								onChange={(e) => {
									setAfterValue(e.target.value);
								}}
								value={afterValue}
							/>
						</div>
					</div>
				)}
				{activeTab === 'ON' && (
					<div className='dropdown is-active is-flex is-justify-content-center'>
						<div className='date-picker'>
							<Input type='date' onChange={(e) => setOnValue(e.target.value)} value={onValue} />
						</div>
					</div>
				)}
			</div>
		</React.Fragment>
	);
};

export default DatePickerCalendarMenu;
