import React from 'react';

interface DropdownItemProps {
	className?: string;
	onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
	onMouseDown?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const DropdownItem: React.FC<DropdownItemProps> = ({
	className,
	children,
	onClick,
	onMouseDown
}): JSX.Element => {
	return (
		<a className={`dropdown-item ${className || ''}`} onClick={onClick} onMouseDown={onMouseDown}>
			{children}
		</a>
	);
};

export default DropdownItem;
