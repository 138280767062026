import React, { useEffect, useState } from 'react';
import { CategorizeParameterType } from 'interfaces/interface';
import './style.css';
import DropdownItem from 'components/atoms/DropdownItem';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
interface TypeAheadStaticCategorizedProps {
	label: string;
	value: string;
	placeholder: string;
	data: Array<string>;
	categorizeParameterList: Array<CategorizeParameterType>;
	setSelected: React.Dispatch<React.SetStateAction<string>>;
	view: boolean;
}

const TypeAheadStaticCategorized: React.FC<TypeAheadStaticCategorizedProps> = ({
	label,
	value,
	placeholder,
	data,
	setSelected,
	categorizeParameterList,
	view
}) => {
	const [matches, setMatches] = useState<Array<string>>([]);
	const [input, setInput] = useState(value);
	const [currentValidParameter, setCurrentValidParameter] = useState<string>(value);
	const [isBlur, setIsBlur] = useState(true);
	const [categoryClick, setCategoryClick] = useState(false);

	useEffect(() => {
		setInput(value);
	}, [value]);

	const itemClickHandler = (item: string) => {
		setInput(item);
		setSelected(item);
		setCurrentValidParameter(item);
		setMatches([]);
	};
	const updateInput = (inputValue: string) => {
		setMatches(
			inputValue.length >= 1
				? data.filter((item) => item.toUpperCase().indexOf(inputValue.toUpperCase()) >= 0)
				: []
		);
		setInput(inputValue);
	};
	const onFocusHandler = () => {
		setIsBlur(false);
	};
	const onBlurHandler = () => {
		if (categoryClick === false) {
			setInput(currentValidParameter);
			setIsBlur(true);
		}
		setCategoryClick(false);
	};
	const categoryClickHandler = () => {
		setCategoryClick(true);
	};
	return (
		<div className='field'>
			{label && <Label>{label}</Label>}
			<div className='control'>
				<div className={`dropdown ${isBlur === false ? 'is-active' : ''}`}>
					<div className='dropdown-trigger'>
						<Input
							type='text'
							value={input}
							onFocus={onFocusHandler}
							onBlur={onBlurHandler}
							onChange={(e) => updateInput(e.target.value)}
							placeholder={placeholder}
							autoComplete='off'
							disabled={view}
						/>
					</div>
					{input.length === 0 && (
						<div className='dropdown-menu dropdown-menu-scrollable helo'>
							<div className='dropdown-content'>
								{categorizeParameterList.map((data, index) => {
									return (
										<React.Fragment key={index}>
											<DropdownItem
												className='has-background-grey-lighter'
												key={index}
												onMouseDown={categoryClickHandler}
											>
												{data.category}
											</DropdownItem>
											{data.parameters.map((parameter, index2) => {
												return (
													<DropdownItem key={index2} onMouseDown={() => itemClickHandler(parameter)}>
														{parameter}
													</DropdownItem>
												);
											})}
										</React.Fragment>
									);
								})}
							</div>
						</div>
					)}
					{input.length > 0 && matches.length > 0 && (
						<div className='dropdown-menu dropdown-menu-scrollable not'>
							<div className='dropdown-content'>
								{matches.map((match, index) => (
									<DropdownItem key={index} onMouseDown={() => itemClickHandler(match)}>
										{match}
									</DropdownItem>
								))}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
export default TypeAheadStaticCategorized;
