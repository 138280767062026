import React, { useState, useEffect } from 'react';
import WorkArea from 'components/molecules/WorkArea';
import PreviewComponent from 'components/molecules/PreviewComponent';
import Column from 'components/atoms/Column';
import ColumnGroup from 'components/atoms/ColumnGroup';
import RuleArea from 'components/molecules/RuleArea';
import { RuleType } from 'interfaces/interface';
import DropdownItem from 'components/atoms/DropdownItem';
import Button from 'components/atoms/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Rule {
	rules: Array<RuleType>;
	condition: string | null;
}
interface FiltersTabProps {
	className?: string;
	ruleList: RuleType[];
	setRuleList: React.Dispatch<React.SetStateAction<RuleType[]>>;
	ruleGroups: Array<Rule>;
	setRuleGroups: React.Dispatch<React.SetStateAction<Array<Rule>>>;
	havingRuleGroups: Array<Rule>;
	setHavingRuleGroups: React.Dispatch<React.SetStateAction<Array<Rule>>>;
	preview: string;
	setPreview: React.Dispatch<React.SetStateAction<string>>;
	havingPreview: string;
	setHavingPreview: React.Dispatch<React.SetStateAction<string>>;
	view: boolean;
	subscriptionStatus: string;
	setSubscriptionStatus: React.Dispatch<React.SetStateAction<string>>;
}

const FiltersTab: React.FC<FiltersTabProps> = ({
	ruleList,
	setRuleList,
	ruleGroups,
	setRuleGroups,
	havingRuleGroups,
	setHavingRuleGroups,
	preview,
	setPreview,
	havingPreview,
	setHavingPreview,
	view,
	subscriptionStatus,
	setSubscriptionStatus
}): JSX.Element => {
	const [simpleRules, setSimpleRules] = useState<RuleType[]>([]);
	const [havingRules, setHavingRules] = useState<RuleType[]>([]);
	const [dropdownOpen, setDropdownOpen] = useState(false);

	useEffect(() => {
		//Initializing and adding names of simple or compound rules
		const havingRules = [];
		const simpleRules = [];
		for (let i = 0; i < ruleList.length; i++) {
			if (ruleList[i].isHaving === true) {
				havingRules.push(ruleList[i]);
			} else {
				simpleRules.push(ruleList[i]);
			}
		}
		setHavingRules(havingRules);
		setSimpleRules(simpleRules);
	}, [ruleList]);

	const itemSelectHandler = (value: string) => {
		setSubscriptionStatus(value);
		setDropdownOpen(!dropdownOpen);
	};

	return (
		<React.Fragment>
			<ColumnGroup>
				<Column className='is-half'>
					<div className='mx-5 mb-3 px-1 py-1 is-flex is-align-items-center subscription-status-area'>
						<div className='has-text-weight-bold mr-3'>Current Subscription Status :</div>

						<div className={`dropdown ${dropdownOpen === false ? '' : 'is-active'}`}>
							<div className='dropdown-trigger'>
								<Button
									aria-haspopup='true'
									aria-controls='dropdown-menu'
									onClick={() => setDropdownOpen(!dropdownOpen)}
									disabled={view}
								>
									<span>{subscriptionStatus === '' ? 'Select Status' : subscriptionStatus}</span>
									<span className='icon is-small'>
										<FontAwesomeIcon icon={['fas', 'angle-down']} aria-hidden='true' />
									</span>
								</Button>
							</div>
							<div className='dropdown-menu' id='dropdown-menu' role='menu'>
								<div className='dropdown-content'>
									<DropdownItem className='has-text-grey-lighter' onClick={() => itemSelectHandler('')}>
										UNSET
									</DropdownItem>
									<DropdownItem onClick={() => itemSelectHandler('Active')}>Active</DropdownItem>
									<DropdownItem onClick={() => itemSelectHandler('Inactive')}>Inactive</DropdownItem>
								</div>
							</div>
						</div>
					</div>
					<RuleArea
						ruleList={ruleList}
						setRuleList={setRuleList}
						ruleGroups={ruleGroups}
						setRuleGroups={setRuleGroups}
						havingRuleGroups={havingRuleGroups}
						setHavingRuleGroups={setHavingRuleGroups}
						view={view}
					/>
				</Column>
				<Column className='is-half'>
					<WorkArea
						ruleList={simpleRules}
						title={'Work Area'}
						ruleGroups={ruleGroups}
						setRuleGroups={setRuleGroups}
						heading='WHERE'
						view={view}
					/>
					<WorkArea
						ruleList={havingRules}
						title={''}
						ruleGroups={havingRuleGroups}
						setRuleGroups={setHavingRuleGroups}
						heading='HAVING'
						view={view}
					/>
					<PreviewComponent
						ruleGroups={ruleGroups}
						havingRuleGroups={havingRuleGroups}
						preview={preview}
						setPreview={setPreview}
						havingPreview={havingPreview}
						setHavingPreview={setHavingPreview}
					/>
				</Column>
			</ColumnGroup>
		</React.Fragment>
	);
};

export default FiltersTab;
