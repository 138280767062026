import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/atoms/Button';
import './style.css';

interface HyperlinkProps {
	className?: string;
	pageTitle?: string;
	setView?: React.Dispatch<React.SetStateAction<boolean>>;
	disabled?: boolean;
}

const PageHeader: React.FC<HyperlinkProps> = ({
	className,
	pageTitle,
	setView,
	disabled
}): JSX.Element => {
	return (
		<React.Fragment>
			<div className={className || ''}>
				<div className='page-header-container'>
					<div className='navbar-brand website-title'>
						<h2 className='navbar-item  has-text-weight-bold px-0'>Audience Builder</h2>
						<span className='navbar-item version-text'>v0.1</span>
					</div>
				</div>
				<div className='page-title-container'>
					<span className='is-size-4 has-text-weight-bold page-title'>{pageTitle}</span>
					{/* Shows edit button on View Segment Page */}
					{pageTitle === 'View Segment' && (
						<Button onClick={() => (setView ? setView(false) : null)} disabled={disabled}>
							<FontAwesomeIcon icon={['fas', 'edit']} className='mr-2 is-small' />
							Edit
						</Button>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

export default PageHeader;
