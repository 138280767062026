import React from 'react';
import DropdownItem from 'components/atoms/DropdownItem';
import DropdownMenu from 'components/atoms/DropdownMenu';
import { KeyString, KeyStringBoolean } from 'interfaces/interface';
import { Link } from 'react-router-dom';
import './style.css';
import { Auth, Segment } from 'services';
import { Constants } from 'utils';

interface ModalData {
	title: string;
	content: string;
	path: string;
}

interface SegmentMenuProps {
	segmentData: KeyString;
	segmentIndex: number;
	downloadDropdownShownList: Array<boolean>;
	setDownloadDropdownShownList: React.Dispatch<React.SetStateAction<Array<boolean>>>;
	downloadLoaderList: Array<boolean>;
	setDownloadLoaderList: React.Dispatch<React.SetStateAction<Array<boolean>>>;
	segmentDownloadList: Array<string[]>;
	setSegmentDownloadList: React.Dispatch<React.SetStateAction<Array<string[]>>>;
	exportLoaderList: Array<KeyStringBoolean>;
	setExportLoaderList: React.Dispatch<React.SetStateAction<Array<KeyStringBoolean>>>;
	isAdmin: boolean;
	setModalData: React.Dispatch<React.SetStateAction<ModalData>>;
	setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setModalAction: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
}

const SegmentMenu: React.FC<SegmentMenuProps> = ({
	segmentData,
	segmentIndex,
	downloadDropdownShownList,
	setDownloadDropdownShownList,
	downloadLoaderList,
	setDownloadLoaderList,
	segmentDownloadList,
	setSegmentDownloadList,
	exportLoaderList,
	setExportLoaderList,
	isAdmin,
	setModalData,
	setIsModalVisible,
	setModalAction
}): JSX.Element => {
	const { exportSuccessful, exportFailed, unAuthorized, downloadListFailed } = Constants.MODAL_DATA;
	const clearJwt = () => {
		localStorage.removeItem('metadata :');
		Auth.logOut();
	};

	const getDownloadSegmentList = (id: string, index: number) => {
		//Sets download loader to true on clicking download button for that particular segment
		const copyDownloadLoaderList = [...downloadLoaderList];
		copyDownloadLoaderList[index] = true;
		setDownloadLoaderList(copyDownloadLoaderList);

		Segment.downloadSegment(id).then((response) => {
			if (response.status === 200) {
				if (response.data.downloadUrlList.length > 0) {
					//Sets download list for that segment if there is download url list from response
					const copySegmentDownloadList = [...segmentDownloadList];
					copySegmentDownloadList[index] = response.data.downloadUrlList;
					setSegmentDownloadList(copySegmentDownloadList);
					//Sets download dropdown visibility to true for that particular segment
					const copyDownloadDropdownShownList = [...downloadDropdownShownList];
					copyDownloadDropdownShownList[index] = true;
					setDownloadDropdownShownList(copyDownloadDropdownShownList);
				}
			} else if (response.status === 401) {
				setModalData(unAuthorized);
				setIsModalVisible(true);
				setModalAction(() => clearJwt());
			} else {
				setModalData(downloadListFailed);
				setIsModalVisible(true);
			}
			//Sets download loader to false on getting response from the api call for that particular segment
			const copyDownloadLoaderList = [...downloadLoaderList];
			copyDownloadLoaderList[index] = false;
			setDownloadLoaderList(copyDownloadLoaderList);
		});
	};

	const exportSegment = (id: string, partner: string, index: number) => {
		//Sets export loader for that concerned partner to true
		const copyExportLoaderList = [...exportLoaderList];
		copyExportLoaderList[index][partner] = true;
		setExportLoaderList(copyExportLoaderList);
		Segment.exportSegment(id, partner).then((response) => {
			if (response.status === 200) {
				setModalData(exportSuccessful);
			} else if (response.status === 401) {
				Auth.logOut();
				setModalData(unAuthorized);
			} else {
				setModalData(exportFailed);
			}
			//Set export loader for that concerned partner to false
			const copyExportLoaderList = [...exportLoaderList];
			copyExportLoaderList[index][partner] = false;
			setExportLoaderList(copyExportLoaderList);
			setIsModalVisible(true);
		});
	};

	const downloadSegment = (url: string) => {
		const a = document.createElement('a');
		a.setAttribute('href', url);
		a.click();
	};
	return (
		<React.Fragment>
			{/* Download dropdown will be enabled only if segmentData status is not 'SAVED' 
            and segment size is greater than zero */}
			{segmentData.status !== 'SAVED' && segmentData.size !== '0' ? (
				<DropdownMenu
					menuName='Download Segment'
					menuHint={segmentData.lastDownloadedAt}
					segmentId={segmentData.id}
					segmentIndex={segmentIndex}
					menuOnClickHandler={getDownloadSegmentList}
					apiTriggerDropdown={downloadDropdownShownList[segmentIndex]}
					isLoading={downloadLoaderList[segmentIndex]}
				>
					{segmentDownloadList[segmentIndex].length > 0 &&
						segmentDownloadList[segmentIndex].map((url, indexList) => {
							return (
								<DropdownItem
									key={indexList}
									onClick={() => {
										downloadSegment(url);
									}}
								>
									File {indexList + 1}
								</DropdownItem>
							);
						})}
				</DropdownMenu>
			) : (
				<DropdownItem className='has-text-grey-light cursor-not-allowed'>Download Segment</DropdownItem>
			)}
			{/* Send to partner dropdown will be enabled only if segmentData status is not 'SAVED' 
															and segment size is greater than zero */}
			{segmentData.status !== 'SAVED' && segmentData.size !== '0' ? (
				<DropdownMenu menuName='Send to partner'>
					{segmentData.canExportToCleverTap ? (
						<DropdownItem
							onClick={() => {
								exportSegment(segmentData.id, 'Clevertap', segmentIndex);
							}}
							className={
								exportLoaderList[segmentIndex].Clevertap ? 'disabled-link has-text-grey-light' : ''
							}
						>
							<div className='is-flex is-align-items-center'>
								<div>Clevertap</div>
								{exportLoaderList[segmentIndex].Clevertap && <div className='loader ml-3'></div>}
							</div>
							{segmentData.lastSharedToCleverTapAt !== '' && (
								<span
									className={`has-text-link is-size-7 ${
										segmentData.lastSharedToCleverTapSuccessful ? 'has-text-success' : 'has-text-danger'
									}`}
								>
									({segmentData.lastSharedToCleverTapAt})
								</span>
							)}
						</DropdownItem>
					) : (
						<DropdownItem className='has-text-grey-light cursor-not-allowed'>Clevertap</DropdownItem>
					)}
					{segmentData.canExportToFirebase ? (
						<DropdownItem
							onClick={() => {
								exportSegment(segmentData.id, 'Firebase', segmentIndex);
							}}
							className={
								exportLoaderList[segmentIndex].Firebase ? 'disabled-link has-text-grey-light' : ''
							}
						>
							<div className='is-flex is-align-items-center'>
								<div>Firebase</div>
								{exportLoaderList[segmentIndex].Firebase && <div className='loader ml-3'></div>}
							</div>
							{segmentData.lastSharedToFirebaseAt !== '' && (
								<span
									className={`has-text-link is-size-7 ${
										segmentData.lastSharedToFirebaseSuccessful ? 'has-text-success' : 'has-text-danger'
									}`}
								>
									({segmentData.lastSharedToFirebaseAt})
								</span>
							)}
						</DropdownItem>
					) : (
						<DropdownItem className='has-text-grey-light cursor-not-allowed'>Firebase</DropdownItem>
					)}
					{segmentData.canExportToGAM ? (
						<DropdownItem
							onClick={() => {
								exportSegment(segmentData.id, 'GoogleAdManager', segmentIndex);
							}}
							className={
								exportLoaderList[segmentIndex].GoogleAdManager ? 'disabled-link has-text-grey-light' : ''
							}
						>
							<div className='is-flex is-align-items-center'>
								<div>Google ad Manager</div>
								{exportLoaderList[segmentIndex].GoogleAdManager && <div className='loader ml-3'></div>}
							</div>
							{segmentData.lastSharedToGAMAt !== '' && (
								<span
									className={`has-text-link is-size-7 ${
										segmentData.lastSharedToGAMSuccessful ? 'has-text-success' : 'has-text-danger'
									}`}
								>
									({segmentData.lastSharedToGAMAt})
								</span>
							)}
						</DropdownItem>
					) : (
						<DropdownItem className='has-text-grey-light cursor-not-allowed'>
							Google Ad Manager
						</DropdownItem>
					)}
				</DropdownMenu>
			) : (
				<DropdownItem className='has-text-grey-light cursor-not-allowed'>Send to Partner</DropdownItem>
			)}
			{/* Duplicate segment button will be disabled if the user is creator 
															and query type is custom query */}
			{!segmentData.isCustomQuery || isAdmin ? (
				<Link to={`/segment/create?id=${segmentData.id}`}>
					<DropdownItem>Duplicate Segment</DropdownItem>
				</Link>
			) : (
				<DropdownItem className='has-text-grey-light cursor-not-allowed'>
					Duplicate Segment
				</DropdownItem>
			)}
		</React.Fragment>
	);
};

export default SegmentMenu;
