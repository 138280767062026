import React from 'react';

interface RadioProps {
	className?: string;
	name?: string;
	value?: string;
	label?: string;
	checked?: boolean;
	onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
	disabled?: boolean;
}

const Radio: React.FC<RadioProps> = ({
	className,
	name,
	value,
	label,
	checked,
	onChange,
	disabled
}): JSX.Element => {
	return (
		<React.Fragment>
			<label className={`radio ${className ? className : ''}`}>
				<input
					type='radio'
					name={name}
					className='mr-1'
					value={value}
					checked={checked}
					onChange={onChange}
					disabled={disabled}
				/>
				{label}
			</label>
		</React.Fragment>
	);
};

export default Radio;
