import React, { useEffect } from 'react';
import { RuleType } from 'interfaces/interface';

interface Rule {
	rules: Array<RuleType>;
	condition: string | null;
}
interface PreviewComponentProps {
	className?: string;
	ruleGroups: Array<Rule>;
	havingRuleGroups: Array<Rule>;
	preview: string;
	setPreview: React.Dispatch<React.SetStateAction<string>>;
	havingPreview: string;
	setHavingPreview: React.Dispatch<React.SetStateAction<string>>;
}

const PreviewComponent: React.FC<PreviewComponentProps> = ({
	ruleGroups,
	havingRuleGroups,
	preview,
	setPreview,
	havingPreview,
	setHavingPreview
}): JSX.Element => {
	useEffect(() => {
		let previewString = '';
		previewString = '( ';
		// Iteration through ruleGroups and forming the preview every time ruleGroups are update
		for (let i = 0; i < ruleGroups.length; i++) {
			previewString = previewString + ' ( ';
			for (let j = 0; j < ruleGroups[i].rules.length; j++) {
				previewString =
					previewString +
					ruleGroups[i].rules[j].name +
					// write condition or <> when its not the last element
					(j !== ruleGroups[i].rules.length - 1 ? ' ' + (ruleGroups[i].rules[j].condition !== null ? ruleGroups[i].rules[j].condition : ' <> ') + ' ' : ' '); // prettier-ignore
			}
			previewString =
				previewString +
				' ) ' +
				(i !== ruleGroups.length - 1
					? ruleGroups[i].condition !== null
						? ruleGroups[i].condition
						: ' <> ' // <> Sign incase of a blank condition
					: ' ');
		}
		previewString = previewString + ' ) ';
		if (previewString === '(  (  )   ) ') {
			setPreview('');
		} else {
			setPreview(previewString);
		}
	}, [ruleGroups, havingRuleGroups]);

	useEffect(() => {
		let previewString = '( ';
		for (let i = 0; i < havingRuleGroups.length; i++) {
			previewString = previewString + ' ( ';
			for (let j = 0; j < havingRuleGroups[i].rules.length; j++) {
				previewString =
					previewString +
					havingRuleGroups[i].rules[j].name +
					(j !== havingRuleGroups[i].rules.length - 1 ? ' ' + (havingRuleGroups[i].rules[j].condition !== null ? havingRuleGroups[i].rules[j].condition : ' <> ') + ' ' : ' '); // prettier-ignore
			}
			previewString =
				previewString +
				' ) ' +
				(i !== havingRuleGroups.length - 1
					? havingRuleGroups[i].condition !== null
						? havingRuleGroups[i].condition
						: ' <> '
					: ' ');
		}
		previewString = previewString + ' ) ';
		if (previewString === '(  (  )   ) ') {
			setHavingPreview('');
		} else {
			setHavingPreview(previewString);
		}
	}, [ruleGroups, havingRuleGroups]);

	return (
		<React.Fragment>
			<div className='wa-container is-flex is-justify-content-space-between mt-5'>
				<div className='work-section p-5'>
					<h1 className='is-size-3 mb-3'>Preview</h1>
					<h1 className='is-size-6 mb-3 has-text-weight-bold'>WHERE</h1>
					<p>{preview}</p>
					<hr />
					<h1 className='is-size-6 mb-3 has-text-weight-bold'>HAVING</h1>
					<p>{havingPreview}</p>
				</div>
			</div>
		</React.Fragment>
	);
};

export default PreviewComponent;
