import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Input from 'components/atoms/Input';
import Label from 'components/atoms/Label';
import React, { useEffect, useState } from 'react';
import TypeAHeadStaticCategorized from 'components/molecules/TypeAheadStaticCategorized';
import { CategorizeParameterType, ParameterType, RuleType } from 'interfaces/interface';
import TypeAheadParameterValue from 'components/molecules/TypeAheadParameterValue';
import DropdownItem from 'components/atoms/DropdownItem';
import { Constants } from 'utils';
import './style.css';
import Chip from 'components/atoms/Chip';
interface SubRuleCardProps {
	className?: string;
	parameterList: Array<string>;
	categorizeParameterList: CategorizeParameterType[];
	ruleList: RuleType[];
	setRuleList: React.Dispatch<React.SetStateAction<RuleType[]>>;
	ruleIndex: number;
	subRuleIndex: number;
	havingShown?: boolean;
	view: boolean;
}

const SubRuleCard: React.FC<SubRuleCardProps> = ({
	parameterList,
	categorizeParameterList,
	ruleList,
	setRuleList,
	ruleIndex,
	subRuleIndex,
	havingShown,
	view
}): JSX.Element => {
	const [previousParameter, setPreviousParameter] = useState<string>(
		ruleList[ruleIndex].subRules[subRuleIndex].parameter
	);
	const [selectedParameter, setSelectedParameter] = useState<string>(
		ruleList[ruleIndex].subRules[subRuleIndex].parameter
	);
	const [parameterContent, setParameterContent] = useState<ParameterType>();
	const [conditionOptions, setConditonOptions] = useState<string[]>([]);
	const [havingOptions, setHavingOptions] = useState<string[]>([]);

	const [value, setValue] = useState<string>(
		ruleList[ruleIndex].subRules[subRuleIndex].value[
			ruleList[ruleIndex].subRules[subRuleIndex].value.length - 1
		]
	);
	const [min, setMin] = useState<string>(ruleList[ruleIndex].subRules[subRuleIndex].min);
	const [max, setMax] = useState<string>(ruleList[ruleIndex].subRules[subRuleIndex].max);

	const [havingDropdown, setHavingDropdown] = useState<boolean>(false);
	const [conditionDropdown, setConditionDropdown] = useState<boolean>(false);

	useEffect(() => {
		const rule = ruleList[ruleIndex].subRules[subRuleIndex];
		//On rulelist state change such as deleting a rule or adding or any changes in rulelist,
		//selectedParameter is synced with the actual rulelist state parameter if they are not in sync.
		//This sync is also maintained for rule value, min and max fields.
		if (rule.parameter !== selectedParameter) {
			setPreviousParameter(rule.parameter);
			setSelectedParameter(rule.parameter);
		}
		if (rule.value[rule.value.length - 1] !== value || rule.min !== min || rule.max !== max) {
			setValue(rule.value[rule.value.length - 1]);
			setMin(rule.min);
			setMax(rule.max);
		}
	}, [ruleList]);

	useEffect(() => {
		if (selectedParameter !== '') {
			changeParameterHandler();
		}
	}, [selectedParameter]);

	useEffect(() => {
		updateSubRuleChange('value', value);
	}, [value]);

	useEffect(() => {
		updateSubRuleChange('min', min);
	}, [min]);

	useEffect(() => {
		updateSubRuleChange('max', max);
	}, [max]);

	const toggleHavingDropdown = () => {
		setHavingDropdown(!havingDropdown);
	};

	const toggleConditionDropdown = () => {
		setConditionDropdown(!conditionDropdown);
	};

	//This function handles changes in parameter value
	const changeParameterHandler = () => {
		//Parameter content for the selected parameter is extracted from parameter mapping and
		//set to parameterContent state for further use
		const copyParameterContent = Constants.ParameterMapping.find((item) => {
			return item.displayName === selectedParameter;
		});
		if (copyParameterContent) {
			setConditonOptions(Constants.ConditionMapping[copyParameterContent.columnType]);
			setParameterContent(copyParameterContent);
			if (copyParameterContent.columnType === 'number')
				setHavingOptions(Constants.HavingOptions.number);
			else setHavingOptions(Constants.HavingOptions.stringAndDate);
			updateSubRuleChange('apiColumnName', copyParameterContent.apiColumnName);
			updateSubRuleChange('columnType', copyParameterContent.columnType);
		}
		//This check helps in preserving values of subrule, in case previousParameter and selectedParameter values are the same.
		//This situation can arise on initial load of subrule, where these two values will be the same
		//and without this check all the other values of subrules will be reset, instead of filling up using rulelist state.
		if (previousParameter !== selectedParameter) {
			updateSubRuleChange('having', '');
			updateSubRuleChange('operator', '');
			updateSubRuleChange('parameter', selectedParameter);
			setPreviousParameter(selectedParameter);
		}
	};

	//Incase operator is 'contain/does not contain', chips are needed to store all the possible values
	//and this function helps in adding value to the chip.
	const containChipAddHandler = (value: string) => {
		const copyRuleList = [...ruleList];
		if (!copyRuleList[ruleIndex].subRules[subRuleIndex].value.includes(value) && value !== '') {
			copyRuleList[ruleIndex].subRules[subRuleIndex].value.push(value);
		}
		setRuleList(copyRuleList);
	};

	//This function handles deletion of value from chips in 'contain/does not contain' operator.
	const containChipDeleteHandler = (value: string) => {
		const copyRuleList = [...ruleList];
		const copyRuleValues = copyRuleList[ruleIndex].subRules[subRuleIndex].value.filter((item) => {
			return !(value === item);
		});
		if (copyRuleValues.length === 0) {
			copyRuleList[ruleIndex].subRules[subRuleIndex].value = [''];
		} else copyRuleList[ruleIndex].subRules[subRuleIndex].value = copyRuleValues;
		setRuleList(copyRuleList);
	};

	//This function handles any changes in subrule values and update it to rulelist state.
	const updateSubRuleChange = (key: string, value: string) => {
		const copyRuleList = [...ruleList];
		switch (key) {
			case 'value':
				if (
					(ruleList[ruleIndex].subRules[subRuleIndex].operator === 'contains' ||
						ruleList[ruleIndex].subRules[subRuleIndex].operator === 'does not contain') &&
					ruleList[ruleIndex].subRules[subRuleIndex].value[0] !== ''
				) {
					containChipAddHandler(value);
				} else copyRuleList[ruleIndex].subRules[subRuleIndex].value[0] = value;
				break;
			case 'min':
				copyRuleList[ruleIndex].subRules[subRuleIndex].min = value;
				break;
			case 'max':
				copyRuleList[ruleIndex].subRules[subRuleIndex].max = value;
				break;
			case 'parameter':
				copyRuleList[ruleIndex].subRules[subRuleIndex].parameter = value;
				break;
			case 'operator':
				copyRuleList[ruleIndex].subRules[subRuleIndex].operator = value;
				setConditionDropdown(false);
				break;
			case 'having':
				copyRuleList[ruleIndex].subRules[subRuleIndex].having = value;
				setHavingDropdown(false);
				break;
			case 'apiColumnName':
				copyRuleList[ruleIndex].subRules[subRuleIndex].apiColumnName = value;
				break;
			case 'columnType':
				copyRuleList[ruleIndex].subRules[subRuleIndex].columnType = value;
				break;
		}
		//If either operator or parameter changes, then value, from(min) and to(max) will be reset.
		if (key === 'operator' || key === 'parameter') {
			copyRuleList[ruleIndex].subRules[subRuleIndex].value = [''];
			copyRuleList[ruleIndex].subRules[subRuleIndex].min = '';
			copyRuleList[ruleIndex].subRules[subRuleIndex].max = '';
			setRuleList(copyRuleList);
		} else {
			setRuleList(copyRuleList);
		}
	};

	const typeAheadCategorizedProps = {
		label: 'Parameter',
		value: ruleList[ruleIndex].subRules[subRuleIndex].parameter,
		placeholder: 'Start typing',
		data: parameterList,
		categorizeParameterList: categorizeParameterList,
		setSelected: setSelectedParameter,
		view: view
	};

	const typeAheadValueProps = {
		selectedValue: value,
		setSelectedValue: setValue,
		parameterContent: parameterContent,
		selectedParameter: selectedParameter,
		view: view
	};

	const typeAheadMinProps = {
		selectedValue: min,
		setSelectedValue: setMin,
		parameterContent: parameterContent,
		selectedParameter: selectedParameter,
		view: view
	};

	const typeAheadMaxProps = {
		selectedValue: max,
		setSelectedValue: setMax,
		parameterContent: parameterContent,
		selectedParameter: selectedParameter,
		view: view
	};

	return (
		<React.Fragment>
			<div
				className={`container ${subRuleIndex === 0 || subRuleIndex === 1 ? '' : 'container-border'}`}
			>
				<div className='parameter-condition-container is-flex is-justify-content-space-between py-3 px-4'>
					<div className='is-flex-grow-1 mr-3'>
						<TypeAHeadStaticCategorized {...typeAheadCategorizedProps} />
					</div>
					{/* Having dropdown menu */}
					{selectedParameter !== '' && havingShown && (
						<div className='is-flex-grow-1 mr-3'>
							<Label className='is-normal'>Having</Label>
							<div className='dropdown is-active'>
								<div className='dropdown-trigger'>
									<button
										className='button dropdown-button'
										aria-haspopup='true'
										aria-controls='dropdown-menu'
										onClick={() => toggleHavingDropdown()}
										disabled={view}
									>
										<span>
											{ruleList[ruleIndex].subRules[subRuleIndex].having
												? ruleList[ruleIndex].subRules[subRuleIndex].having
												: 'Select Having'}
										</span>
										<span className='icon is-small'>
											<FontAwesomeIcon icon={['fas', 'angle-down']} />
										</span>
									</button>
								</div>
								<div className='dropdown-menu' id='dropdown-menu' role='menu'>
									{havingDropdown && (
										<div className='dropdown-content'>
											{havingOptions.map((option, index) => {
												return (
													<DropdownItem key={index} onClick={() => updateSubRuleChange('having', option)}>
														{option}
													</DropdownItem>
												);
											})}
										</div>
									)}
								</div>
							</div>
						</div>
					)}
					{/* Operator/Conditon dropdown menu */}
					{selectedParameter !== '' && (
						<div className='is-flex-grow-1'>
							<Label className='is-normal'>Conditions</Label>
							<div className='dropdown is-active'>
								<div className='dropdown-trigger'>
									<button
										className='button dropdown-button'
										aria-haspopup='true'
										aria-controls='dropdown-menu'
										onClick={() => toggleConditionDropdown()}
										disabled={view}
									>
										<span>
											{ruleList[ruleIndex].subRules[subRuleIndex].operator
												? ruleList[ruleIndex].subRules[subRuleIndex].operator
												: 'Select Operator'}
										</span>
										<span className='icon is-small'>
											<FontAwesomeIcon icon={['fas', 'angle-down']} />
										</span>
									</button>
								</div>
								<div className='dropdown-menu' id='dropdown-menu' role='menu'>
									{conditionDropdown && (
										<div className='dropdown-content'>
											{conditionOptions.map((option, index) => {
												return (
													<DropdownItem key={index} onClick={() => updateSubRuleChange('operator', option)}>
														{option}
													</DropdownItem>
												);
											})}
										</div>
									)}
								</div>
							</div>
						</div>
					)}
				</div>
				{/* Value, from(min) and to(max) input or typeahead */}
				{ruleList[ruleIndex].subRules[subRuleIndex].operator !== '' && (
					<div className='py-2 px-4'>
						{ruleList[ruleIndex].subRules[subRuleIndex].operator === 'between' && (
							<React.Fragment>
								<Label className='is-normal'>Between</Label>
								<div className='is-flex is-flex-direction-row is-align-items-center'>
									<Label className='my-auto mr-2'>From</Label>
									{/* If type of value is date then typeahead is not used, instead input date type is used as to select any date the user wants */}
									{ruleList[ruleIndex].subRules[subRuleIndex].columnType === 'date' ? (
										<Input
											className='mr-2'
											value={ruleList[ruleIndex].subRules[subRuleIndex].min}
											type={ruleList[ruleIndex].subRules[subRuleIndex].columnType}
											onChange={(e) => updateSubRuleChange('min', e.target.value)}
											disabled={view}
										></Input>
									) : (
										<div className='value-container'>
											<TypeAheadParameterValue {...typeAheadMinProps}></TypeAheadParameterValue>
										</div>
									)}
									<Label className='my-auto mr-2 pl-2'>To</Label>
									{/* If type of value is date then typeahead is not used, instead input date type is used as to select any date the user wants */}
									{ruleList[ruleIndex].subRules[subRuleIndex].columnType === 'date' ? (
										<Input
											type={ruleList[ruleIndex].subRules[subRuleIndex].columnType}
											value={ruleList[ruleIndex].subRules[subRuleIndex].max}
											onChange={(e) => updateSubRuleChange('max', e.target.value)}
											disabled={view}
										></Input>
									) : (
										<div className='value-container'>
											<TypeAheadParameterValue {...typeAheadMaxProps}></TypeAheadParameterValue>
										</div>
									)}
								</div>
							</React.Fragment>
						)}
						{ruleList[ruleIndex].subRules[subRuleIndex].operator !== 'between' &&
							ruleList[ruleIndex].subRules[subRuleIndex].operator !== 'exists' &&
							ruleList[ruleIndex].subRules[subRuleIndex].operator !== 'does not exist' && (
								<React.Fragment>
									<Label className='is-normal'>Value</Label>
									{/* If type of value is date then typeahead is not used, instead input date type is used as to select any date the user wants */}
									{ruleList[ruleIndex].subRules[subRuleIndex].columnType === 'date' ? (
										<div className='value-container'>
											<Input
												className='is-normal'
												value={
													ruleList[ruleIndex].subRules[subRuleIndex].value
														? ruleList[ruleIndex].subRules[subRuleIndex].value[0]
														: ''
												}
												onChange={(e) => updateSubRuleChange('value', e.target.value)}
												type={ruleList[ruleIndex].subRules[subRuleIndex].columnType}
												disabled={view}
											></Input>
										</div>
									) : (
										<TypeAheadParameterValue
											{...typeAheadValueProps}
											isContainType={
												ruleList[ruleIndex].subRules[subRuleIndex].operator === 'contains' ||
												ruleList[ruleIndex].subRules[subRuleIndex].operator === 'does not contain'
											}
										></TypeAheadParameterValue>
									)}
									{/* Chips for 'contain/does not contain' type operators/conditions */}
									{(ruleList[ruleIndex].subRules[subRuleIndex].operator === 'contains' ||
										ruleList[ruleIndex].subRules[subRuleIndex].operator === 'does not contain') && (
										<div>
											{ruleList[ruleIndex].subRules[subRuleIndex].value &&
												ruleList[ruleIndex].subRules[subRuleIndex].value.map((value: string, index) => {
													if (value !== '')
														return (
															<Chip
																className='mt-1'
																key={index}
																handleDelete={containChipDeleteHandler}
																label={value}
																view={view}
															>
																{value}
															</Chip>
														);
												})}
										</div>
									)}
								</React.Fragment>
							)}
					</div>
				)}
			</div>
		</React.Fragment>
	);
};

export default SubRuleCard;
