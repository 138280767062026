import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Column from 'components/atoms/Column';
import ColumnGroup from 'components/atoms/ColumnGroup';
import DropdownItem from 'components/atoms/DropdownItem';
import Input from 'components/atoms/Input';
import { PropsInterface } from 'interfaces';
import Button from 'components/atoms/Button';

const RefreshFrequency: React.FC<PropsInterface.RefreshFrequencyProps> = ({
	refreshFrequencyUnit,
	setRefreshFrequencyUnit,
	refreshFrequencyInput,
	setRefreshFrequencyInput,
	refreshFrequencyErrorMessage,
	setRefreshFrequencyErrorMessage,
	view
}): JSX.Element => {
	const invalidCharacters = ['.', '-', '+', 'e'];
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen);
	};

	const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (invalidCharacters.includes(e.key)) {
			e.preventDefault();
		}
	};

	const validateInput = (num: string) => {
		const value = parseInt(num);
		if (value > 99 || value < 1) {
			setRefreshFrequencyErrorMessage('Please enter a number between 1 & 99');
		} else {
			setRefreshFrequencyInput(num);
			setRefreshFrequencyErrorMessage('');
		}
	};

	const itemSelectHandler = (text: string) => {
		setRefreshFrequencyUnit(text);
		toggleDropdown();
	};

	return (
		<React.Fragment>
			<ColumnGroup>
				<Column>
					<Input
						type='number'
						placeholder='Number'
						id='numInput'
						value={refreshFrequencyInput}
						onChange={(e) => {
							validateInput(e.target.value);
						}}
						onKeyDown={(e) => {
							keyDownHandler(e);
						}}
						disabled={view}
					/>
					<p className={'help has-text-left is-danger'}>{refreshFrequencyErrorMessage}</p>
				</Column>
				<Column>
					<div className={`dropdown ${dropdownOpen === false ? '' : 'is-active'}`}>
						<div className='dropdown-trigger'>
							<Button
								aria-haspopup='true'
								aria-controls='dropdown-menu'
								onClick={() => toggleDropdown()}
								disabled={view}
							>
								<span>{refreshFrequencyUnit}</span>
								<span className='icon is-small'>
									<FontAwesomeIcon icon={['fas', 'angle-down']} aria-hidden='true' />
								</span>
							</Button>
						</div>
						<div className='dropdown-menu' id='dropdown-menu' role='menu'>
							<div className='dropdown-content'>
								<DropdownItem onClick={() => itemSelectHandler('Hours')}>Hours</DropdownItem>
								<DropdownItem onClick={() => itemSelectHandler('Days')}>Days</DropdownItem>
								<DropdownItem onClick={() => itemSelectHandler('Months')}>Months</DropdownItem>
							</div>
						</div>
					</div>
				</Column>
			</ColumnGroup>
		</React.Fragment>
	);
};

export default RefreshFrequency;
