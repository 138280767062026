import axios from 'axios';
interface KeyString {
	[key: string]: string;
}

interface CreateUserPayload {
	name: string;
	email: string;
	role: string;
}

interface UpdateUserPayload {
	name?: string;
	email?: string;
	role?: string;
	currentEmail?: string;
	status?: string;
}

const getUsersList = async (page: number, email?: string | undefined, all?: boolean) => {
	const url = '/api/v1/users';
	const offset = (page - 1) * 50;
	return axios
		.get(url, {
			params: {
				offset: offset,
				email: email,
				all: all
			}
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response) {
				return error.response;
			}
		});
};

const createUser = async (payload: CreateUserPayload) => {
	const url = '/api/v1/users/';
	return axios
		.post(url, payload)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response) {
				return error.response;
			}
		});
};

const getUserById = async (id: string) => {
	const url = `/api/v1/users/${id}`;
	return axios
		.get(url)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response) {
				return error.response;
			}
		});
};

const updateUser = async (payload: UpdateUserPayload, userId: string) => {
	const url = `/api/v1/users/${userId}`;
	return axios
		.put(url, payload)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response) {
				return error.response;
			}
		});
};

const deactivateUsers = (users: KeyString[]) => {
	const payload = users.map((user: KeyString) => {
		const { id, email } = user;
		const userPayload = {
			userId: id,
			email: email
		};
		return userPayload;
	});
	const url = '/api/v1/users/bulk/deactivate';
	return axios
		.put(url, payload)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response) {
				return error.response;
			}
		});
};

export { getUsersList, createUser, getUserById, updateUser, deactivateUsers };
