import React, { useEffect, useState } from 'react';
import Button from 'components/atoms/Button';
import Table from 'components/molecules/Table';
import NavBar from 'components/molecules/NavBar';
import Pagination from 'components/molecules/Pagination';
import PageHeader from 'components/molecules/PageHeader';
import { User, Auth } from 'services';
import { Link } from 'react-router-dom';
import Loader from 'components/atoms/Loader';
import HeroContainer from 'components/atoms/HeroContainer';
import Body from 'components/atoms/Body';
import Container from 'components/atoms/Container';
import Modal from 'components/molecules/Modal';
import { Helpers, Constants } from 'utils';
import './style.css';
interface KeyString {
	[key: string]: string;
}

const AdminDashboard: React.FC = (): JSX.Element => {
	const { getUsersList, deactivateUsers, updateUser } = User;
	const { getLocalTime } = Helpers;
	const { forgotPassword, logOut } = Auth;
	const {
		fetchUsersFailed,
		deactivateUsersAlert,
		deactivateUsersFailed,
		resetPasswordSuccess,
		unknownError,
		userUnlockSuccess,
		unAuthorized
	} = Constants.MODAL_DATA;
	const [userList, setUserList] = useState<KeyString[]>([]);
	const [selectedUsers, setSelectedUsers] = useState<KeyString[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [modalData, setModalData] = useState(fetchUsersFailed);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [disableDeactivate, setDisableDeactivate] = useState(true);
	const [deactivateLoader, setDeactivateLoader] = useState(false);
	const [refreshFlag, setRefreshFlag] = useState(true);
	const [page, setPage] = useState(1);
	const [dataSize, setDataSize] = useState(0);
	const [modalAction, setModalAction] = useState<(() => void) | undefined>(undefined);
	const [activeMenuRow, setActiveMenuRow] = useState<KeyString>({
		id: '',
		name: '',
		email: '',
		status: '',
		role: '',
		createdAt: '',
		lastSuccessfulLogin: ''
	});

	const displayColumnOrder: string[] = [
		'name',
		'email',
		'status',
		'role',
		'createdAt',
		'lastSuccessfulLogin'
	];
	const displayColumnName: string[] = [
		'Name',
		'Email Id',
		'Status',
		'Role',
		'Created At',
		'Last Login'
	];

	const clearJwt = () => {
		localStorage.removeItem('metadata :');
		logOut();
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		setIsLoading(true);
		//Get the list of all the users in the table
		getUsersList(page).then((response) => {
			if (response.status === 200) {
				const list = response.data.data.map((item: KeyString) => {
					item.createdAt = getLocalTime(item.createdAt);
					item.lastSuccessfulLogin = getLocalTime(item.lastSuccessfulLogin);
					return item;
				});
				setDataSize(response.data.totalCount);
				setUserList(list);
			}
			//If the user is not allowed to access the list of users
			else if (response.status === 401) {
				setModalData(unAuthorized);
				setIsModalVisible(true);
				setModalAction(() => clearJwt());
			} else {
				setModalData(fetchUsersFailed);
				setIsModalVisible(true);
			}
			setIsLoading(false);
		});
	}, [refreshFlag, page]);

	useEffect(() => {
		if (selectedUsers.length > 0) setDisableDeactivate(false);
		else setDisableDeactivate(true);
	}, [selectedUsers]);

	const deactivateModalTrigger = () => {
		setModalData(deactivateUsersAlert);
		setIsModalVisible(true);
		setModalAction(() => deactivate);
	};

	const deactivate = async () => {
		setModalAction(undefined);
		setDisableDeactivate(true);
		setDeactivateLoader(true);
		const response = await deactivateUsers(selectedUsers);
		if (response.status === 200) {
			setSelectedUsers([]);
		} else {
			setDisableDeactivate(false);
			setSelectedUsers([]);
			setModalData(deactivateUsersFailed);
			setIsModalVisible(true);
		}
		setDeactivateLoader(false);
		setRefreshFlag(!refreshFlag);
	};

	//Option to reset the password if the user has forgotten or wants to reset the password
	const resetPassword = () => {
		setModalAction(undefined);
		setIsLoading(true);
		forgotPassword(activeMenuRow.email).then((response) => {
			if (response.status === 200) {
				setModalData(resetPasswordSuccess);
				setIsModalVisible(true);
				setIsLoading(false);
			} else {
				setModalData(unknownError);
				setIsModalVisible(true);
				setIsLoading(false);
			}
		});
	};

	//If the user has been locked due to 5 unsuccessful login attempts, then that user can be unlocked
	const unlockUser = () => {
		setModalAction(undefined);
		setIsLoading(true);
		const payload = { status: 'ACTIVE', currentEmail: activeMenuRow.email };
		updateUser(payload, activeMenuRow.id).then((response) => {
			if (response.status === 200) {
				setModalData(userUnlockSuccess);
				setIsModalVisible(true);
				setIsLoading(false);
				setRefreshFlag(!refreshFlag);
			} else {
				setModalData(unknownError);
				setIsModalVisible(true);
				setIsLoading(false);
			}
		});
	};

	const tableProps = {
		tableType: 'user',
		className: 'is-fullwidth is-hoverable',
		data: userList,
		showCheckBox: true,
		showAction: true,
		selectedRows: selectedUsers,
		setSelectedRows: setSelectedUsers,
		displayColumnOrder: displayColumnOrder,
		displayColumnName: displayColumnName,
		setModalData: setModalData,
		setIsModalVisible: setIsModalVisible,
		setActiveMenuRow: setActiveMenuRow,
		setModalAction: setModalAction,
		resetPassword: resetPassword,
		unlockUser: unlockUser
	};

	return (
		<React.Fragment>
			<div className='dashboard-container'>
				<NavBar className='is-right'></NavBar>
				<Modal
					data={modalData}
					isModalVisible={isModalVisible}
					setIsModalVisible={setIsModalVisible}
					action={modalAction}
					setAction={setModalAction}
				/>
				{!isLoading ? (
					<React.Fragment>
						<PageHeader pageTitle='Users'></PageHeader>
						<div className='user-options'>
							<Button
								className={'is-dark mr-5 ' + (deactivateLoader ? 'is-loading' : '')}
								disabled={disableDeactivate}
								onClick={deactivateModalTrigger}
							>
								Deactivate
							</Button>
							<Link to='/user/create'>
								<Button className='is-dark'>Create</Button>
							</Link>
						</div>
						<div className='users-table-container'>
							<Table {...tableProps} />
						</div>
						<div className='admin-pagination my-4'>
							<Pagination dataSize={dataSize} page={page} setPage={setPage}></Pagination>
						</div>
					</React.Fragment>
				) : (
					<HeroContainer className='is-fullheight is-flex is-justify-content-center'>
						<Body>
							<Container className='is-flex is-justify-content-center'>
								<Loader className='icon is-large' />
							</Container>
						</Body>
					</HeroContainer>
				)}
			</div>
		</React.Fragment>
	);
};

export default AdminDashboard;
