import React, { useEffect, useRef, useState } from 'react';
import Checkbox from 'components/atoms/Checkbox';
import TypeAhead from 'components/molecules/TypeAhead';
import { Segment } from 'services';
import Modal from 'components/molecules/Modal';
import { Constants } from 'utils';
import ToolTip from 'components/atoms/ToolTip';

interface PartnersTabProps {
	className?: string;
	isDownloadSelected: boolean;
	setIsDownloadSelected: React.Dispatch<React.SetStateAction<boolean>>;
	selectedPartners: string[];
	setSelectedPartners: React.Dispatch<React.SetStateAction<string[]>>;
	selectedFields: string[];
	view: boolean;
	selectedQueryType: string;
	data: Array<{ id?: string; value: string }>;
	setData: React.Dispatch<React.SetStateAction<Array<{ id?: string; value: string }>>>;
	gamInput: string;
	setGamInput: React.Dispatch<React.SetStateAction<string>>;
	currentValidGamInput: string;
	setCurrentValidGamInput: React.Dispatch<React.SetStateAction<string>>;
	setGamId: React.Dispatch<React.SetStateAction<string>>;
}

const PartnersTab: React.FC<PartnersTabProps> = ({
	isDownloadSelected,
	setIsDownloadSelected,
	selectedPartners,
	setSelectedPartners,
	selectedFields,
	selectedQueryType,
	view,
	data,
	setData,
	gamInput,
	setGamInput,
	currentValidGamInput,
	setCurrentValidGamInput,
	setGamId
}): JSX.Element => {
	const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
	const [loader, setLoader] = useState<boolean>(false);

	const { unknownError, failedToLoadAudience } = Constants.MODAL_DATA;
	const [isModalVisible, setIsModalVisible] = useState(false); //eslint-disable-line
	const [isLoading, setIsLoading] = useState(false); //eslint-disable-line
	const [modalData, setModalData] = useState(unknownError); //eslint-disable-line

	const fetchPermission = useRef(false);

	const updateSelectedPartners = (partner: string) => {
		if (selectedPartners.includes(partner)) {
			const copySelectedPartners = [...selectedPartners];
			setSelectedPartners(copySelectedPartners.filter((item) => !(partner === item)));
		} else {
			setSelectedPartners([...selectedPartners, partner]);
		}
	};

	const handleClick = (value: string) => {
		fetchPermission.current = false;
		setCurrentValidGamInput(value);
		setGamInput(value);
	};

	const handleClickId = (id: string) => {
		setGamId(id);
	};

	const onFocusHandler = () => {
		getGAMAudienceList();
		fetchPermission.current = true;
	};

	const onBlurHandler = () => {
		if (currentValidGamInput !== gamInput) {
			setGamInput(currentValidGamInput);
		}
		fetchPermission.current = false;
	};

	const typeAheadProps = {
		data: data,
		inputType: 'text',
		handleClick: handleClick,
		handleClickId: handleClickId,
		input: gamInput,
		setInput: setGamInput,
		dropdownOpen: dropdownOpen,
		setDropdownOpen: setDropdownOpen,
		loader: loader,
		focusHandler: onFocusHandler,
		blurHandler: onBlurHandler,
		view: view
	};

	useEffect(() => {
		if (fetchPermission.current) {
			getGAMAudienceList();
		}
		fetchPermission.current = true;
	}, [gamInput]);

	const getGAMAudienceList = () => {
		setLoader(true);
		Segment.getGAMList(gamInput).then((response) => {
			if (response.status === 200) {
				if (response.data.length > 0) {
					const typeAheadData = response.data.map((item: { listId: string; listName: string }) => {
						return { id: item.listId, value: item.listName };
					});
					setData(typeAheadData);
					setDropdownOpen(true);
				} else {
					setData([]);
					setDropdownOpen(false);
				}
			} else {
				setModalData(failedToLoadAudience);
				setIsModalVisible(true);
			}
			setLoader(false);
		});
	};

	return (
		<React.Fragment>
			<div>
				<p className='help'> Please select the partner(s) to share the segment. </p>
				<p className='help'>
					In case of dynamic segment it will be shared periodically at the update frequency.
				</p>
			</div>

			<div>
				<Checkbox
					className='is-clickable ml-5 mt-5 mr-3'
					value='Clevertap'
					checked={selectedPartners && selectedPartners.includes('Clevertap')}
					onChange={(e) => {
						updateSelectedPartners(e.target.value);
					}}
					disabled={
						!((selectedFields.includes('CpCustomerID') || selectedQueryType === 'Custom') && !view)
					}
				/>
				CleverTap
			</div>

			<div>
				<Checkbox
					className='is-clickable ml-5 mt-5 mr-3'
					value='Firebase'
					checked={selectedPartners && selectedPartners.includes('Firebase')}
					onChange={(e) => {
						updateSelectedPartners(e.target.value);
					}}
					disabled={
						!((selectedFields.includes('CpCustomerID') || selectedQueryType === 'Custom') && !view)
					}
				/>
				Firebase
			</div>

			<div className='is-flex mt-4'>
				<div className='is-flex is-align-items-center'>
					<Checkbox
						className='is-clickable ml-5 mr-3'
						value='GoogleAdManager'
						checked={selectedPartners && selectedPartners.includes('GoogleAdManager')}
						onChange={(e) => {
							updateSelectedPartners(e.target.value);
						}}
						disabled={
							!((selectedFields.includes('AnonymousID') || selectedQueryType === 'Custom') && !view)
						}
					/>
					<span>Google Ad Manager</span>
				</div>

				<div className='ml-4'>
					{selectedPartners.includes('GoogleAdManager') && <TypeAhead {...typeAheadProps} />}
				</div>
			</div>
			<div>
				<Checkbox
					className='is-clickable ml-5 mt-5 mr-3'
					value='Download'
					checked={isDownloadSelected}
					onChange={() => setIsDownloadSelected(!isDownloadSelected)}
					disabled={view}
				/>
				Download
				<ToolTip className='mr-2' direction='bottom'>
					On checking this checkbox, owner will get an email everytime the segment is refreshed and ready
					for download
				</ToolTip>
			</div>
			<Modal data={modalData} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
		</React.Fragment>
	);
};

export default PartnersTab;
