import React from 'react';

interface SectionProps {
	className?: string;
}

const Section: React.FC<SectionProps> = ({ className, children }): JSX.Element => {
	return (
		<React.Fragment>
			<div className={`section ${className || ''}`}>{children}</div>
		</React.Fragment>
	);
};

export default Section;
