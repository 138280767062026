import React from 'react';
import Button from 'components/atoms/Button';
import FormControl from 'components/atoms/FormControl';
import FormField from 'components/atoms/FormField';
import Input from 'components/atoms/Input';
import Label from 'components/atoms/Label';
import { Auth } from 'services';
import { Constants, Helpers } from 'utils';
import { Link } from 'react-router-dom';

interface ModalData {
	title: string;
	content: string;
	path: string;
}

interface ForgotPasswordFormProps {
	className?: string;
	email: string;
	emailErrorMessage?: string;
	updateEmail?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	setModalData: React.Dispatch<React.SetStateAction<ModalData>>;
	setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setEmailErrorMessage: React.Dispatch<React.SetStateAction<string>>;
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
	email,
	updateEmail,
	emailErrorMessage,
	setModalData,
	setIsModalVisible,
	setEmailErrorMessage,
	setIsLoading
}): JSX.Element => {
	const onSubmit = async () => {
		if (Helpers.validateEmail(email)) {
			setIsLoading(true);
			const response = await Auth.forgotPassword(email);
			if (response.status === 200) {
				setModalData(Constants.MODAL_DATA.forgotPasswordSuccess);
			} else if (response.status === 401) {
				setModalData(Constants.MODAL_DATA.invalidCredentials);
			}
			setIsLoading(false);
			setIsModalVisible(true);
		} else {
			if (email === '') {
				setEmailErrorMessage('Email cannot be blank');
			} else {
				setEmailErrorMessage('Invalid email format');
			}
		}
	};

	return (
		<React.Fragment>
			<FormField className='mb-6'>
				<Label className='has-text-grey-dark ml-1 is-small'>Email</Label>
				<FormControl className='input-container'>
					<Input
						className={emailErrorMessage === '' ? '' : 'is-danger'}
						type='text'
						placeholder='Email'
						value={email}
						onChange={updateEmail}
						onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
					/>
				</FormControl>
				<p className={'help has-text-left is-danger'}>{emailErrorMessage}</p>
			</FormField>

			<FormField>
				<FormControl className='is-flex is-justify-content-right'>
					<Link to='/auth/login'>
						<Button className='is-text'>Cancel</Button>
					</Link>
					<Button className='is-link has-background-grey-dark mb-3' onClick={onSubmit}>
						Submit
					</Button>
				</FormControl>
			</FormField>
		</React.Fragment>
	);
};

export default ForgotPasswordForm;
