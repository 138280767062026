import Button from 'components/atoms/Button';
import React, { useEffect, useState } from 'react';
import SubRuleCard from 'components/molecules/SubRuleCard';
import { Interface } from 'interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.css';
import RuleConditionDropdown from '../RuleConditionDropdown';
import { RuleType } from 'interfaces/interface';

interface Rule {
	rules: Array<RuleType>;
	condition: string | null;
}
interface RuleCardProps {
	className?: string;
	ruleList: Interface.RuleType[];
	setRuleList: React.Dispatch<React.SetStateAction<Interface.RuleType[]>>;
	parameterList: string[];
	categorizeParameterList: Interface.CategorizeParameterType[];
	ruleIndex: number;
	ruleGroups: Array<Rule>;
	setRuleGroups: React.Dispatch<React.SetStateAction<Array<Rule>>>;
	havingRuleGroups: Array<Rule>;
	setHavingRuleGroups: React.Dispatch<React.SetStateAction<Array<Rule>>>;
	view: boolean;
}

const RuleCard: React.FC<RuleCardProps> = ({
	ruleList,
	setRuleList,
	parameterList,
	categorizeParameterList,
	ruleIndex,
	ruleGroups,
	setRuleGroups,
	havingRuleGroups,
	setHavingRuleGroups,
	view
}): JSX.Element => {
	const [displayRule, setDisplayRule] = useState(false);
	const [editName, setEditName] = useState(false);
	const [ruleName, setRuleName] = useState<string>('');
	const [isRuleNameBlank, setIsRuleNameBlank] = useState(false);
	const [isRuleNameUnique, setIsRuleNameUnique] = useState(true);
	const [currentValidRuleName, setCurrentValidRuleName] = useState<string>('');

	useEffect(() => {
		setRuleName(ruleList[ruleIndex].name);
	}, [ruleList]);

	const toggleRuleDisplay = () => {
		setDisplayRule(!displayRule);
	};

	const toggleEditName = (ruleName: string | null) => {
		if (ruleName !== null) {
			setCurrentValidRuleName(ruleName);
		}
		setEditName(!editName);
	};

	const deleteRuleCard = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
		e.stopPropagation();
		const copyRuleList = [...ruleList];
		const updatedRuleList = copyRuleList.filter((_, index) => {
			if (index === ruleIndex) {
				return false;
			} else return true;
		});
		setDisplayRule(false);
		setRuleList(updatedRuleList);
	};
	//Having member of rule object is set to true if a compound rule has 'having subrule'
	const addHavingRule = () => {
		const copyRuleList = [...ruleList];
		copyRuleList[ruleIndex].isHaving = true;
		setRuleList(copyRuleList);
	};
	//To remove having subrule from a compound rule, having member of rule object is set to false.
	//The first element of rules array is reserved for having sub-rule.
	//So even if the compound rule does not have having sub-rule, the members of first element subrule is set to either
	//empty string or array of empty string.
	const removeHavingRule = () => {
		const copyRuleList = [...ruleList];
		copyRuleList[ruleIndex].isHaving = false;
		copyRuleList[ruleIndex].subRules[0] = {
			parameter: '',
			apiColumnName: '',
			columnType: '',
			operator: '',
			condition: null,
			value: [''],
			min: '',
			max: ''
		};
		setRuleList(copyRuleList);
	};

	const updateRuleName = (currentName: string) => {
		//Rule name can be saved only if its not blank, unique or without space
		if (!isRuleNameBlank && isRuleNameUnique) {
			const copyRuleList = [...ruleList];
			copyRuleList[ruleIndex].name = currentName;
			setRuleList(copyRuleList);
			toggleEditName(null);

			//Work-area ruleGroup and having-ruleGroup name are updated when rule name changes in rule-area.
			if (currentValidRuleName !== currentName) {
				for (let i = 0; i < ruleGroups.length; i++) {
					for (let j = 0; j < ruleGroups[i].rules.length; j++) {
						if (ruleGroups[i].rules[j].name === currentValidRuleName) {
							const copyRuleGroups = [...ruleGroups];
							copyRuleGroups[i].rules[j].name = currentName;
							setRuleGroups(copyRuleGroups);
						}
					}
				}
				for (let i = 0; i < havingRuleGroups.length; i++) {
					for (let j = 0; j < havingRuleGroups[i].rules.length; j++) {
						if (havingRuleGroups[i].rules[j].name === currentValidRuleName) {
							const copyRuleGroups = [...havingRuleGroups];
							copyRuleGroups[i].rules[j].name = currentName;
							setHavingRuleGroups(copyRuleGroups);
						}
					}
				}
			}
		}
		//If rule name is either blank or not unique then on saving it, the rule name will be set
		//to current valid rule name and variable currentValidRuleName holds its value
		else {
			setEditName(false);
			setRuleName(currentValidRuleName);
			setIsRuleNameBlank(false);
			setIsRuleNameUnique(true);
		}
	};

	const updateLocalRuleName = (currentName: string) => {
		//Removes space from input value which could be injected by copying some space characters and pasting in input field.
		const validRuleName = currentName.replaceAll(' ', '');
		setRuleName(validRuleName);
		if (validRuleName === '') {
			setIsRuleNameBlank(true);
		} else {
			setIsRuleNameBlank(false);
		}
		let isNameUnique = true;
		//Compares the name with all other rules except itself
		//If it matches any other rule's name, then name uniqueness is set to false
		ruleList.forEach((rule, index) => {
			if (validRuleName === rule.name && index != ruleIndex) isNameUnique = false;
		});
		setIsRuleNameUnique(isNameUnique);
	};

	//A function to prevent space character in rule name input field when space key is pressed
	const preventSpaceforRuleInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === ' ') event.preventDefault();
	};

	//On clicking outside of the rule name input, the rule name will be set to current valid rule name
	const inputOnblurHandler = () => {
		setRuleName(currentValidRuleName);
		setEditName(false);
		setIsRuleNameBlank(false);
		setIsRuleNameUnique(true);
	};

	const deleteSubRule = (subRuleIndex: number) => {
		const copyRuleList = [...ruleList];
		const copyCurrentRule = copyRuleList[ruleIndex];
		//If there are more then one non-having subrules and the subrule to be deleted is the last subrule
		//then the condition of the last second subrule is set to null
		if (copyCurrentRule.subRules.length - 1 === subRuleIndex && subRuleIndex > 1) {
			copyRuleList[ruleIndex].subRules[subRuleIndex - 1].condition = null;
		}
		//The subrule to be deleted is filtered out from the subrule array
		copyRuleList[ruleIndex].subRules = copyCurrentRule.subRules.filter((_, index) => {
			if (index === subRuleIndex) {
				return false;
			} else return true;
		});
		setRuleList(copyRuleList);
	};

	const ruleConditionDropdownProps = {
		ruleIndex: ruleIndex,
		ruleList: ruleList,
		setRuleList: setRuleList,
		view: view
	};
	const subRuleProps = {
		parameterList: parameterList,
		categorizeParameterList: categorizeParameterList,
		ruleIndex: ruleIndex,
		ruleList: ruleList,
		setRuleList: setRuleList,
		view: view
	};
	return (
		<React.Fragment>
			<div className='mb-4'>
				{/* Rule header */}
				<div
					onClick={toggleRuleDisplay}
					className='is-clickable is-flex is-justify-content-space-between is-align-items-center px-3 py-1 has-background-grey-lighter'
				>
					<div className='is-flex is-flex-direcion-row'>
						{editName ? (
							<div className='is-flex is-flex-direction-column'>
								<input
									className='input is-inline is-small'
									type='text'
									autoFocus
									value={ruleName}
									onKeyPress={preventSpaceforRuleInput}
									onBlur={inputOnblurHandler}
									onChange={(e) => {
										updateLocalRuleName(e.target.value);
									}}
								></input>
								{isRuleNameBlank && <span className='help is-danger'>Rule name cannot be blank</span>}
								{!isRuleNameUnique && <span className='help is-danger'>Rule name have to be unique</span>}
							</div>
						) : (
							<span className='has-text-weight-bold'>{ruleList[ruleIndex].name}</span>
						)}
						{!view &&
							(editName ? (
								<button
									className='button is-small is-ghost'
									onMouseDown={(e) => {
										e.stopPropagation();
										updateRuleName(ruleName);
									}}
								>
									<FontAwesomeIcon className='is-small' icon={['fas', 'check']} />
								</button>
							) : (
								<Button
									className='is-small is-ghost'
									onClick={(e) => {
										e.stopPropagation();
										toggleEditName(ruleName);
									}}
								>
									<FontAwesomeIcon className='is-small' icon={['fas', 'pencil']} />
								</Button>
							))}
					</div>

					<div>
						{!displayRule && <FontAwesomeIcon icon={['fas', 'angle-down']} />}
						{displayRule && <FontAwesomeIcon icon={['fas', 'angle-up']} />}
						{!view && (
							<FontAwesomeIcon
								className='ml-4'
								onClick={(e) => deleteRuleCard(e)}
								icon={['fas', 'trash-can']}
							/>
						)}
					</div>
				</div>
				{/* Rule content */}
				{displayRule && (
					<div className='rule-card-content'>
						{/* Simple rule */}
						{ruleList[ruleIndex].type === 'simple' && (
							<SubRuleCard {...subRuleProps} subRuleIndex={0}></SubRuleCard>
						)}
						{/* Compound rule */}
						{ruleList[ruleIndex].type === 'compound' && (
							<React.Fragment>
								{ruleList[ruleIndex].subRules
									.filter((_, index) => {
										if (index === 0) return false;
										else return true;
									})
									.map((_, index) => {
										return (
											<React.Fragment key={index}>
												<SubRuleCard {...subRuleProps} subRuleIndex={index + 1}></SubRuleCard>
												{ruleList[ruleIndex].subRules[index + 1].condition && (
													<div className='is-flex is-flex-direction-row is-justify-content-flex-end mb-3'>
														<RuleConditionDropdown
															{...ruleConditionDropdownProps}
															subRuleIndex={index + 1}
														></RuleConditionDropdown>
														{!view && (
															<Button className='is-small mr-3' onClick={() => deleteSubRule(index + 1)}>
																<FontAwesomeIcon icon={['fas', 'trash-can']} />
															</Button>
														)}
													</div>
												)}
											</React.Fragment>
										);
									})}
								{/* Subrule condition */}
								{!view && (
									<div className='add-button-container is-flex is-justify-content-flex-end pb-4 pr-4 pt-4'>
										<RuleConditionDropdown {...ruleConditionDropdownProps}></RuleConditionDropdown>
										{ruleList[ruleIndex].subRules.length > 1 && (
											<Button
												className='is-small'
												onClick={() => deleteSubRule(ruleList[ruleIndex].subRules.length - 1)}
											>
												<FontAwesomeIcon icon={['fas', 'trash-can']} />
											</Button>
										)}
									</div>
								)}
								{/* Having subrule */}
								<div className='having-container'>
									<div className='has-background-white-ter py-1 px-3'>
										<span className='has-text-weight-bold mr-3'>Having</span>
										{!ruleList[ruleIndex].isHaving && !view && (
											<FontAwesomeIcon
												className='is-clickable'
												icon={['fas', 'plus']}
												onClick={addHavingRule}
											/>
										)}
										{ruleList[ruleIndex].isHaving && !view && (
											<FontAwesomeIcon
												className='is-clickable'
												icon={['fas', 'trash-can']}
												onClick={removeHavingRule}
											/>
										)}
									</div>
									{ruleList[ruleIndex].isHaving && (
										<SubRuleCard {...subRuleProps} subRuleIndex={0} havingShown={true}></SubRuleCard>
									)}
								</div>
							</React.Fragment>
						)}
					</div>
				)}
			</div>
		</React.Fragment>
	);
};

export default RuleCard;
