import React from 'react';

interface FooterProps {
	className?: string;
}

const Footer: React.FC<FooterProps> = ({ className, children }): JSX.Element => {
	return (
		<React.Fragment>
			<div className={`hero-foot ${className || ''}`}>{children}</div>
		</React.Fragment>
	);
};

export default Footer;
