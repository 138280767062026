import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterItem from 'components/atoms/FilterItem';
import './style.css';
interface KeyString {
	[key: string]: string;
}

interface FilterMenuListProps {
	className?: string;
	filterTitle: string;
	filters: Array<KeyString>;
	selectedFilter: React.MutableRefObject<string[]>;
}

const FilterMenuList: React.FC<FilterMenuListProps> = ({
	filterTitle,
	filters,
	selectedFilter
}): JSX.Element => {
	const [displayFitlers, setDisplayFilters] = useState(true);
	const toggleDisplay = () => {
		setDisplayFilters(!displayFitlers);
	};
	const updateSelectedFilter = (currentFilter: string) => {
		if (selectedFilter.current.includes(currentFilter)) {
			const selected = selectedFilter.current.filter((filter) => {
				if (filter === currentFilter) return false;
				else return true;
			});
			selectedFilter.current = selected;
		} else {
			selectedFilter.current.push(currentFilter);
		}
	};
	return (
		<React.Fragment>
			<div
				className='menu-label has-text-weight-bold has-text-black is-clickable is-flex is-justify-content-space-between is-align-items-center'
				onClick={() => {
					toggleDisplay();
				}}
			>
				<span className='mr-3'>{filterTitle}</span>
				{!displayFitlers && <FontAwesomeIcon icon={['fas', 'angle-down']} />}
				{displayFitlers && <FontAwesomeIcon icon={['fas', 'angle-up']} />}
			</div>
			{displayFitlers && (
				<ul className='menu-list filter-list'>
					{filters.map((filter, index) => {
						return (
							<FilterItem
								label={filter.label}
								value={filter.value}
								updateSelectedFilter={updateSelectedFilter}
								key={index}
							/>
						);
					})}
				</ul>
			)}
		</React.Fragment>
	);
};

export default FilterMenuList;
