import React from 'react';
import Checkbox from 'components/atoms/Checkbox';

interface FilterItemProps {
	className?: string;
	label: string;
	value: string;
	updateSelectedFilter: (filter: string) => void;
}

const FilterItem: React.FC<FilterItemProps> = ({
	label,
	value,
	updateSelectedFilter
}): JSX.Element => {
	return (
		<React.Fragment>
			<li>
				<label className='checkbox'>
					<Checkbox
						className='mr-2'
						value={value}
						onChange={() => {
							updateSelectedFilter(value);
						}}
					/>
					{label}
				</label>
			</li>
		</React.Fragment>
	);
};

export default FilterItem;
