import React from 'react';
import DropdownItem from 'components/atoms/DropdownItem';
import { Constants } from 'utils';

interface ModalData {
	title: string;
	content: string;
	path: string;
}

interface DropDownProps {
	setModalData: React.Dispatch<React.SetStateAction<ModalData>>;
	setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setModalAction: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
	resetPassword?: () => void;
	unlockUser?: () => void;
}

const UsersMenu: React.FC<DropDownProps> = ({
	setModalData,
	setIsModalVisible,
	setModalAction,
	resetPassword,
	unlockUser
}): JSX.Element => {
	const { confirmResetPassword, confirmUserUnlock } = Constants.MODAL_DATA;

	const resetPasswordModalTrigger = () => {
		setModalData(confirmResetPassword);
		setIsModalVisible(true);
		setModalAction(() => resetPassword);
	};

	const unlockUserModalTrigger = () => {
		setModalData(confirmUserUnlock);
		setIsModalVisible(true);
		setModalAction(() => unlockUser);
	};
	return (
		<React.Fragment>
			<DropdownItem onClick={resetPasswordModalTrigger}>Reset Password</DropdownItem>
			<DropdownItem onClick={unlockUserModalTrigger}>Unlock User</DropdownItem>
		</React.Fragment>
	);
};

export default UsersMenu;
