import React from 'react';
import { Navigate } from 'react-router-dom';
import { AuthUtils } from 'utils';

interface ProtectedRouteProps {
	adminPage: boolean;
	creatorPath?: string;
	commonOutlet?: JSX.Element;
	adminOutlet?: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
	adminPage,
	creatorPath,
	commonOutlet,
	adminOutlet
}): JSX.Element => {
	return (
		<React.Fragment>
			{!AuthUtils.isLoggedIn() && <Navigate to='/auth/login' />}
			{AuthUtils.isLoggedIn() && !adminPage && commonOutlet}
			{AuthUtils.isLoggedIn() && adminPage && AuthUtils.isAdmin() && adminOutlet}
			{AuthUtils.isLoggedIn() && adminPage && !AuthUtils.isAdmin() && (
				<Navigate to={{ pathname: creatorPath }} />
			)}
		</React.Fragment>
	);
};

export default ProtectedRoute;
