import React from 'react';

interface InputProps {
	className?: string;
	placeholder?: string;
	type: string;
	value?: string;
	name?: string;
	id?: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	autoComplete?: string;
	disabled?: boolean;
}

const Input: React.FC<InputProps> = ({
	className,
	placeholder,
	type,
	value,
	name,
	id,
	onChange,
	onKeyDown,
	onFocus,
	onBlur,
	onInput,
	autoComplete,
	disabled
}): JSX.Element => {
	return (
		<React.Fragment>
			<input
				className={`input ${className || ''}`}
				type={type}
				value={value}
				name={name}
				id={id}
				onChange={onChange}
				onKeyDown={onKeyDown}
				placeholder={placeholder}
				onFocus={onFocus}
				onBlur={onBlur}
				onInput={onInput}
				autoComplete={autoComplete}
				disabled={disabled}
			/>
		</React.Fragment>
	);
};

export default Input;
