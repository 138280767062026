import React from 'react';

interface ColumnProps {
	className?: string;
}

const Column: React.FC<ColumnProps> = ({ className, children }): JSX.Element => {
	return (
		<React.Fragment>
			<div className={`column ${className || ''}`}>{children}</div>
		</React.Fragment>
	);
};

export default Column;
