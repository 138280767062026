import React from 'react';

interface ColumnGroupProps {
	className?: string;
}

const ColumnGroup: React.FC<ColumnGroupProps> = ({ className, children }): JSX.Element => {
	return (
		<React.Fragment>
			<div className={`columns${className || ''}`}>{children}</div>
		</React.Fragment>
	);
};

export default ColumnGroup;
