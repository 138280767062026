import React from 'react';

interface TextAreaProps {
	className?: string;
	placeholder?: string;
	value?: string;
	name?: string;
	onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
	disabled?: boolean;
}

const TextArea: React.FC<TextAreaProps> = ({
	className,
	placeholder,
	value,
	name,
	onChange,
	disabled
}): JSX.Element => {
	return (
		<React.Fragment>
			<textarea
				className={`textarea ${className || ''}`}
				value={value}
				name={name}
				onChange={onChange}
				placeholder={placeholder}
				disabled={disabled}
			/>
		</React.Fragment>
	);
};

export default TextArea;
