import React from 'react';

interface FormFieldProps {
	className?: string;
}

const FormField: React.FC<FormFieldProps> = ({ className, children }): JSX.Element => {
	return (
		<React.Fragment>
			<div className={`field ${className || ''}`}>{children}</div>
		</React.Fragment>
	);
};

export default FormField;
