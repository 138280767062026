import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownItem from 'components/atoms/DropdownItem';
import { Interface } from 'interfaces';

interface RuleConditionDropdownProps {
	className?: string;
	ruleIndex: number;
	ruleList: Interface.RuleType[];
	setRuleList: React.Dispatch<React.SetStateAction<Interface.RuleType[]>>;
	subRuleIndex?: number;
	view: boolean;
}

const RuleConditionDropdown: React.FC<RuleConditionDropdownProps> = ({
	className,
	ruleIndex,
	ruleList,
	setRuleList,
	subRuleIndex,
	view
}) => {
	const [activeDropdown, setActiveDropdown] = useState(false);
	const toggleDropdown = () => {
		if (subRuleIndex) {
			setActiveDropdown(!activeDropdown);
		} else {
			if (ruleList[ruleIndex].subRules.length > 1) {
				setActiveDropdown(!activeDropdown);
			} else {
				addRule();
			}
		}
	};
	const addRule = () => {
		const rulePart: Interface.subRuleType = {
			parameter: '',
			apiColumnName: '',
			columnType: '',
			operator: '',
			condition: null,
			value: [''],
			min: '',
			max: ''
		};
		const copyRuleList = [...ruleList];
		copyRuleList[ruleIndex].subRules.push(rulePart);
		setRuleList(copyRuleList);
	};
	const updateCondition = (condition: string, index: number) => {
		const copyRuleList = [...ruleList];
		copyRuleList[ruleIndex].subRules[index].condition = condition;
		setRuleList(copyRuleList);
	};

	const changeCondition = (condition: string) => {
		if (subRuleIndex) {
			updateCondition(condition, subRuleIndex);
		} else {
			updateCondition(condition, ruleList[ruleIndex].subRules.length - 1);
			addRule();
		}
		setActiveDropdown(false);
	};

	return (
		<div className={`dropdown ${className ? className : ''} ${activeDropdown ? 'is-active' : ''}`}>
			<div className='dropdown-trigger'>
				<button
					className={`button is-small mr-3 ${
						!(subRuleIndex && ruleList[ruleIndex].subRules[subRuleIndex].condition)
							? 'is-outlined'
							: 'is-dark'
					}`}
					aria-haspopup='true'
					aria-controls='dropdown-menu'
					onClick={() => toggleDropdown()}
					disabled={view}
				>
					{subRuleIndex && ruleList[ruleIndex].subRules[subRuleIndex].condition ? (
						ruleList[ruleIndex].subRules[subRuleIndex].condition
					) : (
						<React.Fragment>
							<FontAwesomeIcon icon={['fas', 'plus']} />
						</React.Fragment>
					)}
				</button>
			</div>
			<div className='dropdown-menu' id='dropdown-menu' role='menu'>
				<div className='dropdown-content'>
					<DropdownItem onClick={() => changeCondition('OR')}>OR</DropdownItem>
					<DropdownItem onClick={() => changeCondition('AND')}>AND</DropdownItem>
				</div>
			</div>
		</div>
	);
};

export default RuleConditionDropdown;
