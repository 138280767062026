import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/atoms/Button';
import DatePickerCustomMenu from 'components/molecules/DatePickerCustomMenu';
import DatePickerCalendarMenu from 'components/molecules/DatePickerCalendarMenu';
import './styles.css';
import { Helpers } from 'utils';

interface DatePickerProps {
	presentDate: string;
	setStartDate: React.Dispatch<React.SetStateAction<string>>;
	setEndDate: React.Dispatch<React.SetStateAction<string>>;
	durationValue: number;
	setDurationValue: React.Dispatch<React.SetStateAction<number>>;
	durationUnit: string;
	setDurationUnit: React.Dispatch<React.SetStateAction<string>>;
	dateOffsetValue: number;
	setDateOffsetValue: React.Dispatch<React.SetStateAction<number>>;
	fromValue: string;
	setFromValue: React.Dispatch<React.SetStateAction<string>>;
	toValue: string;
	setToValue: React.Dispatch<React.SetStateAction<string>>;
	isRangeValid: boolean;
	setIsRangeValid: React.Dispatch<React.SetStateAction<boolean>>;
	beforeValue: string;
	setBeforeValue: React.Dispatch<React.SetStateAction<string>>;
	afterValue: string;
	setAfterValue: React.Dispatch<React.SetStateAction<string>>;
	onValue: string;
	setOnValue: React.Dispatch<React.SetStateAction<string>>;
	datePickerDropdownValue: string;
	setDatePickerDropdownValue: React.Dispatch<React.SetStateAction<string>>;
	selectedQueryType: string;
	refreshDatePickerDropdownValue: () => void;
	isSegmentDynamic: boolean;
	setIsSegmentDynamic: React.Dispatch<React.SetStateAction<boolean>>;
	view: boolean;
	setRefreshFrequencyInput: React.Dispatch<React.SetStateAction<string>>;
}
const DatePicker: React.FC<DatePickerProps> = ({
	presentDate,
	setStartDate,
	setEndDate,
	durationValue,
	setDurationValue,
	durationUnit,
	setDurationUnit,
	dateOffsetValue,
	setDateOffsetValue,
	fromValue,
	setFromValue,
	toValue,
	setToValue,
	isRangeValid,
	setIsRangeValid,
	beforeValue,
	setBeforeValue,
	afterValue,
	setAfterValue,
	onValue,
	setOnValue,
	datePickerDropdownValue,
	setDatePickerDropdownValue,
	isSegmentDynamic,
	setIsSegmentDynamic,
	view,
	selectedQueryType,
	refreshDatePickerDropdownValue,
	setRefreshFrequencyInput
}): JSX.Element => {
	const firstDate = '2000-01-01';
	const lastDate = '2099-12-31';
	const [activeTab, setActiveTab] = useState(selectedQueryType === 'Simple' ? 'Duration' : 'Date');
	const [secondaryActiveTab, setSecondaryActiveTab] = useState('BETWEEN');
	const [isDropdownVisible, setIsDropdownVisible] = useState(false);
	const { durationDropdownValueFormatter, offsetDropdownValueFormatter } = Helpers;

	const onNext = () => {
		if (activeTab === 'Duration') {
			setActiveTab('Offset');
		} else if (activeTab === 'Offset') {
			setActiveTab('Date');
		} else if (activeTab === 'Date') {
			toggleDropdown();
		}
	};

	const onBack = () => {
		if (activeTab === 'Date') {
			setActiveTab('Offset');
		} else if (activeTab === 'Offset') {
			setActiveTab('Duration');
		} else if (activeTab === 'Duration') {
			toggleDropdown();
		}
	};

	const restrictDatePickerValueDisplay = () => {
		const durationDropdownValue = durationDropdownValueFormatter(durationValue, durationUnit);
		const offsetDropdownValue = offsetDropdownValueFormatter(durationValue, dateOffsetValue);

		if (
			fromValue == '' &&
			toValue === '' &&
			beforeValue === '' &&
			afterValue === '' &&
			onValue === ''
		)
			return `${durationDropdownValue}${offsetDropdownValue}`;
		else if (secondaryActiveTab === 'BETWEEN') {
			if (fromValue === '' || toValue === '') return `${durationDropdownValue}${offsetDropdownValue}`;
			else return `${durationDropdownValue}${offsetDropdownValue}from ${fromValue} to ${toValue}`;
		} else if (secondaryActiveTab === 'BEFORE') {
			if (beforeValue === '') return `${durationDropdownValue}${offsetDropdownValue}`;
			else return `${durationDropdownValue}${offsetDropdownValue}before ${beforeValue}`;
		} else if (secondaryActiveTab === 'AFTER') {
			if (afterValue === '') return `${durationDropdownValue}${offsetDropdownValue}`;
			else return `${durationDropdownValue}${offsetDropdownValue}after ${afterValue}`;
		} else {
			if (onValue === '') return `${durationDropdownValue}${offsetDropdownValue}`;
			else return `${durationDropdownValue}${offsetDropdownValue}on ${onValue}`;
		}
	};

	const setDuration = () => {
		// if Active tab is Duration,
		// Start date would be set on the basis of the number of days/weeks/months/quarters/years selected
		if (activeTab === 'Duration') {
			let modifiedStartDate = new Date();
			modifiedStartDate = new Date(
				modifiedStartDate.setDate(modifiedStartDate.getDate() - durationValue)
			);
			setStartDate(modifiedStartDate.toISOString().substring(0, 10));
			setEndDate(new Date().toISOString().substring(0, 10));
			if (durationValue === 0) {
				setIsSegmentDynamic(false);
			} else {
				setIsSegmentDynamic(true);
			}
		}
		// if Active tab is Offset,
		// Start date and End date would be set on the basis of the number of days selected
		else if (activeTab === 'Offset') {
			let modifiedStartDate = new Date();
			modifiedStartDate = new Date(
				modifiedStartDate.setDate(modifiedStartDate.getDate() - dateOffsetValue)
			);
			setStartDate(modifiedStartDate.toISOString().substring(0, 10));
			setEndDate(modifiedStartDate.toISOString().substring(0, 10));
		}
		// if Active tab is Date and secondary tab is Between,
		// Start date and End date would be set on the basis of from and to dates selected
		else if (activeTab === 'Date' && secondaryActiveTab === 'BETWEEN') {
			//End date cannot be less than Start date
			if (toValue < fromValue) {
				setIsRangeValid(false);
			} else {
				setIsRangeValid(true);
				setStartDate(fromValue);
				setEndDate(toValue);
				setBeforeValue('');
				setAfterValue('');
				setOnValue('');
				setDatePickerDropdownValue(restrictDatePickerValueDisplay());
				//If End date is of the future date, the segment will be a DYNAMIC segment
				if (toValue > presentDate) {
					setIsSegmentDynamic(true);
				} else if (toValue === '' && fromValue === '') {
					if (isSegmentDynamic) {
						setIsSegmentDynamic(true);
					} else {
						setIsSegmentDynamic(false);
					}
				} else if (toValue <= presentDate) {
					setIsSegmentDynamic(false);
				}
				toggleDropdown();
			}
		}
		// if Active tab is Date and secondary tab is Before,
		// Start date would be the oldest date (01-01-2000) and End date would be the before date
		else if (activeTab === 'Date' && secondaryActiveTab === 'BEFORE') {
			setStartDate(firstDate);
			setEndDate(beforeValue);
			setFromValue('');
			setToValue('');
			setAfterValue('');
			setOnValue('');
			setDatePickerDropdownValue(restrictDatePickerValueDisplay());
			//If the End date is of the future date, the segment will be a DYNAMIC segment
			if (beforeValue > presentDate) {
				setIsSegmentDynamic(true);
			} else if (beforeValue === '') {
				if (isSegmentDynamic) {
					setIsSegmentDynamic(true);
				} else {
					setIsSegmentDynamic(false);
				}
			} else if (beforeValue <= presentDate) {
				setIsSegmentDynamic(false);
			}
			toggleDropdown();
		}
		// if Active tab is Date and secondary tab is After,
		// Start date would be the after date and End date would be the last date (31-12-2099)
		else if (activeTab === 'Date' && secondaryActiveTab === 'AFTER') {
			setStartDate(afterValue);
			setEndDate(lastDate);
			setFromValue('');
			setToValue('');
			setBeforeValue('');
			setOnValue('');
			setDatePickerDropdownValue(restrictDatePickerValueDisplay());
			//If the date chosen is After date, then segment will always be a DYNAMIC segment
			setIsSegmentDynamic(true);
			toggleDropdown();
		}
		// if Active tab is Date and secondary tab is On,
		// Start date and End date would be the on date
		else if (activeTab === 'Date' && secondaryActiveTab === 'ON') {
			setStartDate(onValue);
			setEndDate(onValue);
			setFromValue('');
			setToValue('');
			setBeforeValue('');
			setAfterValue('');
			setDatePickerDropdownValue(restrictDatePickerValueDisplay());
			//This will always be a one-time segment, hence it will always be a STATIC segment
			setIsSegmentDynamic(false);
			toggleDropdown();
		} else {
			setStartDate(presentDate);
			setEndDate(presentDate);
		}
	};

	useEffect(() => {
		if (!isSegmentDynamic) {
			setRefreshFrequencyInput('');
		}
	}, [isSegmentDynamic]);

	const datePickerCustomMenuProps = {
		activeTab: activeTab,
		durationUnit: durationUnit,
		setDurationUnit: setDurationUnit,
		durationValue: durationValue,
		setDurationValue: setDurationValue,
		dateOffsetValue: dateOffsetValue,
		setDateOffsetValue: setDateOffsetValue
	};

	const datePickerCalendarMenuProps = {
		activeTab: secondaryActiveTab,
		setActiveTab: setSecondaryActiveTab,
		fromValue: fromValue,
		setFromValue: setFromValue,
		toValue: toValue,
		setToValue: setToValue,
		isRangeValid: isRangeValid,
		beforeValue: beforeValue,
		setBeforeValue: setBeforeValue,
		afterValue: afterValue,
		setAfterValue: setAfterValue,
		onValue: onValue,
		setOnValue: setOnValue
	};

	const numberArray: Array<number> = [];
	for (let i = 1; i <= 365; i++) {
		numberArray.push(i);
	}

	const toggleDropdown = () => {
		selectedQueryType === 'Custom' ? setActiveTab('Date') : setActiveTab('Duration');
		setIsDropdownVisible(!isDropdownVisible);
	};

	return (
		<div className={`dropdown ${isDropdownVisible === false ? '' : 'is-active'}`}>
			<div className='dropdown-trigger'>
				<Button
					className='max-width'
					aria-haspopup='true'
					aria-controls='dropdown-menu'
					onClick={() => toggleDropdown()}
					disabled={view}
				>
					<span>
						{datePickerDropdownValue === '' ? 'Select the date or duration' : datePickerDropdownValue}
					</span>
					<span className='icon is-small'>
						<FontAwesomeIcon icon={['fas', 'angle-down']} />
					</span>
				</Button>
			</div>
			<div className='dropdown-menu parent-menu' id='dropdown-menu' role='menu'>
				<div className='dropdown-content'>
					<div className='ml-3 mb-5'>
						{selectedQueryType === 'Simple' && (
							<FontAwesomeIcon
								icon={['fas', 'angle-left']}
								className='mr-3 is-clickable'
								onClick={() => onBack()}
							/>
						)}
						<span className='has-text-link'>Select {activeTab}</span>

						<span
							className='icon is-small is-clickable is-pulled-right mt-1 mr-4'
							onClick={() => refreshDatePickerDropdownValue()}
						>
							<FontAwesomeIcon icon={['fas', 'refresh']} />
						</span>
					</div>

					{selectedQueryType === 'Simple' ? (
						<div>
							{(activeTab === 'Duration' || activeTab === 'Offset') && (
								<DatePickerCustomMenu {...datePickerCustomMenuProps} />
							)}
							{activeTab === 'Date' && <DatePickerCalendarMenu {...datePickerCalendarMenuProps} />}
						</div>
					) : (
						<DatePickerCalendarMenu {...datePickerCalendarMenuProps} />
					)}

					<div className='is-flex is-justify-content-center mt-5 mb-2'>
						{activeTab != 'Date' ? (
							<>
								<Button className='ml-3' onClick={() => onNext()}>
									Skip
								</Button>

								<Button
									className='is-link ml-3'
									onClick={() => {
										onNext(), setDuration();
									}}
								>
									Apply & Next
								</Button>
							</>
						) : (
							<Button className='ml-3' onClick={() => setDuration()}>
								Skip
							</Button>
						)}
						{activeTab === 'Date' && durationValue === 0 && dateOffsetValue === 0 && onValue === '' && beforeValue === '' && afterValue === '' && fromValue === '' && toValue === '' ? ( // prettier-ignore
							<Button disabled className='is-link ml-3' onClick={() => setDuration()}>
								Apply
							</Button>
						) : (
							activeTab === 'Date' && (
								<Button className='is-link ml-3' onClick={() => setDuration()}>
									Apply
								</Button>
							)
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default DatePicker;
