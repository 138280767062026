import React from 'react';

interface BoxProps {
	className?: string;
}

const Box: React.FC<BoxProps> = ({ className, children }): JSX.Element => {
	return (
		<React.Fragment>
			<div className={`box ${className || ''}`}>{children}</div>
		</React.Fragment>
	);
};

export default Box;
