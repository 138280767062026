import React from 'react';

interface ChipProps {
	className?: string;
	handleDelete: (e: string) => void;
	label: string;
	view?: boolean;
}

const RefreshFrequency: React.FC<ChipProps> = ({
	children,
	handleDelete,
	label,
	className,
	view
}): JSX.Element => {
	return (
		<span className={`tag mr-2 ${className}`}>
			{children}
			{!view && <button className='delete is-small' onClick={() => handleDelete(label)}></button>}
		</span>
	);
};

export default RefreshFrequency;
