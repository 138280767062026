import Hyperlink from 'components/atoms/Hyperlink';
import React from 'react';
import './style.css';

const PageNotFound = () => {
	return (
		<div className='page-not-found-container'>
			404 - Page not found
			<div>
				<Hyperlink to='/'>Go back to Home</Hyperlink>
			</div>
		</div>
	);
};

export default PageNotFound;
