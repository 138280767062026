import axios from 'axios';

const getAllSegments = (
	params: any //eslint-disable-line
) => {
	const url = '/api/v1/segments/';
	return axios
		.get(url, {
			params: params
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response) {
				return error.response;
			}
		});
};

const getSegmentFilters = () => {
	const url = '/api/v1/segments/filters/';
	return axios
		.get(url)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response) {
				return error.response;
			}
		});
};

const getParameterValues = (parameter: string, value: string) => {
	const url = `/api/v1/parameters/${parameter}`;
	const params = {
		value: value === '' ? undefined : value,
		limit: 200
	};
	return axios
		.get(url, {
			params: params
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response) {
				return error.response;
			}
		});
};

const deleteSegments = (segmentIds: string[]) => {
	const url = '/api/v1/segments/bulk/delete';
	const params = {
		segmentIds: segmentIds
	};
	return axios
		.post(url, params)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response) {
				return error.response;
			}
		});
};

const createSegment = async (
	name: string,
	description: string,
	startDate: string,
	endDate: string,
	updateFrequency: string | null,
	updateFrequencyTimeUnit: string | null,
	access: string,
	sharedWithUsers: string[],
	dateOffset: number,
	timeFrameFrequency: number,
	timeFrameUnit: string,
	isCustomQuery: boolean,
	customQuery: string,
	sharedWithPartners: string[],
	fields: string[],
	toDownload: boolean,
	whereAreaQuery: string,
	havingAreaQuery: string,
	ruleGroup: {
		ruleName: string;
		condition: string | null;
		aggregator: string | null | undefined;
		aggregatorField: string | null;
		aggregatorCondition: string | null;
	}[],
	subscriptionStatus: string,
	gamListId: string
) => {
	const url = '/api/v1/segments/';
	const payload = {
		name: name,
		description: description,
		startDate: startDate,
		endDate: endDate,
		updateFrequency: updateFrequency,
		updateFrequencyTimeUnit: updateFrequencyTimeUnit,
		access: access,
		sharedWithUsers: sharedWithUsers,
		dateOffset: dateOffset,
		timeFrameFrequency: timeFrameFrequency,
		timeFrameUnit: timeFrameUnit,
		isCustomQuery: isCustomQuery,
		customQuery: customQuery,
		sharedWithPartners: sharedWithPartners,
		fields: fields,
		toDownload: toDownload,
		whereAreaQuery: whereAreaQuery,
		havingAreaQuery: havingAreaQuery,
		ruleGroup: ruleGroup,
		subscriptionStatus: subscriptionStatus,
		gamListId: sharedWithPartners.includes('GoogleAdManager') ? gamListId : ''
	};
	return axios
		.post(url, payload)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response) {
				return error.response;
			}
		});
};

const downloadSegment = (id: string) => {
	const url = `/api/v1/segments/download/${id}`;
	return axios
		.get(url)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response) {
				return error.response;
			}
		});
};

const exportSegment = (id: string, partner: string) => {
	const url = '/api/v1/segments/exports';
	const params = {
		sharedPartner: partner,
		segmentId: id
	};
	return axios
		.get(url, {
			params: params
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response) {
				return error.response;
			}
		});
};

const getSegmentById = (id: string) => {
	const url = `/api/v1/segments/${id}`;

	return axios
		.get(url)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response) {
				return error.response;
			}
		});
};

const updateSegment = async (
	segmentId: string,
	name?: string,
	description?: string,
	startDate?: string,
	endDate?: string,
	updateFrequency?: string | null,
	updateFrequencyTimeUnit?: string | null,
	access?: string,
	sharedWithUsers?: string[],
	dateOffset?: number,
	timeFrameFrequency?: number,
	timeFrameUnit?: string,
	isCustomQuery?: boolean,
	customQuery?: string,
	sharedWithPartners?: string[],
	fields?: string[],
	toDownload?: boolean,
	whereAreaQuery?: string,
	havingAreaQuery?: string,
	ruleGroup?: {
		ruleName: string;
		condition: string | null;
		aggregator: string | null | undefined;
		aggregatorField: string | null;
		aggregatorCondition: string | null;
	}[],
	subscriptionStatus?: string,
	gamListId?: string,
	listName?: string
) => {
	const url = `/api/v1/segments/${segmentId}`;
	const payload = {
		name: name,
		description: description,
		startDate: startDate,
		endDate: endDate,
		updateFrequency: updateFrequency,
		updateFrequencyTimeUnit: updateFrequencyTimeUnit,
		access: access,
		sharedWithUsers: sharedWithUsers,
		dateOffset: dateOffset,
		timeFrameFrequency: timeFrameFrequency,
		timeFrameUnit: timeFrameUnit,
		isCustomQuery: isCustomQuery,
		customQuery: customQuery,
		sharedWithPartners: sharedWithPartners,
		fields: fields,
		toDownload: toDownload,
		whereAreaQuery: whereAreaQuery,
		havingAreaQuery: havingAreaQuery,
		ruleGroup: ruleGroup,
		subscriptionStatus: subscriptionStatus,
		gamListId: sharedWithPartners?.includes('GoogleAdManager') ? gamListId : '',
		listName: listName
	};
	return axios
		.put(url, payload)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response) {
				return error.response;
			}
		});
};

const getGAMList = async (name?: string | undefined) => {
	const url = '/api/v1/segments/gam';
	return axios
		.get(url, {
			params: {
				name: name
			}
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response) {
				return error.response;
			}
		});
};

export {
	getAllSegments,
	getSegmentFilters,
	getParameterValues,
	deleteSegments,
	createSegment,
	downloadSegment,
	exportSegment,
	getSegmentById,
	updateSegment,
	getGAMList
};
