import React from 'react';

interface HeadingProps {
	className?: string;
}

const Heading: React.FC<HeadingProps> = ({ className, children }): JSX.Element => {
	return (
		<React.Fragment>
			<div className={className || ''}>{children}</div>
		</React.Fragment>
	);
};

export default Heading;
