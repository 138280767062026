import axios from 'axios';

const loginAuth = async (email: string, password: string) => {
	return axios
		.post('/api/v1/auth/login', {
			email: email,
			password: password
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response) {
				return error.response;
			}
		});
};

const forgotPassword = async (email: string) => {
	return axios
		.post('/api/v1/auth/forgot', {
			email: email
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response) {
				return error.response;
			}
		});
};

const resetPassword = async (password: string, token: string) => {
	return axios
		.put('/api/v1/auth/reset', {
			password: password,
			token: token
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response) {
				return error.response;
			}
		});
};

const logOut = async () => {
	return axios
		.get('/api/v1/auth/logout')
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response) {
				return error.response;
			}
		});
};

export { forgotPassword, loginAuth, resetPassword, logOut };
