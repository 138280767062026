import React, { useEffect, useState } from 'react';
import { getUsersList } from 'services/user.services';
import Chip from 'components/atoms/Chip';
import TypeAhead from 'components/molecules/TypeAhead';
import { PropsInterface } from 'interfaces';
import { Constants } from 'utils';
import Modal from 'components/molecules/Modal';

const TypeAheadUserEmailChips: React.FC<PropsInterface.TypeAheadUserEmailChipsProps> = ({
	userData,
	setUserData,
	userId,
	setUserId,
	userEmailChips,
	setUserEmailChips,
	view
}): JSX.Element => {
	const [input, setInput] = useState('');
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const [loader, setLoader] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const { unknownError, failedToFetchUsersList } = Constants.MODAL_DATA;
	const [modalData, setModalData] = useState(unknownError);

	useEffect(() => {
		if (input.length > 2) {
			getUserEmailList();
		} else {
			setDropdownOpen(false);
		}
	}, [input]);

	const handleClick = (text: string) => {
		setInput('');
		if (!userEmailChips.includes(text)) {
			setUserEmailChips([...userEmailChips, text]);
		}
	};

	const handleClickId = (text: string) => {
		if (!userId.includes(text)) {
			setUserId([...userId, text]);
		}
	};

	const handleDelete = (text: string) => {
		let index1 = 0;
		const copyChips = [...userEmailChips];
		const updatedChips = copyChips.filter((item, index) => {
			if (text === item) {
				index1 = index;
				return false;
			}
			return true;
		});
		setUserEmailChips(updatedChips);

		const copyId = [...userId];
		const updatedIds = copyId.filter((item, index) => {
			return !(index === index1);
		});
		setUserId(updatedIds);
	};

	const getUserEmailList = () => {
		setLoader(true);
		setUserData([]);
		setDropdownOpen(false);
		getUsersList(1, input, true).then((response) => {
			if (response.status === 200) {
				const userDetails = response.data.data.map(
					(
						data: any //eslint-disable-line
					) => {
						return data;
					}
				);
				const userInfo = userDetails.map((item: { id: string; email: string }) => {
					return { id: item.id, value: item.email };
				});
				setUserData(userInfo);
				if (userInfo.length > 0) {
					setDropdownOpen(true);
				}
			} else {
				setModalData(failedToFetchUsersList);
				setIsModalVisible(true);
			}
			setLoader(false);
		});
	};

	const onFocusHandler = () => {
		if (input.length > 2) {
			getUserEmailList();
		}
	};

	const typeAheadProps = {
		handleClick: handleClick,
		inputType: 'email',
		input: input,
		setInput: setInput,
		dropdownOpen: dropdownOpen,
		setDropdownOpen: setDropdownOpen,
		focusHandler: onFocusHandler,
		data: userData,
		loader: loader,
		handleClickId: handleClickId,
		view: view
	};

	return (
		<React.Fragment>
			<TypeAhead {...typeAheadProps} />
			<div className='tags mt-2'>
				{userEmailChips &&
					userEmailChips.map((chip: string) => (
						<Chip key={chip} handleDelete={handleDelete} label={chip} view={view}>
							{chip}
						</Chip>
					))}
			</div>
			<Modal data={modalData} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
		</React.Fragment>
	);
};

export default TypeAheadUserEmailChips;
