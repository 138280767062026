import React from 'react';

interface TabsProps {
	activeTab: string;
	setActiveTab: React.Dispatch<React.SetStateAction<string>>;
	tabs: Array<{ name: string; displayName: string }>;
}

const Tabs: React.FC<TabsProps> = ({ activeTab, setActiveTab, tabs }) => {
	return (
		<div className='tabs'>
			<ul>
				{tabs.map((tab, index) => (
					<li
						key={index}
						className={activeTab === tab.name ? 'is-active' : ''}
						onClick={() => setActiveTab(tab.name)}
					>
						<a className='is-size-6'>{tab.displayName}</a>
					</li>
				))}
			</ul>
		</div>
	);
};

export default Tabs;
