import React from 'react';
import Checkbox from 'components/atoms/Checkbox';

interface FieldsTabProps {
	className?: string;
	selectedFields: string[];
	setSelectedFields: React.Dispatch<React.SetStateAction<string[]>>;
	selectedPartners: string[];
	view: boolean;
}

const FieldsTab: React.FC<FieldsTabProps> = ({
	selectedFields,
	setSelectedFields,
	selectedPartners,
	view
}): JSX.Element => {
	const updateSelectedFields = (field: string) => {
		selectedPartners.splice(0);
		if (selectedFields.includes(field)) {
			const copySelectedFields = [...selectedFields];
			setSelectedFields(copySelectedFields.filter((item) => !(field === item)));
		} else {
			setSelectedFields([...selectedFields, field]);
		}
	};

	return (
		<React.Fragment>
			<div className='help'>
				* Please select at least one of the fields to export as part of the segment
			</div>
			<div>
				<Checkbox
					className='is-clickable ml-5 mt-5 mr-3'
					value='CpCustomerID'
					checked={selectedFields && selectedFields.includes('CpCustomerID')}
					onChange={(e) => {
						updateSelectedFields(e.target.value);
					}}
					disabled={view}
				/>
				CpCustomerID
			</div>
			<div>
				<Checkbox
					className='is-clickable ml-5 mt-5 mr-3'
					value='AnonymousID'
					checked={selectedFields && selectedFields.includes('AnonymousID')}
					onChange={(e) => {
						updateSelectedFields(e.target.value);
					}}
					disabled={view}
				/>
				AnonymousID
			</div>
		</React.Fragment>
	);
};

export default FieldsTab;
