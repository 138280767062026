import jwt from 'jwt-decode';

const isLoggedIn = (): boolean => {
	if (
		localStorage.getItem('metadata :') !== null &&
		localStorage.getItem('metadata :') !== undefined
	)
		return true;
	else return false;
};

const isAdmin = (): boolean => {
	if (isLoggedIn()) {
		const metadata = localStorage.getItem('metadata :');
		try {
			if (metadata) {
				const decodedToken: any = jwt(metadata); //eslint-disable-line
				if (decodedToken.role === 'ADMIN') return true;
				else return false;
			}
			return false;
		} catch (error) {
			return false;
		}
	} else return false;
};

const getUserId = (): string => {
	const metadata = localStorage.getItem('metadata :');
	if (metadata) {
		const decodedToken: any = jwt(metadata); //eslint-disable-line
		return decodedToken.id;
	} else {
		return '';
	}
};

export { isLoggedIn, isAdmin, getUserId };
