import Body from 'components/atoms/Body';
import Button from 'components/atoms/Button';
import Footer from 'components/atoms/Footer';
import Header from 'components/atoms/Header';
import Heading from 'components/atoms/Heading';
import React from 'react';
import { Link } from 'react-router-dom';

interface ModalData {
	title: string;
	content: string;
	path?: string;
}

interface ModalProps {
	className?: string;
	isModalVisible?: boolean;
	setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	data: ModalData;
	action?: (() => void) | undefined;
	setAction?: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
}

const Modal: React.FC<ModalProps> = ({
	className,
	isModalVisible,
	setIsModalVisible,
	data,
	action,
	setAction
}): JSX.Element => {
	const onSubmit = (button: string) => {
		if (action && button === 'OK' && data.title.includes('Alert')) {
			action();
		} else if (action && setAction && button === 'CROSS') {
			setAction(undefined);
		}
		setIsModalVisible(!isModalVisible);
	};

	return (
		<React.Fragment>
			{isModalVisible && (
				<div className={`modal is-active ${className || ''}`}>
					<div className='modal-background'></div>
					<div className='modal-card'>
						<Header className='modal-card-head'>
							<Heading className='modal-card-title'>{data.title}</Heading>
							<Link to={data.path || ''}>
								<button className='delete' aria-label='close' onClick={() => onSubmit('CROSS')}></button>
							</Link>
						</Header>

						<Body className='modal-card-body'>{data.content}</Body>

						<Footer className='modal-card-foot is-flex is-justify-content-right'>
							{data.path === '' ? (
								<Button className='is-link has-background-grey-dark' onClick={() => onSubmit('OK')}>
									{' '}
									OK{' '}
								</Button>
							) : (
								<Link to={data.path || ''}>
									<Button className='is-link has-background-grey-dark' onClick={() => onSubmit('OK')}>
										{' '}
										OK{' '}
									</Button>
								</Link>
							)}
						</Footer>
					</div>
				</div>
			)}
		</React.Fragment>
	);
};

export default Modal;
