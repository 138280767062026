import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RuleType } from 'interfaces/interface';
import DropdownItem from 'components/atoms/DropdownItem';

interface Rule {
	rules: Array<RuleType>;
	condition: string | null;
}

interface WorkAreaDropdownProps {
	condition: string | null;
	className?: string;
	ruleGroups: Array<Rule>;
	setRuleGroups: React.Dispatch<React.SetStateAction<Array<Rule>>>;
	groupIndex: number;
	ruleIndex?: number;
	view?: boolean;
}

const WorkAreaDropdown: React.FC<WorkAreaDropdownProps> = ({
	condition,
	className,
	ruleGroups,
	setRuleGroups,
	groupIndex,
	ruleIndex,
	view
}) => {
	const [activeDropdown, setActiveDropdown] = useState(false);
	const dummyGroup = {
		rules: [],
		condition: null
	};

	const changeCondition = (condition: string) => {
		if (ruleIndex !== undefined) {
			const newGroups = [...ruleGroups];
			newGroups[groupIndex].rules[ruleIndex].condition = condition;
			setRuleGroups(newGroups);
			setActiveDropdown(false);
		} else if (ruleIndex === undefined) {
			const newGroups = [...ruleGroups];
			setActiveDropdown(false);
			if (groupIndex === ruleGroups.length - 1) {
				if (newGroups[groupIndex].condition === null) {
					newGroups.push(dummyGroup);
				}
			}
			newGroups[groupIndex].condition = condition;
			setRuleGroups(newGroups);
		}
	};

	return (
		<div className={`dropdown ${className ? className : ''} ${activeDropdown ? 'is-active' : ''}`}>
			<div className='dropdown-trigger'>
				{view && condition === null ? (
					''
				) : (
					<button
						className={`button is-small ${!condition ? 'is-outlined' : 'is-dark'}`}
						aria-haspopup='true'
						aria-controls='dropdown-menu'
						onClick={() => setActiveDropdown(!activeDropdown)}
						disabled={view}
					>
						{condition ? (
							condition
						) : (
							<React.Fragment>
								<FontAwesomeIcon icon={['fas', 'plus']} className='mr-2' />
								<span>Condition</span>
							</React.Fragment>
						)}
					</button>
				)}
			</div>
			<div className='dropdown-menu' id='dropdown-menu' role='menu'>
				<div className='dropdown-content'>
					<DropdownItem onClick={() => changeCondition('OR')}>OR</DropdownItem>
					<DropdownItem onClick={() => changeCondition('AND')}>AND</DropdownItem>
				</div>
			</div>
		</div>
	);
};

export default WorkAreaDropdown;
