import React from 'react';
import DropdownItem from 'components/atoms/DropdownItem';
import Input from 'components/atoms/Input';
import './style.css';

interface TypeAheadProps {
	className?: string;
	data: Array<{ id?: string; value: string }>;
	inputType: string;
	handleClick: (e: string) => void;

	dropdownOpen: boolean;
	setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
	input: string;
	setInput: React.Dispatch<React.SetStateAction<string>>;
	loader: boolean;
	focusHandler?: () => void;
	blurHandler?: () => void;
	handleClickId?: (e: string) => void;
	view?: boolean;
}

const TypeAhead: React.FC<TypeAheadProps> = ({
	data,
	handleClick,
	handleClickId,
	inputType,
	input,
	setInput,
	dropdownOpen,
	setDropdownOpen,
	loader,
	focusHandler,
	blurHandler,
	view
}): JSX.Element => {
	const itemClickHandler = (user: Array<string | undefined>) => {
		user[1] && handleClick(user[1]);
		user[0] && handleClickId && handleClickId(user[0]);
		setDropdownOpen(false);
	};
	return (
		<React.Fragment>
			<div className='dropdown is-active'>
				<div className='dropdown-trigger'>
					<div className={`control ${loader ? 'is-loading' : ''}`}>
						<Input
							type={inputType}
							placeholder='Start typing'
							onChange={(e) => {
								setInput(e.target.value);
							}}
							value={input}
							onFocus={() => {
								focusHandler && focusHandler();
							}}
							onBlur={() => {
								setDropdownOpen(false);
								blurHandler && blurHandler();
							}}
							disabled={view}
						/>
					</div>

					{dropdownOpen && (
						<div className='dropdown-menu dropdown-scrollable' id='dropdown-menu' role='menu'>
							<div className='dropdown-content'>
								{data &&
									data.map((suggestion, index) => (
										<DropdownItem
											key={index}
											onMouseDown={() => itemClickHandler([suggestion.id, suggestion.value])}
										>
											{suggestion.value}
										</DropdownItem>
									))}
							</div>
						</div>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

export default TypeAhead;
