import React from 'react';

interface HeroContainerProps {
	className?: string;
}

const HeroContainer: React.FC<HeroContainerProps> = ({ className, children }): JSX.Element => {
	return (
		<React.Fragment>
			<div className={`hero ${className || ''}`}>{children}</div>
		</React.Fragment>
	);
};

export default HeroContainer;
