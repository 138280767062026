import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Checkbox from 'components/atoms/Checkbox';
import UsersMenu from '../UsersMenu';
import { Link } from 'react-router-dom';
import DropdownMenu from '../../atoms/DropdownMenu';
import { AuthUtils } from 'utils';
import { KeyString, KeyStringBoolean } from 'interfaces/interface';
import SegmentMenu from '../SegmentMenu';
import './style.css';

interface ModalData {
	title: string;
	content: string;
	path: string;
}

interface TableProps {
	tableType: string;
	className?: string;
	data: KeyString[];
	showCheckBox?: boolean;
	showAction?: boolean;
	selectedRows: KeyString[];
	setSelectedRows: React.Dispatch<React.SetStateAction<KeyString[]>>;
	displayColumnOrder: string[];
	displayColumnName: string[];
	setModalData: React.Dispatch<React.SetStateAction<ModalData>>;
	setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setActiveMenuRow: React.Dispatch<React.SetStateAction<KeyString>>;
	setModalAction: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
	resetPassword?: () => void;
	unlockUser?: () => void;
	sortingOrder?: string | undefined;
	setSortingOrder?: React.Dispatch<React.SetStateAction<string | undefined>>;
	sortingColumn?: string | undefined;
	setSortingColumn?: React.Dispatch<React.SetStateAction<string | undefined>>;
	disabledCheckbox?: boolean[];
}

const Table: React.FC<TableProps> = ({
	tableType,
	className,
	data,
	showCheckBox,
	showAction,
	selectedRows,
	setSelectedRows,
	displayColumnOrder,
	displayColumnName,
	setModalData,
	setIsModalVisible,
	setActiveMenuRow,
	setModalAction,
	resetPassword,
	unlockUser,
	sortingOrder,
	sortingColumn,
	setSortingOrder,
	setSortingColumn,
	disabledCheckbox
}): JSX.Element => {
	const [checkboxList, setCheckboxList] = useState<boolean[]>([]);
	const [checkboxAll, setCheckboxAll] = useState(false);
	const [isTableDataLoaded, setIsTableDataLoaded] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [userId, setUserId] = useState('');
	const [segmentDownloadList, setSegmentDownloadList] = useState<Array<string[]>>([]);
	const [downloadDropdownShownList, setDownloadDropdownShownList] = useState<Array<boolean>>([]);
	const [downloadLoaderList, setDownloadLoaderList] = useState<Array<boolean>>([]);
	const [mainDropdownShownList, setMainDropdownShownList] = useState<Array<boolean>>([]);
	const [exportLoaderList, setExportLoaderList] = useState<Array<KeyStringBoolean>>([]);

	//initialize table state list
	useEffect(() => {
		const checkStatus: boolean[] = [];
		const copySegmentDownloadList: Array<string[]> = [];
		const copyDownloadDropdownShownList: Array<boolean> = [];
		const copyDownloadLoaderList: Array<boolean> = [];
		const copyMainDropdownShownList: Array<boolean> = [];
		const copyExportLoaderList: Array<KeyStringBoolean> = [];
		data.forEach(() => {
			checkStatus.push(false);
			copySegmentDownloadList.push([]);
			copyMainDropdownShownList.push(false);
			copyDownloadDropdownShownList.push(false);
			copyDownloadLoaderList.push(false);
			copyExportLoaderList.push({ Clevertap: false, Firebase: false, GoogleAdManager: false });
		});
		setDownloadLoaderList(copyDownloadLoaderList);
		setDownloadDropdownShownList(copyDownloadDropdownShownList);
		setMainDropdownShownList(copyMainDropdownShownList);
		setCheckboxList(checkStatus);
		setSegmentDownloadList(copySegmentDownloadList);
		setIsAdmin(AuthUtils.isAdmin());
		setUserId(AuthUtils.getUserId());
		setIsTableDataLoaded(true);
		setExportLoaderList(copyExportLoaderList);
	}, []);

	//Update selected row depending on if specific row is selected or not
	const updateSelectedRow = (index: number, selected: boolean, id: string): void => {
		//Selected row update
		let selectedList: KeyString[];
		if (selected === false) {
			selectedList = selectedRows.filter((item) => {
				if (item.id === id) return false;
				return true;
			});
		} else {
			selectedList = selectedRows.map((item) => item);
			data.forEach((item) => {
				if (item.id === id) {
					selectedList.push(item);
				}
			});
		}
		setSelectedRows(selectedList);

		//Checkbox list update
		const checkList = checkboxList.map((item, innerIndex) => {
			if (index === innerIndex) {
				if (item === true) setCheckboxAll(false);
				return !item;
			} else return item;
		});
		setCheckboxList(checkList);
	};

	//Update selected row depending on if all the rows are selected or not
	const updateSelectedAllRows = (selectAll: boolean): void => {
		//Checkbox List update
		const checkList = checkboxList.map((_, index) => {
			if (selectAll === true) {
				if (disabledCheckbox) {
					return !disabledCheckbox[index];
				} else return true;
			} else return false;
		});
		setCheckboxList(checkList);
		setCheckboxAll(!checkboxAll);

		//Update all selected rows
		if (selectAll === false) {
			setSelectedRows([]);
		} else {
			const selectedList: KeyString[] = data.filter((_, index) => {
				if (disabledCheckbox) {
					return !disabledCheckbox[index];
				} else {
					return true;
				}
			});
			setSelectedRows(selectedList);
		}
	};

	//Updates which column to sort and what should be the sorting order
	const updateSortDetail = (sortColumn: string) => {
		if (setSortingOrder !== undefined && setSortingColumn !== undefined) {
			if (sortColumn === sortingColumn) {
				if (sortingOrder === 'ASC') setSortingOrder('DESC');
				else setSortingOrder('ASC');
			} else {
				setSortingColumn(sortColumn);
				setSortingOrder('ASC');
			}
		}
	};

	//Updates segment show-dropdown list so that only one segment dropdown is visible at anytime
	const updateMainDropdownShownList = (index: number) => {
		//Sets segment download show-dropdown list to false for all the elements/segments
		//so that on closing a segment dropdown, the child dropdown is also closed
		const copyDownloadDropdownShownList = downloadDropdownShownList.map(() => {
			return false;
		});
		setDownloadDropdownShownList(copyDownloadDropdownShownList);

		//If segment dropdown is initially close, set its visibility to true
		//and set the rest of segments dropdown visibility to false
		if (mainDropdownShownList[index] === false) {
			const copyMainDropdownShownList = mainDropdownShownList.map(() => {
				return false;
			});
			copyMainDropdownShownList[index] = true;
			setMainDropdownShownList(copyMainDropdownShownList);
		}
		//If segment dropdown is initially open, set its visibility with the rest of segments dropdown visibility to false
		else {
			const copyMainDropdownShownList = mainDropdownShownList.map(() => {
				return false;
			});
			setMainDropdownShownList(copyMainDropdownShownList);
		}
	};
	const segmentMenuProp = {
		downloadDropdownShownList,
		setDownloadDropdownShownList,
		downloadLoaderList,
		setDownloadLoaderList,
		segmentDownloadList,
		setSegmentDownloadList,
		exportLoaderList,
		setExportLoaderList,
		isAdmin,
		setModalData,
		setIsModalVisible
	};

	return (
		<React.Fragment>
			{isTableDataLoaded && (
				<table className={`table ${className || ''}`}>
					<thead className='is-capitalized'>
						<tr>
							{showCheckBox && (
								<th className=''>
									<Checkbox
										checked={checkboxAll}
										onChange={() => {
											updateSelectedAllRows(!checkboxAll);
										}}
									></Checkbox>
								</th>
							)}
							{displayColumnName.map((item, index) => {
								return (
									<th
										key={index}
										className={
											tableType === 'segment'
												? index === 1
													? 'first-hidden-column'
													: index === 2
													? 'second-hidden-column'
													: index === 3
													? 'third-hidden-column'
													: ''
												: tableType === 'user'
												? index === 1
													? 'zeroth-hidden-column'
													: index === 4 || index === 5
													? 'second-hidden-column'
													: index === 2
													? 'third-hidden-column'
													: ''
												: ''
										}
									>
										{item}
										{(item === 'Name' ||
											item === 'Creation Date' ||
											item === 'Description' ||
											item === 'Owner' ||
											item === 'Status') &&
											tableType === 'segment' && (
												<span
													className='icon ml-2 is-clickable'
													onClick={() => updateSortDetail(displayColumnOrder[index])}
												>
													{sortingColumn !== displayColumnOrder[index] && (
														<FontAwesomeIcon className='icon is-small' icon={['fas', 'sort']}></FontAwesomeIcon>
													)}
													{sortingColumn === displayColumnOrder[index] && sortingOrder === 'ASC' && (
														<FontAwesomeIcon className='icon is-small' icon={['fas', 'sort-up']} />
													)}
													{sortingColumn === displayColumnOrder[index] && sortingOrder === 'DESC' && (
														<FontAwesomeIcon className='icon is-small' icon={['fas', 'sort-down']} />
													)}
												</span>
											)}
									</th>
								);
							})}
							{showAction && <th className=''>Action</th>}
						</tr>
					</thead>
					<tbody>
						{data.map((row, index) => {
							return (
								<tr key={index}>
									{showCheckBox && (
										<td className=''>
											<Checkbox
												className='is-clickable'
												checked={checkboxList[index] || false}
												onChange={() => {
													updateSelectedRow(index, !checkboxList[index], row.id);
												}}
												disabled={disabledCheckbox ? disabledCheckbox[index] : false}
											></Checkbox>
										</td>
									)}
									{displayColumnOrder.map((item, index) => {
										if (tableType === 'segment' && item === 'name') {
											return (
												<td key={index}>
													<Link to={`/${tableType}/update?id=${row.id}`} className={'item-name is-clickable'}>
														{row[item]}
													</Link>
													{row.status !== 'SAVED' && (
														<span className='has-text-link is-size-7'> ({row.size})</span>
													)}
												</td>
											);
										} else
											return (
												<td
													key={index}
													className={
														tableType === 'segment'
															? index === 1
																? 'first-hidden-column'
																: index === 2
																? 'second-hidden-column'
																: index === 3
																? 'third-hidden-column'
																: ''
															: tableType === 'user'
															? index === 1
																? 'zeroth-hidden-column'
																: index === 4 || index === 5
																? 'second-hidden-column'
																: index === 2
																? 'third-hidden-column'
																: ''
															: ''
													}
												>
													{row[item]}
												</td>
											);
									})}
									{showAction && (
										<td>
											<div className='is-flex is-clickable'>
												<Link
													to={`/${tableType}/update?id=${row.id}`}
													className={isAdmin || userId === row.ownerId ? '' : 'disabled-link'}
													state={'UPDATE'}
												>
													<span className='icon'>
														<FontAwesomeIcon
															className={`icon is-small ${
																isAdmin || userId === row.ownerId ? '' : 'has-text-grey-light'
															}`}
															icon={['fas', 'edit']}
														/>
													</span>
												</Link>
												<DropdownMenu
													setActiveMenuRow={setActiveMenuRow}
													userData={row}
													showDropdown={mainDropdownShownList[index]}
													index={index}
													updateDropdownList={updateMainDropdownShownList}
												>
													{/* user dropdown */}
													{tableType === 'user' && (
														<UsersMenu
															setIsModalVisible={setIsModalVisible}
															setModalData={setModalData}
															setModalAction={setModalAction}
															resetPassword={resetPassword}
															unlockUser={unlockUser}
														/>
													)}
													{/* Segment dropdown */}
													{tableType === 'segment' && (
														<SegmentMenu
															{...segmentMenuProp}
															segmentData={row}
															segmentIndex={index}
															setModalAction={setModalAction}
														></SegmentMenu>
													)}
												</DropdownMenu>
											</div>
										</td>
									)}
								</tr>
							);
						})}
					</tbody>
				</table>
			)}
		</React.Fragment>
	);
};

export default Table;
