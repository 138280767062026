import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface DropDownProps {
	className?: string;
	dataSize: number;
	page: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
}

const Pagination: React.FC<DropDownProps> = ({
	className,
	dataSize,
	page,
	setPage
}): JSX.Element => {
	const pages = Math.ceil(dataSize / 50);
	const pagesArray = [];

	for (let i = 1; i <= pages; i++) {
		pagesArray.push(i);
	}

	const previousPage = () => {
		if (page > 1) {
			setPage(page - 1);
		}
	};

	const nextPage = () => {
		if (page < pages) {
			setPage(page + 1);
		}
	};

	return (
		<nav
			className={`pagination is-centered ${className || ''}`}
			role='navigation'
			aria-label='pagination'
		>
			<div className='pagination-previous is-clickable' onClick={previousPage}>
				<FontAwesomeIcon icon={['fas', 'angle-left']} style={{ color: 'grey' }} />
			</div>
			<ul className='pagination-list'>
				{pagesArray.map((value, index) => (
					<li key={index}>
						<div
							className={`pagination-link  is-clickable ${page === value ? 'is-current' : ''}`}
							aria-label={`Go to page ${value}`}
							onClick={() => setPage(value)}
						>
							{value}
						</div>
					</li>
				))}
			</ul>
			<div className='pagination-next is-clickable' onClick={nextPage}>
				<FontAwesomeIcon icon={['fas', 'angle-right']} style={{ color: 'grey' }} />
			</div>
		</nav>
	);
};

export default Pagination;
