import React from 'react';

interface ContainerProps {
	className?: string;
}

const Container: React.FC<ContainerProps> = ({ className, children }): JSX.Element => {
	return (
		<React.Fragment>
			<div className={`container ${className || ''}`}>{children}</div>
		</React.Fragment>
	);
};

export default Container;
