import React from 'react';

interface CheckboxProps {
	className?: string;
	name?: string;
	checked?: boolean;
	value?: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
	className,
	name,
	value,
	checked,
	onChange,
	disabled
}): JSX.Element => {
	return (
		<React.Fragment>
			<input
				className={className}
				type='checkbox'
				name={name}
				value={value}
				checked={checked}
				onChange={onChange}
				disabled={disabled}
			></input>
		</React.Fragment>
	);
};

export default Checkbox;
