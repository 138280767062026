import React, { useState, useEffect, useRef } from 'react';
import RuleTag from 'components/atoms/RuleTag';
import WorkAreaDropdown from '../WorkAreaDropdown';
import { RuleType } from 'interfaces/interface';
import './styles.css';

interface Rule {
	rules: Array<RuleType>;
	condition: string | null;
}
interface WorkAreaProps {
	ruleList: Array<RuleType>;
	title?: string;
	heading?: string;
	ruleGroups: Array<Rule>;
	setRuleGroups: React.Dispatch<React.SetStateAction<Array<Rule>>>;
	view: boolean;
}
const WorkArea: React.FC<WorkAreaProps> = ({
	ruleList,
	title,
	ruleGroups,
	heading,
	setRuleGroups,
	view
}) => {
	const [unUsedRules, setUnUsedRules] = useState<RuleType[]>([]);
	const draggedRule = useRef<{ groupIndex: number | null; ruleIndex: number } | null>();
	const dragNode = useRef<HTMLDivElement | null>();
	const [dragging, setDragging] = useState(false);

	useEffect(() => {
		// iterate through unused rules and delete the missing rule on every rule list change
		if (unUsedRules.length !== ruleList.length) {
			for (let i = 0; i < ruleGroups.length; i++) {
				for (let j = 0; j < ruleGroups[i].rules.length; j++) {
					const found = ruleList.some((rule) => rule.name === ruleGroups[i].rules[j].name);
					if (!found) {
						const copyRuleGroups = [...ruleGroups];
						copyRuleGroups[i].rules.splice(j, 1);
						setRuleGroups(copyRuleGroups);
					}
				}
			}
		}
		setTimeout(() => {
			setUnUsedRules([...ruleList]);
		}, 0);
	}, [ruleList]);

	const handleDragStart = (
		e: React.DragEvent<HTMLDivElement>,
		groupIndex: number | null,
		ruleIndex: number
	) => {
		// ondragstart save rule coordinates and add a drag end event to the same rule
		draggedRule.current = { groupIndex: groupIndex, ruleIndex: ruleIndex };
		dragNode.current = e.target as HTMLInputElement;
		dragNode?.current?.addEventListener('dragend', handleDragEnd);

		// set dragging true for dragging styles
		setTimeout(() => {
			setDragging(true);
		}, 0);
	};

	const handleDragEnter = (
		e: React.DragEvent<HTMLDivElement>,
		groupIndex: number | null,
		ruleIndex: number
	) => {
		const currentRule = draggedRule.current;
		if (currentRule && groupIndex !== null) {
			// if not entering an unUsedRule (rule with groupIndex === null)
			if (e.target !== dragNode.current) {
				// if its entering a different target
				setRuleGroups((oldGroup) => {
					if (currentRule.groupIndex !== null) {
						// if current rule is not an unused rule
						const newGroup = JSON.parse(JSON.stringify(oldGroup)); // deep copy of the rule
						const duplicateRule = newGroup[groupIndex].rules.some(
							// check if rule exists in the rule target group already
							(rule: { name: string }) =>
								rule.name === newGroup[currentRule.groupIndex || 0].rules[currentRule.ruleIndex].name
						);

						if (!duplicateRule || currentRule.groupIndex === groupIndex) {
							// if its not a repeated rule or if current rule and dragged rule are same
							// remove the rule from its initial group and place it at the index of the dragged upon rule
							newGroup[groupIndex].rules.splice(
								ruleIndex,
								0,
								newGroup[currentRule.groupIndex].rules.splice(currentRule.ruleIndex, 1)[0]
							);
							draggedRule.current = { groupIndex: groupIndex, ruleIndex: ruleIndex };
						}
						return newGroup;
					}
					if (currentRule.groupIndex === null) {
						// if current rule is an unused rule
						const newGroup = JSON.parse(JSON.stringify(oldGroup));
						const duplicateRule = newGroup[groupIndex].rules.some(
							(rule: { name: string }) => rule.name === unUsedRules[currentRule.ruleIndex].name
						);
						if (!duplicateRule) {
							// if its not a repeated rule
							// copy the rule and place it at the index of the dragged upon rule
							newGroup[groupIndex].rules.splice(ruleIndex, 0, unUsedRules[currentRule.ruleIndex]);
							draggedRule.current = { groupIndex: groupIndex, ruleIndex: ruleIndex };
						}
						return newGroup;
					} else return oldGroup;
				});
			}
		}
	};

	const handleDragEnd = () => {
		setDragging(false);
		dragNode?.current?.removeEventListener('dragend', () => handleDragEnd());
		draggedRule.current = null;
		dragNode.current = null;
	};

	const handleDeleteRule = (groupIndex: number, ruleIndex: number) => {
		const newGroups = [...ruleGroups];
		newGroups[groupIndex].rules.splice(ruleIndex, 1);
		if (!newGroups[groupIndex].rules.length) {
			if (groupIndex > 0) {
				newGroups.splice(groupIndex, 1);
				newGroups[groupIndex - 1].condition = null;
			}
			if (groupIndex === 0 && newGroups.length > 1) {
				newGroups.splice(groupIndex, 1);
			}
		}
		setRuleGroups(newGroups);
	};

	const handleDeleteGroup = (groupIndex: number) => {
		const newGroups = [...ruleGroups];
		newGroups.splice(groupIndex, 1);
		if (groupIndex > 0) {
			newGroups[groupIndex - 1].condition = null;
		}
		setRuleGroups(newGroups);
	};

	const getDraggingStyles = (groupIndex: number | null, ruleIndex: number) => {
		const currentRule = draggedRule.current;
		if (currentRule?.groupIndex === groupIndex && currentRule?.ruleIndex === ruleIndex) {
			return 'dragging';
		} else return '';
	};

	return (
		<React.Fragment>
			{(heading === 'WHERE' || ruleList.length > 0) && (
				<div className='wa-container is-flex is-justify-content-space-between'>
					<div className='work-section is-flex is-flex-direction-column is-align-items-flex-start p-5'>
						<h1 className='is-size-3 mb-1'>{title}</h1>
						<h1 className='is-size-6 mb-3 has-text-weight-bold'>{heading}</h1>
						{ruleGroups.length > 0 &&
							ruleGroups.map((ruleGroup, groupIndex) => (
								<React.Fragment key={groupIndex}>
									<div className='is-flex is-align-items-center'>
										<div
											className='wa-filter-set-container is-flex is-align-items-center is-flex-wrap-wrap px-2'
											onDragEnter={
												dragging && !ruleGroup.rules.length
													? (e) => handleDragEnter(e, groupIndex, 0)
													: () => null
											}
										>
											{!ruleGroup.rules.length ? (
												<div className='has-text-grey'>Drag a rule ...</div>
											) : (
												ruleGroup.rules.map((rule, ruleIndex) => (
													<React.Fragment key={ruleIndex}>
														<RuleTag
															isDeletable={!view}
															view={view}
															rule={rule}
															className={dragging ? getDraggingStyles(groupIndex, ruleIndex) : ''}
															handleDragStart={(e: React.DragEvent<HTMLDivElement>) =>
																handleDragStart(e, groupIndex, ruleIndex)
															}
															handleDragEnter={
																dragging
																	? (e: React.DragEvent<HTMLDivElement>) => handleDragEnter(e, groupIndex, ruleIndex)
																	: () => null
															}
															deleteTag={() => handleDeleteRule(groupIndex, ruleIndex)}
														/>
														{rule.name !== null && ruleIndex !== ruleGroups[groupIndex].rules.length - 1 && (
															<WorkAreaDropdown
																condition={rule.condition}
																ruleGroups={ruleGroups}
																setRuleGroups={setRuleGroups}
																groupIndex={groupIndex}
																ruleIndex={ruleIndex}
																view={view}
															/>
														)}
													</React.Fragment>
												))
											)}
										</div>
										{ruleGroups.length > 1 && !view && (
											<button className='delete is-small mx-2' onClick={() => handleDeleteGroup(groupIndex)} />
										)}
									</div>
									<WorkAreaDropdown
										condition={ruleGroup.condition}
										className='my-2'
										ruleGroups={ruleGroups}
										setRuleGroups={setRuleGroups}
										groupIndex={groupIndex}
										view={view}
									/>
								</React.Fragment>
							))}
					</div>
					{unUsedRules.length > 0 && (
						<div className='rule-section py-5 px-2 is-flex is-flex-direction-column'>
							{unUsedRules.map((rule, ruleIndex) => (
								<RuleTag
									view={view}
									key={ruleIndex}
									isDeletable={false}
									rule={rule}
									className={dragging ? getDraggingStyles(null, ruleIndex) : ''}
									handleDragStart={(e: React.DragEvent<HTMLDivElement>) =>
										handleDragStart(e, null, ruleIndex)
									}
									handleDragEnter={(e: React.DragEvent<HTMLDivElement>) =>
										handleDragEnter(e, null, ruleIndex)
									}
								/>
							))}
						</div>
					)}
				</div>
			)}
		</React.Fragment>
	);
};

export default WorkArea;
