import React from 'react';

interface LoaderProps {
	className?: string;
}

const Loader: React.FC<LoaderProps> = ({ className, children }): JSX.Element => {
	return (
		<React.Fragment>
			<div className='loader-wrapper is-center'>
				<div className={`loader is-loading ${className || ''}`}>{children}</div>
			</div>
		</React.Fragment>
	);
};

export default Loader;
