import React from 'react';

interface FormControlProps {
	className?: string;
}

const FormControl: React.FC<FormControlProps> = ({ className, children }): JSX.Element => {
	return (
		<React.Fragment>
			<div className={`control ${className || ''}`}>{children}</div>
		</React.Fragment>
	);
};

export default FormControl;
