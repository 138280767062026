import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/atoms/Button';
import DropdownItem from '../DropdownItem';
import './style.css';

interface KeyString {
	[key: string]: string;
}

interface DropdownMenuProps {
	className?: string;
	setActiveMenuRow?: React.Dispatch<React.SetStateAction<KeyString>>;
	userData?: KeyString;
	menuName?: string;
	menuHint?: string;
	segmentId?: string;
	segmentIndex?: number;
	menuOnClickHandler?: (id: string, index: number) => void;
	apiTriggerDropdown?: boolean;
	isLoading?: boolean;
	index?: number;
	showDropdown?: boolean;
	updateDropdownList?: (index: number) => void;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
	className,
	setActiveMenuRow,
	userData,
	menuName,
	menuHint,
	children,
	segmentId,
	segmentIndex,
	menuOnClickHandler,
	apiTriggerDropdown,
	isLoading,
	showDropdown,
	index,
	updateDropdownList
}): JSX.Element => {
	const [localDropdownShow, setLocalDropdownShow] = useState(false);
	return (
		<span className={`dropdown is-right width-100pc ${showDropdown ? 'is-active' : ''}`}>
			<span className='dropdown-trigger width-100pc'>
				{/* If menu name is not passed down then this button will be rendered */}
				{!menuName && (
					<Button
						className='is-small is-white py-0'
						aria-haspopup='true'
						aria-controls='dropdown-menu'
						onClick={() => {
							updateDropdownList && index !== undefined && updateDropdownList(index);
							setActiveMenuRow && userData && setActiveMenuRow(userData);
						}}
					>
						<FontAwesomeIcon icon={['fas', 'ellipsis-vertical']} style={{ color: 'grey' }} />
					</Button>
				)}
				{menuName && (
					<DropdownItem
						className={`${isLoading ? 'disable-dropdown has-text-grey-light' : ''}`}
						onClick={() => {
							setLocalDropdownShow(!localDropdownShow);
							setActiveMenuRow && userData && setActiveMenuRow(userData);
							//This handler function handles download segment functionality
							!localDropdownShow &&
								segmentId !== undefined &&
								segmentIndex !== undefined &&
								menuOnClickHandler &&
								menuOnClickHandler(segmentId, segmentIndex);
						}}
					>
						<div className='is-flex is-align-items-center is-justify-content-space-between'>
							<div className='is-flex is-align-items-center'>
								<div>{menuName}</div>
								{isLoading !== undefined && isLoading && <div className='loader ml-3'></div>}
							</div>
							<FontAwesomeIcon icon={['fas', 'angle-right']} className='apply-negative-margin' />
						</div>
						{menuHint && <span className='has-text-link is-size-7 has-text-success'>{menuHint}</span>}
					</DropdownItem>
				)}
			</span>
			{((!menuName && showDropdown) || (menuName && localDropdownShow && !isLoading)) &&
				// If apiTriggerDropdown is not passed down then rendering items wont depend on it.
				// apiTriggerDropdown will be set to true after recieving non-empty download url list from api call.
				(apiTriggerDropdown === undefined ? true : apiTriggerDropdown) && (
					<div
						className={`dropdown-menu dropdown-menu-content ${className || ''}`}
						onClick={() => {
							setActiveMenuRow && userData && setActiveMenuRow(userData);
						}}
					>
						<div className='dropdown-content'>{children}</div>
					</div>
				)}
		</span>
	);
};

export default DropdownMenu;
