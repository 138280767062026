import React from 'react';
import { NavLink } from 'react-router-dom';
import './style.css';

interface NavBarItemProps {
	className?: string;
	link: string;
}

const NavBarItem: React.FC<NavBarItemProps> = ({ className, children, link }): JSX.Element => {
	return (
		<NavLink to={link} className={`navbar-item custom-navbar-item ${className || ''}`}>
			{children}
		</NavLink>
	);
};

export default NavBarItem;
