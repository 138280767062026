import React from 'react';

interface LabelProps {
	className?: string;
}

const Label: React.FC<LabelProps> = ({ className, children }): JSX.Element => {
	return (
		<React.Fragment>
			<div className={`label ${className || ''}`}>{children}</div>
		</React.Fragment>
	);
};

export default Label;
