import Button from 'components/atoms/Button';
import React, { useEffect, useState } from 'react';
import { Constants } from 'utils';
import { Interface } from 'interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RuleCard from 'components/molecules/RuleCard';
import { RuleType } from 'interfaces/interface';

interface Rule {
	rules: Array<RuleType>;
	condition: string | null;
}
interface RuleAreaProps {
	className?: string;
	ruleList: Interface.RuleType[];
	setRuleList: React.Dispatch<React.SetStateAction<Interface.RuleType[]>>;
	ruleGroups: Array<Rule>;
	setRuleGroups: React.Dispatch<React.SetStateAction<Array<Rule>>>;
	havingRuleGroups: Array<Rule>;
	setHavingRuleGroups: React.Dispatch<React.SetStateAction<Array<Rule>>>;
	view: boolean;
}

const RuleArea: React.FC<RuleAreaProps> = ({
	ruleList,
	setRuleList,
	ruleGroups,
	setRuleGroups,
	havingRuleGroups,
	setHavingRuleGroups,
	view
}): JSX.Element => {
	//Parameter list without category
	const [parameterList, setParameterList] = useState<Array<string>>([]);
	//Parmeter list with category
	const [categorizeParameterList, setCategorizeParameterList] = useState<
		Interface.CategorizeParameterType[]
	>([]);

	useEffect(() => {
		updateParameters();
	}, []);

	const updateParameters = () => {
		//Setting paramter list from the mapping list
		const parameters = Constants.ParameterMapping.map((parameter) => {
			return parameter.displayName;
		});
		//Setting categorized parameter list from the mapping list
		const categoryParameters = Constants.categoryList
			//Setting parameter category
			.map((category) => {
				const categorizeParameter: Interface.CategorizeParameterType = {
					category: category,
					parameters: []
				};
				return categorizeParameter;
			})
			//Setting parameters into their respective category
			.map((categorizeParameter) => {
				Constants.ParameterMapping.forEach((parameterMap) => {
					if (parameterMap.category === categorizeParameter.category) {
						categorizeParameter.parameters.push(parameterMap.displayName);
					}
				});
				return categorizeParameter;
			});
		setCategorizeParameterList(categoryParameters);
		setParameterList(parameters);
	};

	//Recursive function that will return a counter to make rule name unique
	const validateRuleName = (counter: number): number => {
		const copyRuleList = ruleList.some((rule) => rule.name === `Rule${counter}`);
		if (copyRuleList) {
			return validateRuleName(counter + 1);
		} else {
			return counter;
		}
	};

	const addSimpleRuleCard = () => {
		const subRule: Interface.subRuleType = {
			parameter: '',
			apiColumnName: '',
			columnType: '',
			operator: '',
			condition: null,
			value: [''],
			min: '',
			max: ''
		};
		//Simple rule card is initialized with an empty subrule
		const newRule: RuleType = {
			name: `Rule${validateRuleName(1)}`,
			type: 'simple',
			subRules: [subRule],
			condition: null
		};
		setRuleList([...ruleList, newRule]);
	};

	const addCompoundRuleCard = () => {
		const subRule: Interface.subRuleType = {
			parameter: '',
			apiColumnName: '',
			columnType: '',
			operator: '',
			condition: null,
			value: [''],
			min: '',
			max: ''
		};

		//Compound rule card is initialized with an empty having subrule,
		//but isHaving key is not set to true, so the compound rule initially doesn't have having sub-rule
		const newRule: RuleType = {
			name: `Rule${validateRuleName(1)}`,
			type: 'compound',
			subRules: [subRule],
			condition: null
		};
		setRuleList([...ruleList, newRule]);
	};
	return (
		<React.Fragment>
			<div className='rules-container mx-5'>
				{parameterList && (
					<div className='rule-card-container'>
						{ruleList.map((_, index) => {
							return (
								<RuleCard
									parameterList={parameterList}
									categorizeParameterList={categorizeParameterList}
									ruleList={ruleList}
									setRuleList={setRuleList}
									ruleIndex={index}
									key={index}
									ruleGroups={ruleGroups}
									setRuleGroups={setRuleGroups}
									havingRuleGroups={havingRuleGroups}
									setHavingRuleGroups={setHavingRuleGroups}
									view={view}
								></RuleCard>
							);
						})}
					</div>
				)}
				{!view && (
					<div className='is-flex is-justify-content-end mt-4'>
						<Button onClick={addSimpleRuleCard} className='is-dark is-small mr-2'>
							<FontAwesomeIcon icon={['fas', 'plus']}></FontAwesomeIcon>
							Simple Rule
						</Button>
						<Button onClick={addCompoundRuleCard} className='is-dark is-small'>
							<FontAwesomeIcon icon={['fas', 'plus']}></FontAwesomeIcon>
							Compound Rule
						</Button>
					</div>
				)}
			</div>
		</React.Fragment>
	);
};
export default RuleArea;
